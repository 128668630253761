import React, {useEffect} from "react"
import {SSOBC, SSOState} from "./Profile/SSOBC"

export const SSOStatePage: React.FC<{state: SSOState}> = ({state}) => {
    useEffect(() => {
        // We are in the SSO login sub-window.
        // Send SSO state to the parent window. This window will be closed.
        new SSOBC().postSSOState(state).close()
    }, [state])

    return null
}
