import React from "react"
import {Page403} from "scholastic-client-components/src/pages/ErrorPage/page403/Page403"
import {useAvailablePortalSectionsPaths} from "../hooks/useAvailablePortalSectionsPaths"
import {PortalRoutes} from "scholastic-client-components/src/context/portalSectionTypes"
import {AppPaths} from "../lib/routing"

type Home403Props = {}

export const Home403: React.FC<Home403Props> = () => {
    const availablePaths = useAvailablePortalSectionsPaths()

    const url = availablePaths.school.home
        ? PortalRoutes.School + AppPaths.Home
        : availablePaths.higherEd.home
        ? PortalRoutes.HigherEd + AppPaths.Home
        : availablePaths.business.home
        ? PortalRoutes.Business + AppPaths.Home
        : undefined

    return <Page403 variant={"home_403"} url={url} />
}
