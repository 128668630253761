import React, {ReactNode} from "react"
import {Link} from "react-router-dom"
import styles from "../../ui/DropdownSelect/DropdownMenu/DropdownMenu.module.scss"
import classNames from "classnames"
import {Icon, NameIcon} from "../../ui/Icon/Icon"

export interface HeaderPopupItemProps {
    id: number
    to?: string
    external?: boolean
    text: string
    icon?: NameIcon
    nodeIcon?: ReactNode
    limitedWidth?: string
    onClick?: () => void
    subTitle?: boolean
    matchHeaderColors?: boolean
    supportLink?: boolean
    active?: boolean
}

export const HeaderDropdownItem: React.FC<HeaderPopupItemProps> = ({
    to,
    external,
    text,
    icon,
    nodeIcon,
    limitedWidth,
    onClick,
    subTitle,
    matchHeaderColors,
    active,
}) => {
    if (to) {
        if (external) {
            return (
                <a className={classNames(styles.itemContent)} href={to} onClick={onClick}>
                    {icon ? (
                        <div
                            style={limitedWidth ? {maxWidth: limitedWidth} : undefined}
                            className={classNames(styles.itemContentOffset, styles.withIcon, {
                                [styles.limitedWidth]: !!limitedWidth,
                            })}
                        >
                            <Icon name={icon} />
                            <span>{text}</span>
                        </div>
                    ) : (
                        <div className={styles.itemContentOffset}>{text}</div>
                    )}
                </a>
            )
        } else {
            return (
                <Link
                    className={classNames(styles.itemContent, {
                        [styles.matchHeaderColors]: matchHeaderColors,
                        [styles.active]: active,
                        [styles.svg_666]: !!nodeIcon,
                    })}
                    to={to}
                    onClick={onClick}
                >
                    <div
                        className={classNames(styles.itemContentOffset, {
                            [styles.withIcon]: !!nodeIcon,
                        })}
                    >
                        {nodeIcon}
                        {text}
                    </div>
                </Link>
            )
        }
    }

    if (subTitle) {
        return (
            <div className={classNames(styles.itemContent, styles["item-title"])}>
                <div className={styles.itemContentOffset}>{text}</div>
            </div>
        )
    }

    return (
        <button className={classNames(styles.itemContent)} onClick={onClick}>
            {icon ? (
                <div
                    style={limitedWidth ? {maxWidth: limitedWidth} : undefined}
                    className={classNames(styles.itemContentOffset, styles.withIcon, {
                        [styles.limitedWidth]: !!limitedWidth,
                    })}
                >
                    <Icon name={icon} />
                    <span>{text}</span>
                </div>
            ) : (
                <div className={styles.itemContentOffset}>{text}</div>
            )}
        </button>
    )
}
