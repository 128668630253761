import React, {ComponentType} from "react"

export const retryImport = (componentImport) => {
    return new Promise((resolve, reject) => {
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
        )
        componentImport()
            .then((component) => {
                window.sessionStorage.setItem("retry-lazy-refreshed", "false")
                resolve(component)
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    window.sessionStorage.setItem("retry-lazy-refreshed", "true")
                    return window.location.reload()
                }
                reject(error)
            })
    }) as Promise<{default: ComponentType<any>}>
}

export const reactLazyRetry = (componentImport) => React.lazy(() => retryImport(componentImport))
