import React, {memo} from "react"
import {usePortalSectionContext} from "../../context/portalSectionContext"
import {ReactComponent as InstitutionIcon} from "./graphics/institution.svg"
import {ReactComponent as BusinessIcon} from "./graphics/business.svg"
import {ReactComponent as DistrictIcon} from "./graphics/district.svg"
import {ReactComponent as BuildingIcon} from "./graphics/building.svg"
import {PortalSection} from "../../context/portalSectionTypes"
import classNames from "classnames"
import styles from "./LocationIcon.module.scss"

interface LocationIconProps {
    /**
     * size_hex
     * @default "18px_999999"
     */
    variant?: "18px_999999" | "18px_444444"
    type: "location" | "building"
    className?: string
    portalSection?: PortalSection
}

/**
 * Renders icon depending on portal section context
 */
export const SectionLocationIcon: React.FC<LocationIconProps> = memo(
    ({variant = "18px_999999", className, type, portalSection: propsPS}) => {
        const {portalSection: contextPS} = usePortalSectionContext()
        const portalSection = propsPS || contextPS

        const cn = classNames(
            {
                // ctrl + click is a great thing, let's allow it for every variant class name
                [styles._18px_999999]: variant == "18px_999999",
                [styles._18px_444444]: variant == "18px_444444",
            },
            className
        )

        if (type === "building") {
            return <BuildingIcon className={classNames(cn, styles.building)} />
        }

        if (portalSection === PortalSection.HigherEd) {
            return <InstitutionIcon className={classNames(cn, styles.institution)} />
        }
        if (portalSection === PortalSection.Business) {
            return <BusinessIcon className={classNames(cn, styles.business)} />
        }
        if (portalSection === PortalSection.School) {
            return <DistrictIcon className={cn} />
        }

        return null
    }
)
