import React, {useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useHistory, useLocation} from "react-router-dom"
import {
    Header,
    HeaderProps,
} from "scholastic-client-components/src/features/PortalHeader/Header/Header"
import {useLogout} from "scholastic-client-components/src/hooks/useLogout"
import {authSelectors} from "scholastic-client-components/src/slices/authSlice"
import {PortalsPanelItem} from "scholastic-client-components/src/types/securityTypes"
import {authAPI} from "../../api/connectedAPI"
import {AppPaths} from "../../lib/routing"
import {
    PortalRoutes,
    PortalSection,
} from "scholastic-client-components/src/context/portalSectionTypes"
import {usePortalSectionContext} from "scholastic-client-components/src/context/portalSectionContext"
import {i18n} from "scholastic-client-components/src/i18n/i18n"
import {useAvailablePortalSectionsPaths} from "../../hooks/useAvailablePortalSectionsPaths"

export const getHomePortalHeaderTitles = () => ({
    home: i18n.t("home_portal:home_page.title"),
    personal: i18n.t("home_portal:personal_page.title"),
})

export const PortalHeader: React.FC = () => {
    const history = useHistory()
    const location = useLocation()

    const {portalSection, role} = usePortalSectionContext()
    const logout = useLogout({api: authAPI, useDispatch})
    const availablePaths = useAvailablePortalSectionsPaths()

    const isBusinessUser = useSelector(authSelectors.getIsBusinessUser) || role === "business"
    const profileOpen = location.pathname.includes(AppPaths.Profile)

    const portalSections: HeaderProps["portalSections"] = useMemo(() => {
        const sections = [
            availablePaths.school?.home && {
                to: PortalRoutes.School,
                portalSection: PortalSection.School,
                active: portalSection === PortalSection.School,
            },
            availablePaths.higherEd?.home && {
                to: PortalRoutes.HigherEd,
                portalSection: PortalSection.HigherEd,
                active: portalSection === PortalSection.HigherEd,
            },
            availablePaths.business?.home && {
                to: PortalRoutes.Business,
                portalSection: PortalSection.Business,
                active: portalSection === PortalSection.Business,
            },
        ].filter(Boolean) as HeaderProps["portalSections"]

        return sections?.length
            ? sections
            : [
                  {
                      to: PortalRoutes.School,
                      portalSection: PortalSection.School,
                      active: portalSection === PortalSection.School,
                  },
              ]
    }, [
        availablePaths.business.home,
        availablePaths.higherEd.home,
        availablePaths.school.home,
        portalSection,
    ])

    const title = useMemo(() => {
        if (location.pathname.includes(AppPaths.Profile))
            return getHomePortalHeaderTitles().personal
        return getHomePortalHeaderTitles().home
    }, [location.pathname])

    return (
        <Header
            page={"HOME"}
            forcedBackground={
                profileOpen
                    ? undefined
                    : "linear-gradient(180deg, #2d363e 0%, rgba(0, 0, 0, 0) 100%)"
            }
            forcedIconColor={profileOpen ? "#FFF" : undefined}
            logoLink={{
                logoIcon: isBusinessUser ? "home_business" : "home_edu",
                to: AppPaths.Home,
            }}
            useDispatch={useDispatch}
            useSelector={useSelector}
            history={history}
            portalsMenu={{
                currentPortal: PortalsPanelItem.Home,
                subRoutes: true,
            }}
            leftItems={[]}
            portalSections={profileOpen ? [] : portalSections}
            selectedPortalSection={role}
            logout={logout}
            title={title}
        />
    )
}
