import React, {FC, ReactNode, useLayoutEffect, useRef} from "react"
import Bugsnag, {BrowserConfig} from "@bugsnag/js"
import BugsnagPluginReact, {
    BugsnagErrorBoundary,
    BugsnagPluginReactResult,
} from "@bugsnag/plugin-react"
import {BUGSNAG_API_KEY, BUGSNAG_APP_VERSION} from "../constants"
import {getEnvironmentURL} from "../utilities/getEnvironmentURL"

export const BugsnagErrorBoundaryWrapper: FC<{children?: ReactNode}> = ({children}) => {
    const releaseStage = getEnvironmentURL()
    const plugin = useRef<BugsnagPluginReactResult | undefined>(undefined)
    const Component = useRef<BugsnagErrorBoundary>(React.Fragment)

    useLayoutEffect(() => {
        if (!releaseStage) {
            return
        }
        const bugsnagConfig: BrowserConfig = {
            apiKey: BUGSNAG_API_KEY,
            plugins: [new BugsnagPluginReact()],
            appVersion: BUGSNAG_APP_VERSION,
        }
        Bugsnag.start({...bugsnagConfig, releaseStage})

        plugin.current = Bugsnag.getPlugin("react")

        if (plugin && plugin.current) {
            Component.current = plugin.current.createErrorBoundary(React)
        }
    }, [releaseStage])

    return <Component.current>{children}</Component.current>
}
