import React, {ReactNode} from "react"
import {Link} from "react-router-dom"
import styles from "./ErrorPageTemplate.module.scss"
import {Box} from "../../../ui/Box/Box"
import {H1} from "../../../ui/heading/heading"
import classNames from "classnames"

export interface ErrorPageTemplateProps {
    variant?: "home_403" | "full_height"
    children: {
        icon: ReactNode
        title: string
        message?: string
        links?: ReactNode
    }
    url?: string
}

export const ErrorPageTemplate: React.FC<ErrorPageTemplateProps> = ({
    variant,
    children: {icon, title, message, links},
    url = "/",
}) => {
    return (
        <div
            className={classNames(styles["error-page"], {
                [styles.home_403]: variant === "home_403",
                [styles.full_height]: variant === "full_height",
            })}
        >
            {icon}
            <Box mb={1} />
            <H1>{title}</H1>
            <Box mb={0.687} />
            <span>{message}</span>
            <div>{links || <Link to={url}>Go to main page</Link>}</div>
        </div>
    )
}
