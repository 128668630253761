import React, {ReactNode, useEffect, useRef, useState} from "react"
import styles from "./CopiedHint.module.scss"
import {Icon} from "../Icon/Icon"
import {CopiedPopup} from "./CopiedPopup"

interface ICopiedHint {
    hintText: string
    textToCopy: string
    showTextEnding?: boolean
    fadeoutTime?: number
    selectionRange?: number | boolean
    className?: string
    showCopyButton?: boolean
}

export const CopiedHint: React.FC<{children?: ReactNode} & ICopiedHint> = ({
    textToCopy,
    hintText = "Text",
    children,
    fadeoutTime = 2150,
    showTextEnding = true,
    selectionRange = 1000,
    className,
    showCopyButton,
}) => {
    const [showHint, setShowHint] = useState(false)

    const fieldTimeoutRef = useRef<number>()
    const inputRef = useRef<HTMLTextAreaElement>(null)

    const hideRestHints = () => {
        const hints = document.querySelectorAll(`.${styles.copiedHint}.${styles.openHint}`)
        hints.forEach((hint) => {
            hint.classList.add(styles.closeHint)
        })
    }

    const handleCopyText = () => {
        if (showHint) return
        if (inputRef.current) {
            hideRestHints()
            inputRef.current.value = textToCopy
            inputRef.current.select()
            typeof selectionRange === "number" &&
                inputRef.current.setSelectionRange(0, selectionRange)
            document.execCommand("copy")

            clearTimeout(fieldTimeoutRef.current)
            fieldTimeoutRef.current = Number(setTimeout(() => setShowHint(false), fadeoutTime))
            setShowHint(true)
        }
    }

    useEffect(() => {
        return () => clearTimeout(fieldTimeoutRef.current)
    }, [])

    return (
        <>
            <div className={className} onClick={handleCopyText}>
                {children}
                {(!children || showCopyButton) && (
                    <div className={styles.copySign}>
                        <Icon
                            name={"copySign"}
                            style={{
                                flexShrink: 0,
                                cursor: "pointer",
                            }}
                        />
                    </div>
                )}
            </div>
            <div className={styles.hiddenInput}>
                <textarea ref={inputRef} />
            </div>

            <CopiedPopup
                showTextEnding={showTextEnding}
                hintText={hintText}
                showHint={showHint}
                onClose={() => setShowHint(false)}
                classNames={styles.copied}
            />
        </>
    )
}
