import React from "react"
import {ErrorPageTemplate, ErrorPageTemplateProps} from "../ErrorPageTemplate/ErrorPageTemplate"
import Icon403 from "./403.svg"

type Page403Props = {
    variant?: ErrorPageTemplateProps["variant"]
    url?: string
}

export const Page403: React.FC<Page403Props> = ({variant, url}) => {
    return (
        <ErrorPageTemplate variant={variant} url={url}>
            {{
                icon: <img alt={""} src={Icon403} />,
                title: "No Permission to View This Page",
            }}
        </ErrorPageTemplate>
    )
}
