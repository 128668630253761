import {MobileContext} from "../../context/mobileContext"
import {useContext} from "react"
import {useRem} from "./useRem"

interface ICustomBreakpoint {
    inRange: boolean
    innerWidth: number
}

export const useCustomBreakpoint = (
    remBreakpoint?: number,
    remBottomBreakpoint?: number
): ICustomBreakpoint => {
    let inRange = false
    const remValue = useRem()
    const {innerWidth} = useContext(MobileContext)

    if (!remBreakpoint) return {inRange, innerWidth}

    const pixelBreakPoint = remValue * remBreakpoint
    const pixelBottomBreakPoint = remBottomBreakpoint ? remValue * remBottomBreakpoint : null

    if (pixelBottomBreakPoint) {
        inRange = innerWidth <= pixelBreakPoint && innerWidth >= pixelBottomBreakPoint
    } else {
        inRange = innerWidth <= pixelBreakPoint
    }

    return {inRange, innerWidth}
}
