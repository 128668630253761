import React, {useContext} from "react"
import {Notification} from "../Notification/Notification"
import styles from "./NotificationList.module.scss"
import {NotificationItem} from "../../../slices/notificationListSlice"
import {RemovableNotification} from "../RemovableNotification"
import {Button} from "../../../ui/Button/Button"
import logo from "./logo.svg"
import {MobileContext} from "../../../context/mobileContext"
import {useTranslation} from "react-i18next"

export interface NotificationListProps {
    notifications: Array<NotificationItem>
    showUpdateMessage: boolean
    onRemove: (payload: {id: string}) => void
}

export const NotificationList: React.FC<NotificationListProps> = (props) => {
    const {t} = useTranslation(["common", "shared_components"])
    const {notifications, showUpdateMessage, onRemove} = props
    const {isMobile} = useContext(MobileContext)

    return (
        <div className={styles["notifications-layout"]}>
            {notifications.map((notification) => (
                <RemovableNotification
                    key={notification.id}
                    onRemove={onRemove}
                    {...notification}
                />
            ))}
            {showUpdateMessage && (
                <Notification
                    title={t`shared_components:notifications.system_update.title`}
                    message={
                        <>
                            <p>{t`shared_components:notifications.system_update.p1`}</p>
                            <p>{t`shared_components:notifications.system_update.p2`}</p>

                            <Button
                                style={{marginTop: "0.375rem"}}
                                onClick={() => window.location.reload()}
                                primary
                            >
                                {isMobile ? t`common:ui.reload` : t`common:ui.refresh`}
                            </Button>
                        </>
                    }
                    titleIcon={logo}
                    showIcon={false}
                />
            )}
        </div>
    )
}
