import React, {ReactNode, Suspense} from "react"
import {BurgerProps} from "./interfaces"
import {reactLazyRetry} from "../../utilities/retryImport"

const LazyBurger = reactLazyRetry(() => import("./Burger"))

export const Burger: React.FC<{children?: ReactNode} & BurgerProps> = (props) => (
    <Suspense fallback={null}>
        <LazyBurger {...props} />
    </Suspense>
)
