import {useMemo} from "react"

export const useTriggerLayoutStyles = (triggerRef: HTMLDivElement | null, zIndexPopup: number) => {
    const triggerLayoutStyles = useMemo(() => {
        if (!triggerRef) return {}
        const {height, width} = getComputedStyle(triggerRef)

        return {
            left: "0px",
            top: `-${height}`,
            height,
            width,
            zIndex: zIndexPopup,
        }
    }, [triggerRef, zIndexPopup])

    return {
        triggerRef,
        triggerLayoutStyles,
    }
}
