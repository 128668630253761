import React from "react"
import {useSelector} from "react-redux"
import {PrivateRouteProps} from "scholastic-client-components/src/features/PrivateRoute"
import {usePortalSectionContext} from "scholastic-client-components/src/context/portalSectionContext"
import {Redirect, Route} from "react-router-dom"
import {useAppSelector} from "../hooks/useAppSelector"
import {authSelectors} from "scholastic-client-components/src/slices/authSlice"
import {Home403} from "../pages/Home403"

type AppPrivateRouteProps = Omit<PrivateRouteProps, "useSelector"> & {
    restrictSectionAccess?: boolean
}

export const AppPrivateRoute: React.FC<AppPrivateRouteProps> = ({
    children,
    restrictSectionAccess,
    ...rest
}) => {
    const {selectors} = usePortalSectionContext()
    const portalAccessAllowed = useSelector(authSelectors.getAccessAllowed)
    const authorities = useAppSelector(selectors.getAuthorities)
    const sectionAccessRestricted = restrictSectionAccess && !authorities.length

    return (
        <Route
            {...rest}
            render={(props) =>
                !portalAccessAllowed && !rest.portalAccessAllowed ? (
                    <Redirect to={"/no_access"} />
                ) : sectionAccessRestricted ? (
                    <Home403 />
                ) : (
                    <>{children}</>
                )
            }
        />
    )
}
