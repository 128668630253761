import React, {ReactNode} from "react"
import {FrontErrorPage} from "../pages/FrontErrorPage/FrontErrorPage"
import {UseSelector} from "../lib/reactRedux"

interface State {
    hasError: boolean
    url?: string
    errorInfo?: Error | string
}

const initialState: State = {
    hasError: false,
    url: undefined,
    errorInfo: undefined,
}

interface ErrorBoundaryProps {
    useSelector: UseSelector
    children?: ReactNode
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    public state = initialState

    setError(errorMsg: State["errorInfo"]): void {
        this.setState({hasError: true, errorInfo: errorMsg, url: window.location.href})
    }

    dropError(): void {
        this.setState(initialState)
    }

    setErrorFromEvent: OnErrorEventHandlerNonNull = (msg, url, lineNo, columnNo, error) => {
        //ResizeObserver should warn about it, but it's error
        if (
            msg === "ResizeObserver loop limit exceeded" ||
            msg === "ResizeObserver loop completed with undelivered notifications."
        ) {
            console.warn(msg)
            return
        }

        if (
            typeof msg === "string" &&
            msg.includes("Loading") &&
            msg.includes("chunk") &&
            msg.includes("failed")
        ) {
            console.warn(msg)
            return
        }

        let fullMessage = ""
        fullMessage += typeof msg === "string" ? `msg - ${msg}\n\n` : ""
        fullMessage += url ? `url - ${url}\n\n` : ""
        fullMessage += `lineNo - ${lineNo}\n\n`
        fullMessage += `columnNo - ${columnNo}\n\n`
        fullMessage += error ? `error - ${error.stack || error.message}` : ""
        this.setError(fullMessage)
    }

    componentDidMount(): void {
        window.onerror = this.setErrorFromEvent
    }

    componentWillUnmount(): void {
        window.onerror = null
    }

    componentDidUpdate(): void {
        if (this.state.url && this.state.url !== window.location.href) this.dropError()
    }

    componentDidCatch(error: Error): void {
        this.setError(error)
    }

    render() {
        if (this.state.hasError) {
            return (
                <FrontErrorPage
                    useSelector={this.props.useSelector}
                    errorInfo={this.state.errorInfo}
                />
            )
        }

        return this.props.children
    }
}
