import Popup from "reactjs-popup"
import React, {memo, ReactElement, useEffect, useRef, useState} from "react"
import styles from "./DropdownBase.module.scss"
import "./DropdownBase.scss"
import classNames from "classnames"
import {EventType} from "reactjs-popup/dist/types"

export type Position =
    | "top left"
    | "top center"
    | "top right"
    | "right top"
    | "right center"
    | "right bottom"
    | "bottom left"
    | "bottom center"
    | "bottom right"
    | "left top"
    | "left center"
    | "left bottom"
    | "center center"

export interface DropdownBaseProps {
    position?: Position | Array<Position>
    open?: boolean
    marginTop?: string
    marginBottom?: string
    zIndexPopup?: number
    overflow?: boolean
    maxHeight?: string
    noMinWidth?: boolean
    disabled?: boolean
    closeOnDocumentClick?: boolean
    contentStyle?: React.CSSProperties
    className?: string

    setOpen?: (open: boolean) => void
    onClose?: () => void

    trigger: ReactElement
    children?: JSX.Element

    offsetX?: number
    offsetY?: number

    on?: EventType | Array<EventType>
    hide?: boolean
    nested?: boolean
    mouseEnterDelay?: number

    modalId?: string
    repositionOnResize?: boolean
    animated?: boolean
    animationDelay?: number
}

export const DropdownBase: React.FC<DropdownBaseProps> = memo((props) => {
    const {
        position,
        trigger,
        children,
        open,
        setOpen,
        onClose,
        marginTop = "0.5rem",
        marginBottom,
        zIndexPopup = 1055,
        disabled,
        noMinWidth,
        overflow = true,
        offsetX,
        offsetY,
        on = "click",
        hide,
        nested,
        contentStyle,
        closeOnDocumentClick = true,
        className,
        modalId,
        mouseEnterDelay,
        repositionOnResize = true,
        maxHeight,
        animated,
        animationDelay,
    } = props
    const [localOpen, localSetOpen] = useState(false)

    const handleClose = () => {
        if (setOpen) {
            setOpen(false)
        } else {
            localSetOpen(false)
        }

        if (onClose) {
            onClose()
        }
    }

    const pos = !position
        ? undefined
        : Array.isArray(position)
        ? position
        : ([position, "left top"] as Array<Position>)

    const scrollRef = useRef<number>()

    useEffect(() => {
        const modalElement = modalId ? document.getElementById(modalId) : null
        const modalScrollableElement = modalElement
            ? modalElement
                  .getElementsByTagName("div")[0]
                  .getElementsByTagName("div")[0]
                  .getElementsByTagName("div")[0]
                  .getElementsByTagName("div")[0]
            : null

        const handler = () => {
            const newValue = modalScrollableElement?.scrollTop || 0
            if (scrollRef.current === undefined) scrollRef.current = newValue
            else {
                const delta = Math.abs(newValue - scrollRef.current)
                if (delta > 100) {
                    // probably, popup tried to scroll into view or something
                    modalScrollableElement?.scrollTo({top: scrollRef.current})
                } else scrollRef.current = newValue
            }
        }

        if (modalId) {
            modalScrollableElement?.addEventListener("scroll", handler)
        }
        return () => {
            modalScrollableElement?.removeEventListener("scroll", handler)
        }
    }, [modalId])

    const [opacity, setOpacity] = useState(animated ? 0 : 1)

    const isPopupOpen = open ?? localOpen
    const timeoutRef = useRef<number>()
    useEffect(() => {
        clearTimeout(timeoutRef.current)
    }, [])
    useEffect(() => {
        clearTimeout(timeoutRef.current)
        if (animated) {
            if (isPopupOpen)
                timeoutRef.current = Number(setTimeout(() => setOpacity(1), animationDelay || 0))
            else setOpacity(0)
        }
    }, [isPopupOpen])

    return (
        <Popup
            className={classNames("dropdown-base", className)}
            trigger={trigger}
            open={isPopupOpen}
            onOpen={() => (setOpen ? setOpen(true) : localSetOpen(true))}
            onClose={handleClose}
            arrow={false}
            closeOnDocumentClick={closeOnDocumentClick}
            position={pos}
            overlayStyle={{
                zIndex: zIndexPopup - 5,
            }}
            contentStyle={{
                zIndex: zIndexPopup,
                backgroundColor: "var(--white)",
                width: "auto",
                boxShadow: "0 2px 16px 0 rgba(0, 42, 76, 0.15)",
                padding: "0",
                marginTop: marginTop,
                marginBottom: marginBottom,
                display: hide ? "none" : undefined,
                opacity,
                transition: "opacity linear 0.35s",
                ...contentStyle,
            }}
            repositionOnResize={repositionOnResize}
            disabled={disabled}
            offsetX={offsetX}
            offsetY={offsetY}
            on={on}
            nested={nested}
            mouseEnterDelay={mouseEnterDelay}
        >
            <div
                data-test={"dropdown-base"}
                style={maxHeight ? {maxHeight} : undefined}
                className={classNames(styles.contentWrapper, {
                    [styles.overflowY]: overflow,
                    [styles["-no-min-width"]]: noMinWidth,
                })}
            >
                {children}
            </div>
        </Popup>
    )
})
