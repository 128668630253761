export interface IAllSchools {
    id: -1
    name: string
    fullAddress: ""
    allowToEditRole?: boolean
    allowManageRoles?: boolean
    allowToChangeRole?: boolean
}

export const ALL_SCHOOLS_SELECT_ITEM: IAllSchools = {
    id: -1,
    name: "All schools",
    fullAddress: "",
}

export const ALL_BUILDINGS_SELECT_ITEM: IAllSchools = {
    id: -1,
    name: "All buildings",
    fullAddress: "",
}
