import React from "react"

export const mobileContextInitialValues: IMobileContext = {
    isMobile: false,
    collapseHeaderLeftPart: false,
    innerWidth: document.documentElement.clientWidth,
}

export interface IMobileContext {
    isMobile: boolean
    collapseHeaderLeftPart: boolean
    innerWidth: number
}

export const MobileContext = React.createContext<IMobileContext>(mobileContextInitialValues)
