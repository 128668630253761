import React from "react"
import {Scrollbars as CustomScrollbars, ScrollbarProps} from "react-custom-scrollbars"

export const Scrollbars: React.FC<
    ScrollbarProps & {scrollRef?: React.ClassAttributes<CustomScrollbars>["ref"]}
> = ({scrollRef, ...props}) => {
    const {autoHide = true, autoHideTimeout = 850, autoHideDuration = 350} = props
    return (
        <CustomScrollbars
            autoHide={autoHide}
            autoHideTimeout={autoHideTimeout}
            autoHideDuration={autoHideDuration}
            ref={scrollRef}
            data-test={"scrollbar-container"}
            {...props}
        />
    )
}

export const AdjustableScrollbars: React.FC<
    ScrollbarProps & {scrollRef?: React.ClassAttributes<CustomScrollbars>["ref"]}
> = ({scrollRef, renderThumbVertical, ...props}) => {
    const {
        autoHeight = true,
        autoHeightMax = "none",
        autoHeightMin = "100%",
        autoHide = true,
        autoHideTimeout = 850,
        autoHideDuration = 350,
    } = props
    return (
        <Scrollbars
            autoHeight={autoHeight}
            autoHeightMax={autoHeightMax}
            autoHeightMin={autoHeightMin}
            ref={scrollRef}
            renderThumbVertical={renderThumbVertical}
            {...props}
        />
    )
}
