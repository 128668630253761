import React, {FC, useMemo} from "react"
import {PortalSectionIconProps} from "../PortalSectionIcon/PortalSectionIcon"
import styles from "./PortalSectionPopup.module.scss"
import {useTranslation} from "react-i18next"

interface PortalSectionPopupProps {
    selectedPortalSection?: PortalSectionIconProps["selectedPortalSection"]
    visiblePortalSectionTooltip: boolean
}

export const PortalSectionPopup: FC<PortalSectionPopupProps> = ({
    selectedPortalSection,
    visiblePortalSectionTooltip,
}) => {
    const {t} = useTranslation(["shared_components"])

    const selectedPortalSectionText = useMemo(() => {
        switch (selectedPortalSection) {
            case "school":
                return t("shared_components:portal_header.portal_section_popup.section_name.k12")
            case "business":
                return t(
                    "shared_components:portal_header.portal_section_popup.section_name.business"
                )
            case "higherEd":
                return t(
                    "shared_components:portal_header.portal_section_popup.section_name.higher_ed"
                )
        }
    }, [selectedPortalSection, t])

    if (visiblePortalSectionTooltip) {
        return (
            <div className={styles.portalSectionPopup}>
                {t("shared_components:portal_header.change_view")}
            </div>
        )
    }

    if (!selectedPortalSection) {
        return null
    }
    return (
        <div className={styles.portalSectionPopup}>
            {t("shared_components:portal_header.portal_view_label")}:{" "}
            <strong>{selectedPortalSectionText}</strong>
        </div>
    )
}
