import React, {memo, ReactNode} from "react"
import {PortalSectionContextValue} from "../context/portalSectionTypes"
import {PortalSectionEffector} from "./PortalSectionEffector/PortalSectionEffector"
import {PortalSectionContext} from "../context/portalSectionContext"

interface PortalSectionContextProviderProps {
    children: ReactNode
    value: PortalSectionContextValue
}

export const PortalSectionContextProvider: React.FC<PortalSectionContextProviderProps> = memo(
    ({children, value}) => {
        return (
            <PortalSectionContext.Provider value={value}>
                <PortalSectionEffector />
                {children}
            </PortalSectionContext.Provider>
        )
    }
)
