import React, {ReactNode, useEffect, useMemo, useRef, useState} from "react"
import styles from "./OverflowTextHint.module.scss"
import classNames from "classnames"
import {DropdownBase} from "../DropdownBase/DropdownBase"

interface OverflowTextHintProps {
    text: string
    baseMaxWidth: number | "100%"
    hintMaxWidth?: number
    closeOnClick?: boolean
    hintThresholds?: Array<{length: number; maxWidth: number}>
    renderText?: (text: React.ReactNode) => React.ReactNode
    renderHint?: (text: React.ReactNode) => React.ReactNode
    className?: string
    wrapperClassName?: string
    titleClassName?: string
    ["data-cy"]?: string
    dataTest?: string
    widthCannotBeChanged?: boolean
    onShownHintClick?: () => void
    children?: ReactNode
    immediateHint?: boolean
}

const JustRender = (node: React.ReactNode) => node

interface MountHelperProps {
    onMount: () => void
    children?: ReactNode
    delayMountBy?: number
}

const MountHelper: React.FC<MountHelperProps> = ({onMount, children, delayMountBy}) => {
    const delayRef = useRef<number>()
    useEffect(() => {
        clearTimeout(delayRef.current)
    }, [])

    const [mounted, setMounted] = useState(!delayMountBy)

    useEffect(() => {
        if (mounted) onMount()
    }, [mounted])

    useEffect(() => {
        clearTimeout(delayRef.current)
        if (delayMountBy)
            delayRef.current = Number(setTimeout(() => setMounted(true), delayMountBy))
    }, [delayMountBy])

    return mounted ? <>{children}</> : <></>
}

export const OverflowTextHint: React.FC<OverflowTextHintProps> = ({
    text,
    baseMaxWidth,
    hintMaxWidth,
    hintThresholds,
    renderText = JustRender,
    renderHint = renderText,
    children,
    closeOnClick,
    className,
    wrapperClassName,
    titleClassName,
    ["data-cy"]: dataCy,
    onShownHintClick,
    widthCannotBeChanged = baseMaxWidth !== "100%",
    dataTest,
    immediateHint,
}) => {
    const textRef = useRef<HTMLSpanElement | null>(null)
    const [isOverflow, setIsOverflow] = useState(false)
    useEffect(
        () => {
            if (textRef.current) {
                setIsOverflow(textRef.current.scrollWidth > textRef.current.clientWidth)
            }
        },
        widthCannotBeChanged ? [text] : undefined
    )

    const hintWidth = useMemo(() => {
        let res = hintMaxWidth || 0
        for (const threshold of hintThresholds || []) {
            if (text.length >= threshold.length) {
                if (threshold.maxWidth > res) res = threshold.maxWidth
            }
        }
        const baseMaxWidthNum = Number(baseMaxWidth)
        if (baseMaxWidthNum) return Math.max(baseMaxWidthNum, res)
        else if (res) return res

        return (textRef.current?.clientWidth || 0) / 16
    }, [text, hintMaxWidth, baseMaxWidth, textRef.current])

    const [open, setOpen] = useState(false)

    const handleClick = () => {
        if (closeOnClick) setOpen(false)
        if (onShownHintClick) onShownHintClick()
    }

    const forceResize = () => {
        const {overflowY} = document.body.style
        if (document.body.clientHeight === document.body.scrollHeight)
            document.body.style.overflowY = "hidden"

        setTimeout(() => {
            setTimeout(() => {
                window.dispatchEvent(new Event("resize"))
                document.body.style.overflowY = overflowY
            }, 0)
        }, 0)
    }

    return (
        <DropdownBase
            zIndexPopup={30000}
            open={open}
            on={"hover"}
            setOpen={setOpen}
            hide={!isOverflow}
            position={"bottom left"}
            offsetY={-5}
            offsetX={-16}
            animated={!immediateHint}
            animationDelay={immediateHint ? undefined : 1000}
            trigger={
                <div
                    className={classNames(styles.truncatedWrapper, className, titleClassName, {
                        [styles.align]: children,
                        [styles.fullWidth]: baseMaxWidth === "100%",
                    })}
                >
                    {renderText(
                        <span
                            ref={textRef}
                            style={{
                                maxWidth: baseMaxWidth === "100%" ? "100%" : `${baseMaxWidth}rem`,
                            }}
                            className={styles.wrappedInner}
                            data-cy={dataCy}
                            data-test={dataTest}
                        >
                            {text}
                        </span>
                    )}
                    {children}
                </div>
            }
        >
            <MountHelper onMount={forceResize} delayMountBy={immediateHint ? 0 : 1000}>
                <div
                    className={classNames(styles.fullWrapper, wrapperClassName, {
                        [styles.wrapped]: renderText !== JustRender,
                    })}
                >
                    {renderHint(
                        <span
                            data-test={dataTest ? `full-text-${dataTest}` : undefined}
                            onClick={handleClick}
                            style={{maxWidth: `${hintWidth}rem`}}
                            className={classNames(styles.wrappedInner, className)}
                        >
                            {text}
                        </span>
                    )}
                </div>
            </MountHelper>
        </DropdownBase>
    )
}
