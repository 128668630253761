import {authSelectors} from "../slices/authSlice"
import {
    FormBusinessSelect,
    FormDistrictSelect,
    FormInstitutionSelect,
} from "../features/ReduxForm/FormDistrictSelect"
import {FormBuildingSelect, FormSchoolSelect} from "../features/ReduxForm/FormSchoolSelect"
import {PortalRoutes, PortalSection, PortalSectionContextValue} from "./portalSectionTypes"
import {i18n} from "../i18n/i18n"

const ALL_ID = -1

const locationInteractiveData = {
    options: "locations",
    paramName: "location",
    resetParamsOnChange: ["building"],
}

const buildingInteractiveData = {
    options: "buildings",
    paramName: "building",
}

export const getSchoolSectionContextValue: () => PortalSectionContextValue = () => ({
    portalSection: PortalSection.School,
    portalRoute: PortalRoutes.School,
    role: "school",
    networkType: "SCHOOL",
    locationName: {
        base: i18n.t("glossary:location.k12.base_singular"),
        plural: i18n.t("glossary:location.k12.base_plural"),
        capitalized: i18n.t("glossary:location.k12.capitalized_singular"),
        pluralCapitalized: i18n.t("glossary:location.k12.capitalized_plural"),
    },
    location: {
        selectPlaceholder: i18n.t("glossary:location.k12.select_placeholder"),
        selectPlaceholderValue: {
            id: ALL_ID,
            name: i18n.t("glossary:location.k12.select_all_option"),
        },
        SelectComponent: FormDistrictSelect,
        interactiveFilterField: {
            title: i18n.t("glossary:location.k12.capitalized_singular"),
            ...locationInteractiveData,
        },
    },
    buildingName: {
        base: i18n.t("glossary:building.k12.base_singular"),
        plural: i18n.t("glossary:building.k12.base_plural"),
        capitalized: i18n.t("glossary:building.k12.capitalized_singular"),
        pluralCapitalized: i18n.t("glossary:building.k12.capitalized_plural"),
    },
    building: {
        selectPlaceholder: i18n.t("glossary:building.k12.select_placeholder"),
        selectPlaceholderValue: {
            id: ALL_ID,
            name: i18n.t("glossary:building.k12.select_all_option"),
        },
        SelectComponent: FormSchoolSelect,
        interactiveFilterField: {
            title: i18n.t("glossary:building.k12.capitalized_singular"),
            placeholder: i18n.t("glossary:building.k12.select_all_option"),
            ...buildingInteractiveData,
        },
    },
    buildingTypeName: {
        base: i18n.t("glossary:building_type.k12.base_singular"),
        plural: i18n.t("glossary:building_type.k12.base_plural"),
        capitalized: i18n.t("glossary:building_type.k12.capitalized_singular"),
        pluralCapitalized: i18n.t("glossary:building_type.k12.capitalized_plural"),
    },
    selectors: {
        getUserHasAuthorities: (state, authorities) =>
            authSelectors.getUserHasRoleAuthorities(state, "school", authorities),
        getAuthorities: (state) => authSelectors.getRoleAuthorities(state, "school"),
    },
})

export const getBusinessSectionContextValue: () => PortalSectionContextValue = () => ({
    portalSection: PortalSection.Business,
    portalRoute: PortalRoutes.Business,
    role: "business",
    networkType: "BUSINESS",
    locationName: {
        base: i18n.t("glossary:location.business.base_singular"),
        plural: i18n.t("glossary:location.business.base_plural"),
        capitalized: i18n.t("glossary:location.business.capitalized_singular"),
        pluralCapitalized: i18n.t("glossary:location.business.capitalized_plural"),
    },
    location: {
        selectPlaceholder: i18n.t("glossary:location.business.select_placeholder"),
        selectPlaceholderValue: {
            id: ALL_ID,
            name: i18n.t("glossary:location.business.select_all_option"),
        },
        SelectComponent: FormBusinessSelect,
        interactiveFilterField: {
            title: i18n.t("glossary:location.business.capitalized_singular"),
            ...locationInteractiveData,
        },
    },
    buildingName: {
        base: i18n.t("glossary:building.business.base_singular"),
        plural: i18n.t("glossary:building.business.base_plural"),
        capitalized: i18n.t("glossary:building.business.capitalized_singular"),
        pluralCapitalized: i18n.t("glossary:building.business.capitalized_plural"),
    },
    building: {
        selectPlaceholder: i18n.t("glossary:building.business.select_placeholder"),
        selectPlaceholderValue: {
            id: ALL_ID,
            name: i18n.t("glossary:building.business.select_all_option"),
        },
        SelectComponent: FormBuildingSelect,
        interactiveFilterField: {
            title: i18n.t("glossary:building.business.capitalized_singular"),
            placeholder: i18n.t("glossary:building.business.select_all_option"),
            ...buildingInteractiveData,
        },
    },
    buildingTypeName: {
        base: i18n.t("glossary:building_type.business.base_singular"),
        plural: i18n.t("glossary:building_type.business.base_plural"),
        capitalized: i18n.t("glossary:building_type.business.capitalized_singular"),
        pluralCapitalized: i18n.t("glossary:building_type.business.capitalized_plural"),
    },
    selectors: {
        getUserHasAuthorities: (state, authorities) =>
            authSelectors.getUserHasRoleAuthorities(state, "business", authorities),
        getAuthorities: (state) => authSelectors.getRoleAuthorities(state, "business"),
    },
})

export const getHigherEdSectionContextValue: () => PortalSectionContextValue = () => ({
    portalSection: PortalSection.HigherEd,
    portalRoute: PortalRoutes.HigherEd,
    role: "higherEd",
    networkType: "HIGHER_ED",
    locationName: {
        base: i18n.t("glossary:location.higher_ed.base_singular"),
        plural: i18n.t("glossary:location.higher_ed.base_plural"),
        capitalized: i18n.t("glossary:location.higher_ed.capitalized_singular"),
        pluralCapitalized: i18n.t("glossary:location.higher_ed.capitalized_plural"),
    },
    location: {
        selectPlaceholder: i18n.t("glossary:location.higher_ed.select_placeholder"),
        selectPlaceholderValue: {
            id: ALL_ID,
            name: i18n.t("glossary:location.higher_ed.select_all_option"),
        },
        SelectComponent: FormInstitutionSelect,
        interactiveFilterField: {
            title: i18n.t("glossary:location.higher_ed.capitalized_singular"),
            ...locationInteractiveData,
        },
    },
    buildingName: {
        base: i18n.t("glossary:building.higher_ed.base_singular"),
        plural: i18n.t("glossary:building.higher_ed.base_plural"),
        capitalized: i18n.t("glossary:building.higher_ed.capitalized_singular"),
        pluralCapitalized: i18n.t("glossary:building.higher_ed.capitalized_plural"),
    },
    building: {
        selectPlaceholder: i18n.t("glossary:building.higher_ed.select_placeholder"),
        selectPlaceholderValue: {
            id: ALL_ID,
            name: i18n.t("glossary:building.higher_ed.select_all_option"),
        },
        SelectComponent: FormBuildingSelect,
        interactiveFilterField: {
            title: i18n.t("glossary:building.higher_ed.capitalized_singular"),
            placeholder: i18n.t("glossary:building.higher_ed.select_all_option"),
            ...buildingInteractiveData,
        },
    },
    buildingTypeName: {
        base: i18n.t("glossary:building_type.higher_ed.base_singular"),
        plural: i18n.t("glossary:building_type.higher_ed.base_plural"),
        capitalized: i18n.t("glossary:building_type.higher_ed.capitalized_singular"),
        pluralCapitalized: i18n.t("glossary:building_type.higher_ed.capitalized_plural"),
    },
    selectors: {
        getUserHasAuthorities: (state, authorities) =>
            authSelectors.getUserHasRoleAuthorities(state, "higherEd", authorities),
        getAuthorities: (state) => authSelectors.getRoleAuthorities(state, "higherEd"),
    },
})
