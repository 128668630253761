import React, {Suspense, useContext, useRef, useState} from "react"
import styles from "./Notification.module.scss"
import {Button} from "../../../ui/Button/Button"
import {Box} from "../../../ui/Box/Box"
import NotificationIcon from "./Notification.svg"
import classNames from "classnames"
import {MobileContext} from "../../../context/mobileContext"
import {reactLazyRetry} from "../../../utilities/retryImport"
import {useTranslation} from "react-i18next"

const Details = reactLazyRetry(() => import("./Details"))

export interface NotificationProps {
    id?: string
    title: string
    message: string | React.ReactElement
    details?: string

    onRemove?: (payload: {id: string}) => void
    onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void
    showIcon?: boolean
    titleIcon?: string
}

export const Notification: React.FC<NotificationProps> = (props) => {
    const {t} = useTranslation(["shared_components"])
    const {id, title, message, details, onRemove, onMouseEnter, showIcon = true, titleIcon} = props
    const [openDetails, setOpenDetails] = useState(false)
    const [showCopied, setShowCopied] = useState(false)
    const copiedTimerRef = useRef<NodeJS.Timeout | undefined>()
    const inputRef = useRef<HTMLTextAreaElement>(null)

    const handleCopy = () => {
        if (!details) {
            return
        }

        if (inputRef.current) {
            inputRef.current.value = details
            inputRef.current.select()
            inputRef.current.setSelectionRange(0, 2000)
            document.execCommand("copy")
        }

        setShowCopied(true)
        copiedTimerRef.current = setTimeout(() => {
            setShowCopied(false)
        }, 1000)
    }

    const {isMobile} = useContext(MobileContext)

    return (
        <div className={styles["notification-component"]} onMouseEnter={onMouseEnter}>
            <div className={styles.header}>
                <Box flex={{alignItems: "center"}}>
                    {titleIcon && (
                        <Box mr={0.6875}>
                            <img src={titleIcon} alt="titleIcon" />
                        </Box>
                    )}
                    <h1 className={styles["title"]}>{title}</h1>
                </Box>
                {onRemove && id && (
                    <div className={styles.closeContainer}>
                        <button className={styles.close} onClick={() => onRemove({id})} />
                    </div>
                )}
            </div>
            <div className={styles["body"]}>
                <div className={styles.message}>
                    {showIcon && <img src={NotificationIcon} alt="" />}
                    <span>{message}</span>
                </div>
            </div>

            <div className={styles.hiddenInput}>
                <textarea ref={inputRef} />
            </div>

            {details && (
                <div className={styles.detailsActions}>
                    {isMobile && showCopied ? null : (
                        <Button
                            className={classNames(styles.detailsAction, styles.showDetails, {
                                [styles.openDetails]: openDetails,
                            })}
                            onClick={() => setOpenDetails(!openDetails)}
                            text
                        >
                            {openDetails
                                ? t`shared_components:notifications.hide_details`
                                : t`shared_components:notifications.show_details`}
                        </Button>
                    )}
                    {openDetails &&
                        (showCopied ? (
                            <div
                                className={classNames(styles.copied, {
                                    [styles.showCopied]: showCopied,
                                })}
                            >
                                {t`shared_components:notifications.copied_clipboard`}
                            </div>
                        ) : (
                            <Button onClick={handleCopy} className={styles.detailsAction} text>
                                {t`shared_components:notifications.copy_message`}
                            </Button>
                        ))}
                </div>
            )}
            <Suspense fallback={null}>
                <Details handleCopy={handleCopy} details={openDetails ? details : undefined} />
            </Suspense>
        </div>
    )
}
