import {BlockSizes, dropdownPositions, getDropdownCoords, Position} from "./getDropdownCoords"

interface Rect {
    top: number
    left: number
    width: number
    height: number
}

const checkIfFits = ({left, width, top, height}: Rect) => {
    const ww = document.documentElement.clientWidth
    const wh = document.documentElement.clientHeight

    const intersectsLeft = left < 0
    const intersectsRight = left + width > ww
    const intersectsTop = top < 0
    const intersectsBottom = top + height > wh
    return !intersectsLeft && !intersectsRight && !intersectsTop && !intersectsBottom
}

interface CorrectPosition {
    position: Position
    dropdownCoords: {
        top: number
        left: number
    }
    blockSizes: BlockSizes
    rootRect: DOMRect
    offsetX?: number
    offsetY?: number
}

export const findDropdownPosition = ({
    position,
    dropdownCoords,
    blockSizes,
    offsetX = 0,
    offsetY = 0,
    rootRect,
}: CorrectPosition): Position => {
    if (
        checkIfFits({
            width: blockSizes.dropdownWidth,
            height: blockSizes.dropdownHeight,
            top: dropdownCoords.top,
            left: dropdownCoords.left,
        })
    ) {
        return position
    }

    const fittingPosition = dropdownPositions.find((position) => {
        const pos = getDropdownCoords(position, blockSizes, offsetX, offsetY, rootRect)
        return checkIfFits({
            width: blockSizes.dropdownWidth,
            height: blockSizes.dropdownHeight,
            top: pos.top,
            left: pos.left,
        })
    })

    return fittingPosition || "detached"
}
