import {Environment, environmentUrls} from "../constants"

export const getEnvironmentURL: () => Environment | undefined = () => {
    switch (window.location.host) {
        case environmentUrls[Environment.Prod]:
            return Environment.Prod
        case environmentUrls[Environment.Stable]:
            return Environment.Stable
        case environmentUrls[Environment.Stage]:
            return Environment.Stage
    }
    return
}
