import React from "react"
import {ErrorPageTemplate, ErrorPageTemplateProps} from "../ErrorPageTemplate/ErrorPageTemplate"
import Icon404 from "./404.svg"

export interface Page404Props {
    match: {
        url: string
    }
    variant?: ErrorPageTemplateProps["variant"]
}

export const Page404: React.FC<Page404Props> = ({match: {url}, variant}) => (
    <ErrorPageTemplate url={url} variant={variant}>
        {{
            icon: <img alt={""} src={Icon404} />,
            title: "Page is Not Found",
            message: "You have entered an incorrect address",
        }}
    </ErrorPageTemplate>
)
