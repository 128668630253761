import React from "react"
import styles from "./BurgerNavLink.module.scss"
import {Link} from "react-router-dom"
import classNames from "classnames"
import {NavigationLinkProps} from "../../NavigationLink/NavigationLink"

export const BurgerNavLink: React.FC<NavigationLinkProps> = ({
    to,
    isActive,
    onClick,
    text,
    orange,
}) => {
    return (
        <Link
            to={to ? to : ""}
            className={classNames(styles.navLink, {
                [styles.navLink_orange]: orange,
                [styles.navLink_active]: isActive,
            })}
            onClick={onClick}
            data-cy={`headerLink${text}`}
        >
            <span>{text}</span>
        </Link>
    )
}
