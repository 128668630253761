import React, {useEffect, useRef} from "react"
import styles from "./FrontErrorPage.module.scss"
import image from "../../graphics/FrontError.svg"
import Bugsnag from "@bugsnag/js"
import {Text} from "../../ui/Text/Text"
import {Box} from "../../ui/Box/Box"
import {Portal, PortalRoute} from "../../types/securityTypes"
import {CopiedHint} from "../../ui/CopiedHint/CopiedHint"
import {UseSelector} from "../../lib/reactRedux"
import {authSelectors} from "../../slices/authSlice"
import {getEnvironmentURL} from "../../utilities/getEnvironmentURL"
import {Environment} from "../../constants"
import {LastRequestResponse} from "../../lib/axios"
import {Trans, useTranslation} from "react-i18next"

interface IFrontErrorPage {
    errorInfo?: Error | string
    useSelector: UseSelector
}

export const FrontErrorPage: React.FC<IFrontErrorPage> = ({errorInfo, useSelector}) => {
    const {t} = useTranslation(["shared_components"])
    const releaseStage = getEnvironmentURL()
    const {id, email, fullName} = useSelector(authSelectors.getUserData) || {}
    const authorities = useSelector(authSelectors.getAuthorities)

    const errorText = errorInfo
        ? typeof errorInfo === "string"
            ? errorInfo
            : errorInfo.stack || errorInfo.message
        : ""

    const timeoutRef = useRef<number>()

    useEffect(() => {
        clearTimeout(timeoutRef.current)
    }, [])

    useEffect(() => {
        clearTimeout(timeoutRef.current)

        const handleNotify = (email?: string, fullName?: string, id?: number) => {
            Bugsnag.setUser(id?.toString(), email, fullName)
            if (releaseStage === Environment.Prod) {
                Bugsnag.addMetadata("authorities", {authorities})
                Bugsnag.addMetadata("lastResponse", LastRequestResponse)
            }
            Bugsnag.notify(new Error(errorText))
        }

        const tryToNotify = (email?: string, fullName?: string, id?: number) => {
            if (errorText) {
                if (Bugsnag.isStarted()) {
                    handleNotify(email, fullName, id)
                } else {
                    timeoutRef.current = Number(
                        setTimeout(() => {
                            tryToNotify(email, fullName, id)
                        }, 250)
                    )
                }
            }
        }
        tryToNotify(email, fullName, id)
    }, [authorities, email, errorText, fullName, id, releaseStage])

    return (
        <div className={styles.errorPage}>
            <div className={styles.imageWrapper}>
                <img src={image} alt="front-error" />
            </div>
            <Box className={styles.textBlock} mt={1.625}>
                <Text color="warm-grey">
                    <Trans i18nKey={"shared_components:error_page.message"} />
                </Text>
            </Box>
            <Box mt={1.5}>
                <a
                    href={window.location.origin + PortalRoute[Portal.Home]}
                    className={styles.homeBtn}
                >
                    {t("common:ui.home_button")}
                </a>
            </Box>
            <Box mt={1.6}>
                <CopiedHint
                    hintText={t("shared_components:error_page.copied_hint")}
                    textToCopy={errorText}
                    selectionRange={false}
                    showTextEnding={false}
                >
                    <Text className={styles.copiedHint}>
                        {t("shared_components:error_page.copy_error")}
                    </Text>
                </CopiedHint>
            </Box>
        </div>
    )
}
