import React, {memo} from "react"
import {CRUDSuccessPopup} from "../../features/CRUDSuccessPopup/CRUDSuccessPopup"
import {BodyPortal} from "../../features/BodyPortal"
import {SmoothComponent} from "../SmoothComponent/SmoothComponent"
import CopyIcon from "./copy.svg"
import styles from "./CopiedHint.module.scss"

interface CopiedPopupProps {
    showHint: boolean
    hintText: string
    showTextEnding: boolean
    onClose?: () => void
    classNames?: string
}

export const CopiedPopup: React.FC<CopiedPopupProps> = memo(
    ({showHint, hintText, showTextEnding, classNames}) => {
        return (
            <BodyPortal>
                <SmoothComponent show={showHint}>
                    <CRUDSuccessPopup
                        classes={classNames}
                        icon={<img className={styles.copyIcon} src={CopyIcon} alt={""} />}
                    >
                        {hintText} {showTextEnding ? " copied to clipboard" : ""}
                    </CRUDSuccessPopup>
                </SmoothComponent>
            </BodyPortal>
        )
    }
)
