import React, {useEffect, useRef, useState} from "react"
import classNames from "classnames"
import styles from "./styles.module.scss"
import CSS from "csstype"

export enum SmoothAnimations {
    OPACITY,
    TO_RIGHT,
    TO_LEFT,
    TO_TOP,
    TO_BOTTOM,
}

interface ISmoothComponent {
    show: boolean
    children: React.ReactNode
    style?: CSS.Properties
    animation?: SmoothAnimations
    "data-cy"?: string
}

enum ComponentState {
    UNMOUNTED,
    MOUNTING,
    RENDERED,
    UNMOUNTING,
}

const ANIMATION_DURATION = 0.35

export const SmoothComponent: React.FC<ISmoothComponent> = ({
    show,
    children,
    style,
    animation = SmoothAnimations.OPACITY,
    "data-cy": dataCy,
}) => {
    const [state, setState] = useState<ComponentState>(ComponentState.UNMOUNTED)
    const timeoutRef = useRef<NodeJS.Timeout>()
    const idleTimeoutRef = useRef<NodeJS.Timeout>()

    useEffect(() => {
        setState((state) => {
            clearTimeout(Number(idleTimeoutRef.current))
            idleTimeoutRef.current = undefined
            if (
                show &&
                (state === ComponentState.UNMOUNTED || state === ComponentState.UNMOUNTING)
            ) {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                    timeoutRef.current = undefined
                }
                idleTimeoutRef.current = setTimeout(() => {
                    setState(ComponentState.RENDERED)
                }, 1000)
                window.requestAnimationFrame(() => {
                    if (idleTimeoutRef.current) {
                        clearTimeout(Number(idleTimeoutRef.current))
                        window.requestAnimationFrame(() =>
                            setState((state) =>
                                state === ComponentState.MOUNTING ? ComponentState.RENDERED : state
                            )
                        )
                    }
                })
                return ComponentState.MOUNTING
            } else if (show) return state
            else if (state === ComponentState.RENDERED || state === ComponentState.MOUNTING) {
                timeoutRef.current = setTimeout(
                    () => setState(ComponentState.UNMOUNTED),
                    ANIMATION_DURATION * 1000
                )
                return ComponentState.UNMOUNTING
            } else return state
        })
    }, [show])

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            if (idleTimeoutRef.current) {
                clearTimeout(idleTimeoutRef.current)
            }
        }
    }, [])

    if (state === ComponentState.UNMOUNTED) return null
    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.mounted]: state === ComponentState.RENDERED,
                [styles.opacity]: animation === SmoothAnimations.OPACITY,
                [styles.toLeft]: animation === SmoothAnimations.TO_LEFT,
                [styles.toRight]: animation === SmoothAnimations.TO_RIGHT,
                [styles.toBottom]: animation === SmoothAnimations.TO_BOTTOM,
                [styles.toTop]: animation === SmoothAnimations.TO_TOP,
            })}
            style={{transitionDuration: `${ANIMATION_DURATION}s`, ...style}}
            data-cy={dataCy}
        >
            {children}
        </div>
    )
}
