export const useNotificationsTimeThreshold = () => {
    const getValue = () => {
        return Number(localStorage.getItem("notificationsThreshold")) || 0
    }
    const setValue = (value: number | undefined) => {
        if (value) {
            const currentValue = getValue()
            if (value > currentValue)
                localStorage.setItem("notificationsThreshold", value.toString())
        } else localStorage.removeItem("notificationsThreshold")
    }

    return {
        getValue,
        setValue,
    }
}
