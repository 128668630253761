import React, {memo, ReactNode} from "react"
import styles from "./PageHeader.module.scss"
import classNames from "classnames"
import {BoxProps} from "../Box/Box"
import {Text} from "../Text/Text"
import {useModalTopStickyContext} from "../../context/modalContexts"
import {OverflowTextHint} from "../OverflowTextHint/OverflowTextHint"

export interface PageHeaderChildren {
    title?: string | ReactNode
    description?: string | ReactNode
    actions?: ReactNode
}

export interface PageHeaderProps {
    className?: string
    actionClassName?: string
    titleClassName?: string

    mb?: BoxProps["mb"]
    nowrap?: boolean

    showSavedMessage?: boolean
    adjacentModalCloseIcon?: boolean

    children?: PageHeaderChildren
    title?: PageHeaderChildren["title"]
    actions?: PageHeaderChildren["actions"]
    description?: PageHeaderChildren["description"]
    secondLineActions?: ReactNode
    logo?: ReactNode
    hintMaxWidth?: number
}

export const PageHeader: React.FC<PageHeaderProps> = memo((props) => {
    const {
        className,
        actionClassName,
        titleClassName,
        mb = 1.75,
        showSavedMessage,
        adjacentModalCloseIcon,
        children,
        nowrap,
        secondLineActions,
        logo,
        hintMaxWidth,
    } = props

    const title = children?.title || props.title
    const actions = children?.actions || props.actions
    const description = children?.description || props.description

    const ModalStickyWrapper = useModalTopStickyContext()

    const TitleAndActions = (
        <>
            {!!title &&
                (typeof title === "string" && nowrap ? (
                    <OverflowTextHint
                        titleClassName={classNames(styles.title, titleClassName)}
                        text={title}
                        baseMaxWidth={"100%"}
                        hintMaxWidth={hintMaxWidth}
                    />
                ) : typeof title === "string" ? (
                    <div className={classNames(styles.title, titleClassName)}>{title}</div>
                ) : (
                    title
                ))}
            <div
                className={classNames(styles.actions, actionClassName, {
                    [styles.adjacentModalCloseIcon]: adjacentModalCloseIcon,
                })}
            >
                {showSavedMessage !== undefined && (
                    <Text
                        small
                        color={"warm-grey"}
                        className={classNames(styles.savedMessage, {
                            [styles["-visible"]]: showSavedMessage,
                        })}
                    >
                        Saved
                    </Text>
                )}
                {actions}
            </div>
        </>
    )

    return (
        <>
            {ModalStickyWrapper && (
                <ModalStickyWrapper>
                    <div className={styles.container}>
                        <div className={classNames(styles.pageHeader, className)}>
                            {TitleAndActions}
                        </div>
                    </div>
                </ModalStickyWrapper>
            )}
            <div className={styles.container} style={mb ? {marginBottom: `${mb}rem`} : undefined}>
                {logo && <div className={classNames(styles.logoBox)}>{logo}</div>}
                <div className={classNames(styles.pageHeader, className)}>
                    {!ModalStickyWrapper && TitleAndActions}
                    {description && (
                        <div
                            className={classNames(
                                styles.description,
                                !secondLineActions && styles.description_fullLine,
                                ModalStickyWrapper && styles.noMarginTop
                            )}
                        >
                            {description}
                        </div>
                    )}
                    {secondLineActions && (
                        <div className={styles.secondLineActions}>{secondLineActions}</div>
                    )}
                </div>
            </div>
        </>
    )
})
