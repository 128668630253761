import {
    PortalRoutes,
    PortalSection,
} from "scholastic-client-components/src/context/portalSectionTypes"
import {useLocation} from "react-router-dom"
import {useMemo} from "react"

interface PathObject {
    [key: string]: string | undefined
}
interface AvailablePathsProps {
    school?: PathObject
    business?: PathObject
    higherEd?: PathObject
}

export const useDefaultPortalPath = (pathsObject: AvailablePathsProps) => {
    const {pathname} = useLocation()

    return useMemo(() => {
        const getDefaultPath = (pathObject?: PathObject) => {
            const paths = Object.values(pathObject || {})
            const notEmptyPaths = paths.filter((item) => !!item)
            return notEmptyPaths.find((item) => pathname.includes(item || "")) || notEmptyPaths[0]
        }

        const defaultSchoolPath = getDefaultPath(pathsObject.school)
        const defaultBusinessPath = getDefaultPath(pathsObject.business)
        const defaultHigherEdPath = getDefaultPath(pathsObject.higherEd)

        return {
            [PortalSection.School]: defaultSchoolPath
                ? PortalRoutes.School + defaultSchoolPath
                : undefined,
            [PortalSection.HigherEd]: defaultHigherEdPath
                ? PortalRoutes.HigherEd + defaultHigherEdPath
                : undefined,
            [PortalSection.Business]: defaultBusinessPath
                ? PortalRoutes.Business + defaultBusinessPath
                : undefined,
        }
    }, [pathname])
}
