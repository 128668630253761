import React from "react"
import {ErrorPageTemplate} from "./ErrorPageTemplate/ErrorPageTemplate"
import Icon403 from "./page403/403.svg"
import {Button} from "../../ui/Button/Button"

export const PageNoAccess: React.FC<{onLoginProposalClick: () => void; homeURL?: string}> = ({
    onLoginProposalClick,
    homeURL,
}) => (
    <ErrorPageTemplate url={homeURL}>
        {{
            icon: <img alt={""} src={Icon403} />,
            title: "No Permission to View This Page",
            links: (
                <>
                    <a href={homeURL}>Go to main page</a> or{" "}
                    <Button onClick={onLoginProposalClick} text>
                        login as another user
                    </Button>
                </>
            ),
        }}
    </ErrorPageTemplate>
)
