import {UseSelector} from "../lib/reactRedux"
import {authSelectors, AuthSliceState, PersonLocale} from "../slices/authSlice"
import {useRef} from "react"
import {APIStatus} from "../types/APITypes"
import {Portal} from "../types/securityTypes"

const portalsWithRoleAuthorities = [
    Portal.Admin,
    Portal.Repository,
    Portal.Menu,
    Portal.Settings,
    Portal.Messages,
    Portal.Content,
    Portal.Users,
    Portal.Screens,
    Portal.Home,
    Portal.Provider,
    Portal.Settings,
]

export const useAuthStorage = (useSelector: UseSelector) => {
    const data = useSelector(authSelectors.getHydrationData)
    const authValueRef = useRef(data)
    authValueRef.current = data

    const getValue = (portal: Portal) => {
        const lastAuthData = localStorage.getItem("lastAuthData")
        const withRoleAuthorities = portalsWithRoleAuthorities.includes(portal)
        const roleAuthorities = withRoleAuthorities
            ? localStorage.getItem(`lastAuthData_roleAuthorities_portal_${portal}`)
            : undefined
        if (!lastAuthData || (withRoleAuthorities && !roleAuthorities)) return undefined
        try {
            const authData = JSON.parse(lastAuthData) as AuthSliceState
            if (withRoleAuthorities && roleAuthorities && authData.person)
                authData.person.roleAuthorities = JSON.parse(roleAuthorities)
            return authData
        } catch (e) {
            console.warn("could not reconstruct auth data from local storage")
        }
    }

    const setValue = () => {
        setTimeout(() => {
            if (authValueRef.current && authValueRef.current.hydrationData.accessAllowed) {
                const {hydrationData, currentPortal} = authValueRef.current
                const dataToHydrate = {
                    ...hydrationData,

                    vacantDevices: undefined,
                    locations: undefined,
                    currentPortal: undefined,
                    accessTokenETagInvalid: undefined,
                    globalUIState: {
                        menuNavigationOpen: false,
                        profileMenuOpen: false,
                        portalSectionMenuOpen: false,
                        notificationMenuOpen: false,
                    },
                    person: {...hydrationData.person, roleAuthorities: undefined},
                }
                try {
                    if (dataToHydrate.logoutStatus === APIStatus.Initial) {
                        localStorage.setItem("lastAuthData", JSON.stringify(dataToHydrate))
                        if (
                            portalsWithRoleAuthorities.includes(currentPortal) &&
                            authValueRef.current.hydrationData?.person?.roleAuthorities
                        )
                            localStorage.setItem(
                                `lastAuthData_roleAuthorities_portal_${currentPortal}`,
                                JSON.stringify(
                                    authValueRef.current.hydrationData.person.roleAuthorities
                                )
                            )
                    }
                } catch (e) {
                    console.warn("could not save auth data to local storage")
                }
            }
        }, 0)
    }

    return {
        getValue,
        setValue,
    }
}

export const removeAuthStorage = () => {
    return () => {
        localStorage.removeItem("lastAuthData")
        for (const portal of portalsWithRoleAuthorities)
            localStorage.removeItem(`lastAuthData_roleAuthorities_portal_${portal}`)
    }
}
