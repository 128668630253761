import React, {Suspense} from "react"
import {SocketProps} from "./interfaces"
import {reactLazyRetry} from "../../utilities/retryImport"

const SocketWrapperComponent = reactLazyRetry(() => import("./SocketWrapper"))

export const SocketWrapper: React.FC<SocketProps> = (props) => (
    <>
        {props.children}
        <Suspense fallback={null}>
            <SocketWrapperComponent {...props} />
        </Suspense>
    </>
)
