import {
    createSelector,
    createSlice,
    PayloadAction,
} from "scholastic-client-components/src/SharedPackages/reduxToolkit"
import {APIStatus} from "scholastic-client-components/src/types/APITypes"
import {HomeResponse, LocationInfo} from "../api/homeAPI"
import {AuthPerson} from "scholastic-client-components/src/api/authAPI"
import {trimList} from "../utilities/helpers"

interface SliceState {
    data?: HomeResponse
    status: APIStatus
    locationInfo: {
        school: LocationInfo
        business: LocationInfo
        higherEd: LocationInfo
    }
}

const initialState: SliceState = {
    status: APIStatus.Initial,
    locationInfo: {
        school: {} as LocationInfo,
        business: {} as LocationInfo,
        higherEd: {} as LocationInfo,
    },
}

export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        startFetch(state: SliceState) {
            state.status = APIStatus.Loading
        },
        failFetch(state: SliceState) {
            state.status = APIStatus.Failure
        },
        successFetch(state: SliceState, action: PayloadAction<HomeResponse>) {
            state.data = action.payload
            state.status = APIStatus.Success
            const locations = state.data?.userLocations
            if (locations) {
                Object.keys(locations).forEach((key) => {
                    const currentLocations = locations[key]
                    if (currentLocations.length) {
                        state.locationInfo[key].locations = trimList(currentLocations)
                        state.locationInfo[key].totalBuildings = currentLocations.reduce(
                            (count, location) => {
                                if (location.locationLevelUser) {
                                    count++
                                }
                                count += location.buildings.length
                                return count
                            },
                            0
                        )
                    }
                })
            }
        },
    },
})

interface Store {
    home: SliceState
}

export const homeSelectors = {
    getStatus: (store: Store) => store.home.status,
    getData: (store: Store) => store.home.data,
    getLocationInfo: createSelector(
        (state: Store) => state.home.locationInfo,
        (state, role: keyof AuthPerson["roleAuthorities"]) => role,
        (locationInfo = {}, role) => {
            if (Object.keys(locationInfo).length) {
                return locationInfo[role] || []
            }
            return null
        }
    ),
}
