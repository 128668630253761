import classNames from "classnames"
import React, {forwardRef, ReactNode, useCallback, useEffect, useState} from "react"
import {Position} from "../../../ui/Dropdown/components/DropdownPosition/hooks/getDropdownCoords"
import {HeaderDropdownItem, HeaderPopupItemProps} from "../HeaderDropdownItem"
import {HeaderItem} from "../HeaderItem/HeaderItem"
import "./HeaderDropdown.module.scss"
import {DropdownList} from "../../../ui/DropdownSelect/DropdownList/DropdownList"
import styles from "./HeaderDropdown.module.scss"
import {DropdownIconTrigger, DropdownIconTriggerProps} from "../PortalsMenu/DropdownIconTrigger"
import {
    SupportDropdownItem,
    SupportDropdownItemProps,
} from "../SupportDropdownItem/SupportDropdownItem"
import {Dropdown} from "../../../ui/Dropdown/Dropdown"
import {Tooltip} from "../../../ui/Tooltip/Tooltip"
import {useDispatch, useSelector} from "react-redux"
import {userGuideSlice} from "../../../slices/userGuideSlice"
import {headerNotificationsSelectors} from "../../../slices/headerNotificationsSlice"
import {userSupportRequestsSelectors} from "../../../slices/userSupportRequestsSlice"
import {APIStatus} from "../../../types/APITypes"

export interface HeaderDropdownProps {
    fullHeightDropdown?: boolean
    title?: string
    yellow?: boolean
    items: Array<HeaderPopupItemProps | SupportDropdownItemProps>
    isActive?: boolean
    isNavigationVisible?: boolean
    getIcon?: DropdownIconTriggerProps["getIcon"]
    offsetX?: number
    children?: JSX.Element
    customDropdownNode?: React.FC<{closeDropdown: () => void}>
    hideYellowBorder?: boolean
    matchHeaderColors?: boolean
    isSupportItems?: boolean
    position?: Position
    dropdownWidth?: string
    dataCy?: string
    externalOpenStateDriver?: {
        open: boolean
        setOpen: (value: boolean) => void
    }
    tooltipPopup?: ReactNode
    visiblePortalSectionTooltip?: boolean
    dropdownListTitle?: string
}

export const HEADER_DROPDOWN_INDEX = 9999

export const HeaderDropdown: React.FC<HeaderDropdownProps> = ({
    children,
    customDropdownNode: CustomDropdown,
    ...props
}) => {
    const {
        title,
        items,
        yellow,
        getIcon,
        isActive,
        isNavigationVisible,
        offsetX,
        hideYellowBorder,
        matchHeaderColors,
        fullHeightDropdown,
        isSupportItems = false,
        dropdownWidth,
        dataCy,
        tooltipPopup,
        visiblePortalSectionTooltip,
        position = "bottom center",
        externalOpenStateDriver,
        dropdownListTitle,
    } = props
    const dispatch = useDispatch()
    const notificationsInitStatus = useSelector(headerNotificationsSelectors.getInitStatus)
    const supportRequestsInfoStatus = useSelector(userSupportRequestsSelectors.getInfoStatus)
    const [_open, _setOpen] = useState(false)
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const open = externalOpenStateDriver ? externalOpenStateDriver.open : _open
    const setOpen = externalOpenStateDriver ? externalOpenStateDriver.setOpen : _setOpen

    useEffect(() => {
        setOpen(isNavigationVisible ? open : false)
    }, [isNavigationVisible])

    const TriggerButton = forwardRef<HTMLButtonElement, {}>((triggerProps, ref) => (
        <button
            ref={ref}
            className={classNames(styles.dropdown, styles.link, {
                [styles["-open"]]: open,
                [styles["-yellow"]]: yellow,
                [styles["-fullHeightDropdown"]]: fullHeightDropdown,
                [styles["-active"]]: isActive,
                [styles.hideYellowBorder]: isActive,
            })}
            {...triggerProps}
        >
            <div className={styles.content}>{title}</div>
        </button>
    ))

    const handleClick = (item: HeaderPopupItemProps) => {
        if (item.onClick) {
            item.onClick()
        }

        setOpen(false)
    }

    const dropdownItems = isSupportItems
        ? (items as Array<SupportDropdownItemProps>).map((item, index) => (
              <SupportDropdownItem
                  lastItem={index === items.length - 1}
                  handleClose={() => setOpen(false)}
                  key={item.id}
                  {...item}
              />
          ))
        : items.map((item) => (
              <HeaderDropdownItem
                  key={item.id}
                  {...(item as HeaderPopupItemProps)}
                  onClick={() => handleClick(item as HeaderPopupItemProps)}
                  matchHeaderColors={matchHeaderColors}
              />
          ))

    useEffect(() => {
        if (visiblePortalSectionTooltip) {
            // Wait for notifications and support requests
            // We want icons for these two to be rendered before rendering our tooltip
            const notificationsPending = [APIStatus.Loading, APIStatus.Initial].includes(
                notificationsInitStatus
            )
            const supportRequestsPending = [APIStatus.Loading, APIStatus.Initial].includes(
                supportRequestsInfoStatus
            )
            if (notificationsPending || supportRequestsPending) {
                return
            }

            setTooltipOpen(true)
            setInterval(() => {
                setTooltipOpen(false)
                dispatch(userGuideSlice.actions.resetFirstVisitThisPortal())
            }, 10000)
        }
    }, [dispatch, notificationsInitStatus, supportRequestsInfoStatus, visiblePortalSectionTooltip])

    const handleMouseEnter = useCallback(() => {
        if (visiblePortalSectionTooltip) {
            dispatch(userGuideSlice.actions.resetFirstVisitThisPortal())
        }
    }, [dispatch, visiblePortalSectionTooltip])

    return (
        <HeaderItem data-cy={dataCy} onMouseEnter={handleMouseEnter}>
            <Tooltip open={tooltipOpen} setOpen={setTooltipOpen}>
                {{
                    Trigger: (
                        <Dropdown
                            defaultPopupStyles={false}
                            dropdownClass={styles.popup}
                            open={open}
                            setOpen={setOpen}
                            zIndexPopup={HEADER_DROPDOWN_INDEX}
                            trigger={
                                getIcon ? (
                                    <DropdownIconTrigger
                                        open={open}
                                        fullHeightDropdown={fullHeightDropdown}
                                        getIcon={getIcon}
                                        hideYellowBorder={hideYellowBorder}
                                    />
                                ) : (
                                    <TriggerButton />
                                )
                            }
                            dropdownWidth={dropdownWidth}
                            offsetX={matchHeaderColors ? 19 : offsetX}
                            position={position}
                            disablePosCorrection
                        >
                            {dropdownItems.length ? (
                                <DropdownList
                                    items={dropdownItems}
                                    matchHeaderColors={matchHeaderColors}
                                    title={dropdownListTitle}
                                />
                            ) : CustomDropdown ? (
                                <CustomDropdown closeDropdown={() => setOpen(false)} />
                            ) : (
                                children
                            )}
                        </Dropdown>
                    ),
                    Popup: !open ? tooltipPopup : null,
                }}
            </Tooltip>
        </HeaderItem>
    )
}
