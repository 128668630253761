import {SystemNotification} from "../features/SocketWrapper/Notifications/useNotificationsListener"
import {GenericAPIRequest, getCallAPI} from "../lib/axios"

const viewNotifications =
    <RootState>(): GenericAPIRequest<RootState, {items: Array<number>}> =>
    (args) => {
        return getCallAPI<RootState>()({url: "/api/notification/view", canParallel: true, ...args})
    }

const deleteNotification =
    <RootState>(): GenericAPIRequest<RootState, {id: number}> =>
    (args) => {
        return getCallAPI<RootState>()({
            url: "/api/notification/delete",
            canParallel: true,
            ...args,
        })
    }

const fetchInitNotifications =
    <RootState>(): GenericAPIRequest<RootState, null, {items: Array<SystemNotification>}> =>
    (args) => {
        return getCallAPI<RootState>()({url: "/api/notification/list", ...args})
    }

export const getNotificationsAPI = <RootState>() => ({
    viewNotifications: viewNotifications<RootState>(),
    deleteNotification: deleteNotification<RootState>(),
    fetchInitNotifications: fetchInitNotifications<RootState>(),
})
