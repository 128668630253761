import classNames from "classnames"
import React, {forwardRef, memo} from "react"
import {DropdownContentProps} from "../../types"
import styles from "./DropdownContent.module.scss"

export const DropdownContent = memo(
    forwardRef<HTMLDivElement, DropdownContentProps>(
        (
            {
                defaultPopupStyles = true,
                dropdownWidth,
                dropdownClass,
                dropdownPadContainer,
                children,
            },
            ref
        ) => {
            return (
                <div
                    ref={ref}
                    style={{width: dropdownWidth, padding: dropdownPadContainer}}
                    className={classNames(dropdownClass, {
                        [styles.defaultPopupStyles]: defaultPopupStyles,
                    })}
                    data-cy={"dropdown-content"}
                >
                    {children}
                </div>
            )
        }
    )
)
