import React, {useEffect, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Redirect, Route, Switch, useHistory} from "react-router-dom"
import {authSelectors} from "scholastic-client-components/src/slices/authSlice"
import {useLogout} from "scholastic-client-components/src/hooks/useLogout"
import {APIStatus} from "scholastic-client-components/src/types/APITypes"
import {ContainerLayout} from "scholastic-client-components/src/ui/ContainerLayout/ContainerLayout"
import {ErrorBoundary} from "scholastic-client-components/src/features/ErrorBoundary"
import {Loader} from "scholastic-client-components/src/ui/Loader/Loader"
import {PageNoAccess} from "scholastic-client-components/src/pages/ErrorPage/PageNoAccess"
import {authAPI} from "./api/connectedAPI"
import {AppPrivateRoute} from "./lib/AppPrivateRoute"
import {AppPaths} from "./lib/routing"
import {HomePage} from "./pages/Home/HomePage"
import "./stylesheets/index.scss"
import {useSearchParams} from "./hooks/search/useSearchParams"
import {Portal} from "scholastic-client-components/src/types/securityTypes"
import {GreetingsModal} from "scholastic-client-components/src/modals/GreetingsModal/GreetingsModalLazy"
import {LazyWrapper} from "scholastic-client-components/src/features/LazyWrapper"
import {PortalHeader} from "./features/PortalHeader/PortalHeader"
import {PortalRoutes} from "scholastic-client-components/src/context/portalSectionTypes"
import {getPortalSectionContextValue} from "scholastic-client-components/src/context/portalSectionContext"
import {PortalSectionContextProvider} from "scholastic-client-components/src/features/PortalSectionContextProvider"
import {BrowserUpdate} from "scholastic-client-components/src/features/BrowserUpdate/BrowserUpdate"
import {useAvailablePortalSectionsPaths} from "./hooks/useAvailablePortalSectionsPaths"
import {useDefaultPortalPath} from "scholastic-client-components/src/hooks/useDefaultPortalPath"
import {Home403} from "./pages/Home403"
import {DefaultPortalSection} from "scholastic-client-components/src/features/PortalSectionEffector/DefaultPortalSection"
import {Home404} from "./pages/Home404"

const ProfilePage = React.lazy(() => import("./pages/Profile/ProfilePage"))

const LazyProfilePage = () => (
    <>
        <PortalHeader />
        <LazyWrapper title={""} mt={1}>
            <ProfilePage />
        </LazyWrapper>
    </>
)

export const App: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const {
        ["article_id"]: {value},
        ["help_center"]: {setValue},
    } = useSearchParams("article_id", "help_center")

    const logout = useLogout({useDispatch, api: authAPI})

    const homeURL = useSelector(authSelectors.getHomePortalURL)
    const noDevicesTrial = useSelector(authSelectors.getNoDevicesTrial)
    const logoutInProgress = useSelector(authSelectors.getLogoutStatus) === APIStatus.Loading

    const portalSectionContextValue = useMemo(getPortalSectionContextValue, [])

    useEffect(() => {
        if (value) setValue("open")
    }, [])

    if (logoutInProgress)
        return (
            <ContainerLayout>
                <Loader />
            </ContainerLayout>
        )

    return (
        <ErrorBoundary useSelector={useSelector}>
            <BrowserUpdate />

            {!noDevicesTrial && (
                <GreetingsModal
                    portal={Portal.Home}
                    useSelector={useSelector}
                    dispatch={dispatch}
                    appPaths={Object.values(AppPaths)}
                    history={history}
                />
            )}

            <Switch>
                <AppPrivateRoute path={"/"} authorities={[]} exact>
                    {noDevicesTrial ? (
                        <>
                            <PortalHeader />
                            <HomePage />
                        </>
                    ) : (
                        <DefaultPage />
                    )}
                </AppPrivateRoute>

                <AppPrivateRoute
                    component={LazyProfilePage}
                    path={AppPaths.Profile}
                    authorities={[]}
                />

                <Route path={PortalRoutes.School}>
                    <PortalSectionContextProvider value={portalSectionContextValue.school}>
                        <PortalHeader />
                        <Switch>
                            <AppPrivateRoute
                                path={PortalRoutes.School + AppPaths.Home}
                                exact
                                authorities={[]}
                                restrictSectionAccess
                            >
                                <HomePage />
                            </AppPrivateRoute>
                        </Switch>
                    </PortalSectionContextProvider>
                </Route>
                <Route path={PortalRoutes.HigherEd}>
                    <PortalSectionContextProvider value={portalSectionContextValue.higherEd}>
                        <PortalHeader />
                        <Switch>
                            <AppPrivateRoute
                                path={PortalRoutes.HigherEd + AppPaths.Home}
                                exact
                                authorities={[]}
                                restrictSectionAccess
                            >
                                <HomePage />
                            </AppPrivateRoute>
                        </Switch>
                    </PortalSectionContextProvider>
                </Route>
                <AppPrivateRoute path={PortalRoutes.Business} authorities={[]}>
                    <PortalSectionContextProvider value={portalSectionContextValue.business}>
                        <PortalHeader />
                        <Switch>
                            <AppPrivateRoute
                                path={PortalRoutes.Business + AppPaths.Home}
                                exact
                                authorities={[]}
                                restrictSectionAccess
                            >
                                <HomePage />
                            </AppPrivateRoute>
                        </Switch>
                    </PortalSectionContextProvider>
                </AppPrivateRoute>

                <Route path={"/no_access"}>
                    <PageNoAccess onLoginProposalClick={logout} homeURL={homeURL} />
                </Route>
                <Route path={"/403"}>
                    <PortalHeader />
                    <Home403 />
                </Route>
                <Route component={Home404} />
            </Switch>
        </ErrorBoundary>
    )
}

const DefaultPage: React.FC = () => {
    const {
        ["article_id"]: {value},
    } = useSearchParams("article_id")
    const search = value ? `&help_center=open&article_id=${value}` : ""

    const defaultPortalPath = useDefaultPortalPath(useAvailablePortalSectionsPaths())

    return <Redirect to={DefaultPortalSection.getRedirectTo(defaultPortalPath) + search} />
}
