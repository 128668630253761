import {Portal, PortalRoute} from "../../types/securityTypes"

export const getProfileLink = () => {
    const {
        location: {origin, pathname, search},
    } = window

    const encodedUrl = encodeURIComponent(origin + pathname + search)
    const onProfilePage = pathname.includes("/profile")

    if (onProfilePage) {
        return origin + pathname + search
    } else {
        return origin + PortalRoute[Portal.Home] + "/profile?origin=" + encodedUrl
    }
}

export const getCertificatesLink = (isBusinessUser?: boolean) => {
    const {
        location: {origin},
    } = window

    return (
        origin +
        PortalRoute[Portal.Screens] +
        `/${isBusinessUser ? "business_screens_page" : "screens_page"}?certificate_activation=open`
    )
}
