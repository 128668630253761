import {useCallback, useEffect, useLayoutEffect, useRef} from "react"
import {UseSelector, DispatchAction} from "../lib/reactRedux"
import {authSelectors, authSlice} from "../slices/authSlice"
import {setAxiosTokenHeader} from "../lib/axios"
import {AuthResponse} from "../api/authAPI"
import {APIStatus} from "../types/APITypes"
import {Portal} from "../types/securityTypes"
import {removeAuthStorage, useAuthStorage} from "./useAuthStorage"

export const useAuth = (
    portal: Portal,
    dispatch: DispatchAction,
    useSelector: UseSelector,
    authAPI: any
) => {
    const {setValue, getValue} = useAuthStorage(useSelector)
    const clearStorage = removeAuthStorage()

    const retryTimeoutId = useRef<number>()
    const callAuthAPI = useCallback(() => {
        const scholasticNamespace = window.scholasticNamespace
        if (!scholasticNamespace?.authStatus) {
            if (scholasticNamespace) scholasticNamespace.bundleReady = true
            else window.scholasticNamespace = {bundleReady: true} as any

            const storageValue = getValue(portal)
            if (storageValue) {
                setAxiosTokenHeader(storageValue.accessToken, storageValue.accessTokenETag)
                dispatch(authSlice.actions.dehydrate(storageValue))
            } else dispatch(authSlice.actions.startAuth())

            dispatch(
                authAPI.auth({
                    includeHeaders: ["etag"],
                    onSuccess: (data: AuthResponse, _: any, {etag}: any) => {
                        setAxiosTokenHeader(data.token, etag)
                        dispatch(authSlice.actions.successAuth({...data, etag, portal}))
                        setValue()
                    },
                    onError: (error: any) => {
                        clearStorage()
                        dispatch(authSlice.actions.failAuth())
                        if (error?.response?.status && error.response.status > 500) {
                            retryTimeoutId.current = Number(setTimeout(callAuthAPI, 30 * 1000))
                        }
                    },
                })
            )
        } else {
            if (scholasticNamespace.authStatus === APIStatus.Loading) {
                const storageValue = getValue(portal)
                if (storageValue) {
                    setAxiosTokenHeader(storageValue.accessToken, storageValue.accessTokenETag)
                    dispatch(authSlice.actions.dehydrate(storageValue))
                    scholasticNamespace.bundleReady = true
                }

                retryTimeoutId.current = Number(setTimeout(callAuthAPI, 300))
            } else {
                const data: AuthResponse = scholasticNamespace.authResponse
                const etag = scholasticNamespace.authETag
                setAxiosTokenHeader(data.token, etag)
                //@ts-ignore
                scholasticNamespace.authResponse = undefined
                dispatch(authSlice.actions.successAuth({...data, etag, portal}))
                scholasticNamespace.bundleReady = true
                setValue()
            }
        }
    }, [dispatch, authAPI, portal])

    useEffect(() => {
        callAuthAPI()
        return () => {
            clearTimeout(retryTimeoutId.current)
        }
    }, [callAuthAPI])

    const [token, tokenETag] = useSelector(authSelectors.getToken)

    useLayoutEffect(() => {
        if (window.scholasticNamespace?.bundleReady === false) {
            return
        } else {
            setAxiosTokenHeader(token, tokenETag)
        }
    }, [token, tokenETag])

    return useSelector(authSelectors.getAuthValues)
}
