import classNames from "classnames"
import React, {memo} from "react"
import {ReactComponent as SchoolIcon} from "./graphics/school.svg"
import {ReactComponent as BusinessIcon} from "./graphics/business.svg"
import {ReactComponent as HigherEdIcon} from "./graphics/higherEd.svg"
import styles from "./PortalSectionIcon.module.scss"
import {AuthPerson} from "../../../api/authAPI"

export interface PortalSectionIconProps {
    active: boolean
    hover: boolean
    yellow: boolean
    selectedPortalSection?: keyof AuthPerson["roleAuthorities"]
}

export const PortalSectionIcon: React.FC<PortalSectionIconProps> = memo(
    ({active, hover, yellow, selectedPortalSection}) => {
        const className = classNames(styles.icon, {
            [styles.active]: active,
            [styles.hover]: hover,
            [styles.yellow]: yellow,
        })

        return selectedPortalSection === "school" ? (
            <SchoolIcon className={className} />
        ) : selectedPortalSection === "business" ? (
            <BusinessIcon className={className} />
        ) : selectedPortalSection === "higherEd" ? (
            <HigherEdIcon className={className} />
        ) : null
    }
)
