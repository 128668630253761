import React, {Suspense} from "react"
import {GreetingsModalProps} from "./interfaces"
import {reactLazyRetry} from "../../utilities/retryImport"

const GreetingsModalComponent = reactLazyRetry(() => import("./GreetingsModal"))

export const GreetingsModal: React.FC<GreetingsModalProps> = (props) => (
    <Suspense fallback={null}>
        <GreetingsModalComponent {...props} />
    </Suspense>
)
