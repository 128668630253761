import React from "react"
import {WrappedFieldProps} from "redux-form"
import {WrappedFieldInputProps} from "redux-form/lib/Field"
import {
    ALL_BUSINESSES_SELECT_ITEM,
    ALL_DISTRICTS_SELECT_ITEM,
    ALL_INSTITUTIONS_SELECT_ITEM,
} from "../../types/districtTypes"
import {BaseDistrictView} from "../../types/view/district/BaseDistrictView"
import {SelectComponent} from "../../ui/SelectComponent/SelectComponentLazy"
import {i18n} from "../../i18n/i18n"
import {useTranslation} from "react-i18next"

interface DistrictInputProps extends WrappedFieldInputProps {
    value: BaseDistrictView
}

export interface FormDistrictSelectProps extends WrappedFieldProps {
    withAllDistricts?: boolean
    input: DistrictInputProps
    disabled?: boolean

    options: Array<BaseDistrictView>
    "data-cy"?: string
}

export const FormDistrictSelect: React.FC<FormDistrictSelectProps> = (props) => {
    const {
        input: {value, ...input},
        meta: {error, touched},
        withAllDistricts,
        options,
        disabled,
        "data-cy": dataCy,
    } = props

    const {t} = useTranslation(["glossary"])
    const districts = withAllDistricts ? [ALL_DISTRICTS_SELECT_ITEM].concat(options) : options
    const district = withAllDistricts && !value ? ALL_DISTRICTS_SELECT_ITEM : value

    return (
        <SelectComponent
            {...input}
            value={district}
            title={t("glossary:location.k12.capitalized_singular")}
            placeholder={t("glossary:location.k12.select_placeholder")}
            options={districts}
            error={touched ? error : undefined}
            onChange={input.onChange}
            onBlur={() => input.onBlur(district)}
            compactSelector
            disabled={disabled}
            data-cy={dataCy}
        />
    )
}

export const FormBusinessSelect: React.FC<FormDistrictSelectProps> = (props) => {
    const {
        input: {value, ...input},
        meta: {error, touched},
        withAllDistricts,
        options,
        disabled,
        "data-cy": dataCy,
    } = props

    const {t} = useTranslation(["glossary"])
    const businesses = withAllDistricts ? [ALL_BUSINESSES_SELECT_ITEM].concat(options) : options
    const business = withAllDistricts && !value ? ALL_BUSINESSES_SELECT_ITEM : value

    return (
        <SelectComponent
            {...input}
            value={business}
            title={t("glossary:location.business.capitalized_singular")}
            placeholder={t("glossary:location.business.select_placeholder")}
            options={businesses}
            error={touched ? error : undefined}
            onChange={input.onChange}
            onBlur={() => input.onBlur(business)}
            compactSelector
            disabled={disabled}
            data-cy={dataCy}
        />
    )
}

export const FormInstitutionSelect: React.FC<FormDistrictSelectProps> = (props) => {
    const {
        input: {value, ...input},
        meta: {error, touched},
        withAllDistricts,
        options,
        disabled,
        "data-cy": dataCy,
    } = props

    const {t} = useTranslation(["glossary"])

    const businesses = withAllDistricts ? [ALL_INSTITUTIONS_SELECT_ITEM].concat(options) : options
    const business = withAllDistricts && !value ? ALL_INSTITUTIONS_SELECT_ITEM : value

    return (
        <SelectComponent
            {...input}
            value={business}
            title={t("glossary:location.higher_ed.capitalized_singular")}
            placeholder={t("glossary:location.higher_ed.select_placeholder")}
            options={businesses}
            error={touched ? error : undefined}
            onChange={input.onChange}
            onBlur={() => input.onBlur(business)}
            compactSelector
            disabled={disabled}
            data-cy={dataCy}
        />
    )
}
