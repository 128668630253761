import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {v4 as uuid} from "uuid"

export interface NotificationItem {
    id: string
    title: string
    message: string
    data: string
}

const MAX_NOTIFICATIONS_AMOUNT = 3

interface SliceState {
    items: Array<NotificationItem>
    showUpdateMessage: boolean
}

const initialState: SliceState = {
    items: [],
    showUpdateMessage: false,
}

export const notificationListSlice = createSlice({
    name: "notificationList",
    initialState: initialState,
    reducers: {
        setNotification: {
            reducer: (state: SliceState, action: PayloadAction<NotificationItem>) => {
                if (state.items.length >= MAX_NOTIFICATIONS_AMOUNT) {
                    state.items = state.items.slice(1)
                }
                state.items.push(action.payload)

                return state
            },
            prepare: (payload) => {
                payload.id = uuid()
                payload.title = payload.title || "Server Error"

                return {payload: payload}
            },
        },
        removeNotification: (state: SliceState, action: PayloadAction<{id: string}>) => {
            state.items = state.items.filter(
                (notification) => notification.id !== action.payload.id
            )

            return state
        },
        showUpdateMessage: (state: SliceState) => {
            state.showUpdateMessage = true

            return state
        },
    },
})

export interface NotificationListStore {
    notificationList: SliceState
}

export const notificationListSelectors = {
    getNotifications: (store: NotificationListStore) => store.notificationList.items,
    getShowUpdateMessage: (store: NotificationListStore) =>
        store.notificationList.showUpdateMessage,
}
