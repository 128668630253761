import {useCallback, useEffect} from "react"
import {guideAPI} from "../../../../api/guideAPI"
import {DispatchAction} from "../../../../lib/reactRedux"
import {userGuideSlice} from "../../../../slices/userGuideSlice"
import {usePortalSectionContext} from "../../../../context/portalSectionContext"

export const useFetchGreetings = (dispatch: DispatchAction) => {
    const {portalSection} = usePortalSectionContext()
    const fetch = useCallback(() => {
        dispatch(
            guideAPI().fetchGreetings({
                onSuccess: (response) => {
                    dispatch(userGuideSlice.actions.successGreetingsFetch(response))
                },
            })
        )
    }, [dispatch, portalSection])

    useEffect(() => {
        fetch()
    }, [fetch])
}
