import React from "react"
import {PortalsPanelItem} from "../../../../../../scholastic-client-components/src/types/securityTypes"

import {ReactComponent as Admin} from "../../../../../public/graphics/portals/portal_list_admin.svg"
import {ReactComponent as Alerts} from "../../../../../public/graphics/portals/portal_list_alerts.svg"
import {ReactComponent as BusinessHome} from "../../../../../public/graphics/portals/portal_list_business_home.svg"
import {ReactComponent as Content} from "../../../../../public/graphics/portals/portal_list_content.svg"
import {ReactComponent as Home} from "../../../../../public/graphics/portals/portal_list_home.svg"
import {ReactComponent as Menu} from "../../../../../public/graphics/portals/portal_list_menu.svg"
import {ReactComponent as Messages} from "../../../../../public/graphics/portals/portal_list_messages.svg"
import {ReactComponent as News} from "../../../../../public/graphics/portals/portal_list_news.svg"
import {ReactComponent as Provider} from "../../../../../public/graphics/portals/portal_list_provider.svg"
import {ReactComponent as Reports} from "../../../../../public/graphics/portals/portal_list_reports.svg"
import {ReactComponent as Repository} from "../../../../../public/graphics/portals/portal_list_repository.svg"
import {ReactComponent as Screens} from "../../../../../public/graphics/portals/portal_list_screens.svg"
import {ReactComponent as Student} from "../../../../../public/graphics/portals/portal_list_student.svg"
import {ReactComponent as Users} from "../../../../../public/graphics/portals/portal_list_users.svg"
import {ReactComponent as Settings} from "../../../../../public/graphics/portals/portal_list_settings.svg"

export const PortalIcons: React.FC<{portal: PortalsPanelItem; isBusinessUser?: boolean}> = ({
    portal,
    isBusinessUser,
}) => {
    switch (portal) {
        case PortalsPanelItem.Home:
            return isBusinessUser ? <BusinessHome /> : <Home />
        case PortalsPanelItem.Admin:
            return <Admin />
        case PortalsPanelItem.Menu:
            return <Menu />
        case PortalsPanelItem.Content:
            return <Content />
        case PortalsPanelItem.Student:
            return <Student />
        case PortalsPanelItem.Messages:
            return <Messages />
        case PortalsPanelItem.Repository:
            return <Repository />
        case PortalsPanelItem.Screens:
            return <Screens />
        case PortalsPanelItem.Users:
            return <Users />
        case PortalsPanelItem.News:
            return <News />
        case PortalsPanelItem.Reports:
            return <Reports />
        case PortalsPanelItem.Provider:
            return <Provider />
        case PortalsPanelItem.Alerts:
            return <Alerts />
        case PortalsPanelItem.Settings:
            return <Settings />

        default:
            return null
    }
}
