import React, {ReactNode} from "react"
import classNames from "classnames"
import styles from "./Text.module.scss"
import CSS from "csstype"

export type TextColor =
    | "ocean-blue"
    | "sea-blue"
    | "red"
    | "grassy-green"
    | "yellow"
    | "warm-grey"
    | "dove-grey"
    | "orange"
    | "black"
    | "cinnabar"
    | "salmon"
    | "elf-green"
    | "green-leaf"
    | "light-black"
    | "white"
    | "lighter-grey"

export interface TextProps {
    className?: string
    style?: CSS.Properties
    color?: TextColor
    small?: boolean
    medium?: boolean
    large?: boolean
    lineHeight?: number
    light?: boolean
    dark?: boolean
    semibold?: boolean
    error?: boolean
    errorVisible?: boolean
    limitedWidth?: boolean | string
    uppercase?: boolean
    width?: number
    "data-cy"?: string
    "data-test"?: string
    letterSpacing?: number
    noWrap?: boolean
    limitLines?: number
    hex?: string
    children?: ReactNode
    title?: string
}

export const Text: React.FC<TextProps> = (props) => {
    const {
        className,
        style,
        color,
        small,
        children,
        lineHeight,
        light,
        dark,
        error,
        errorVisible,
        limitedWidth,
        semibold,
        medium,
        large,
        uppercase,
        width,
        "data-cy": dataCy,
        "data-test": dataTest,
        letterSpacing,
        noWrap,
        limitLines,
        hex,
        title,
    } = props

    const resultStyle: CSS.Properties = {
        color: hex,
        maxWidth: typeof limitedWidth === "string" ? limitedWidth : undefined,
        ...style,
        lineHeight: `${lineHeight}rem`,
        width: style?.width || `${width}rem`,
        letterSpacing: `${letterSpacing}rem`,
        WebkitLineClamp: limitedWidth && limitLines ? limitLines : undefined,
    }

    return (
        <span
            data-cy={dataCy}
            data-test={dataTest}
            style={resultStyle}
            className={classNames(styles["text-component"], className, {
                [styles[`-${color}`]]: color,
                [styles["-small"]]: small,
                [styles["-medium"]]: medium,
                [styles["-large"]]: large,
                [styles.light]: light,
                [styles.dark]: dark,
                [styles.errorText]: error,
                [styles.visible]: errorVisible,
                [styles.overflow]: limitedWidth,
                [styles.noWrap]: typeof limitedWidth === "boolean" || noWrap,
                [styles.semibold]: semibold,
                [styles.uppercase]: uppercase,
                [styles.limitLines]: limitLines && limitedWidth,
            })}
            title={title}
        >
            {children}
        </span>
    )
}
