import React, {ReactNode, useContext} from "react"
import {ModalLoaderContext} from "../../context/modalContexts"
import ReactDOM from "react-dom"

export const ModalLoaderPortal: React.FC<{children: ReactNode}> = ({children}) => {
    const loaderBox = useContext(ModalLoaderContext)

    if (!loaderBox) {
        return null
    }
    return ReactDOM.createPortal(children, loaderBox)
}
