import React from "react"
import {Link} from "react-router-dom"
import styles from "./HeaderLogo.module.scss"
import {ReactComponent as AdminIcon} from "./graphics/admin.svg"
import {ReactComponent as ContentIcon} from "./graphics/content.svg"
import {ReactComponent as LibrariesIcon} from "./graphics/libraries.svg"
import {ReactComponent as MenuIcon} from "./graphics/menu.svg"
import {ReactComponent as MessagesIcon} from "./graphics/messages.svg"
import {ReactComponent as NewsIcon} from "./graphics/news.svg"
import {ReactComponent as ProviderIcon} from "./graphics/provider.svg"
import {ReactComponent as ReportsIcon} from "./graphics/reports.svg"
import {ReactComponent as ScreensIcon} from "./graphics/screen.svg"
import {ReactComponent as SettingsIcon} from "./graphics/settings.svg"
import {ReactComponent as StudentIcon} from "./graphics/student.svg"
import {ReactComponent as SupportIcon} from "./graphics/support.svg"
import {ReactComponent as UsersIcon} from "./graphics/user.svg"
import {ReactComponent as HomeEduIcon} from "./graphics/home_edu.svg"
import {ReactComponent as HomeBusinessIcon} from "./graphics/home_business.svg"
import {useDispatch} from "react-redux"
import {authSlice} from "../../../slices/authSlice"

export const logoSVG = {
    admin: <AdminIcon />,
    content: <ContentIcon />,
    libraries: <LibrariesIcon />,
    menu: <MenuIcon />,
    messages: <MessagesIcon />,
    news: <NewsIcon />,
    provider: <ProviderIcon />,
    reports: <ReportsIcon />,
    screens: <ScreensIcon />,
    settings: <SettingsIcon />,
    student: <StudentIcon />,
    support: <SupportIcon />,
    users: <UsersIcon />,
    home_edu: <HomeEduIcon />,
    home_business: <HomeBusinessIcon />,
}

export interface HeaderLogoProps {
    logoIcon: keyof typeof logoSVG
    to: string
}

export const HeaderLogo: React.FC<HeaderLogoProps> = ({to, logoIcon}) => {
    const dispatch = useDispatch()
    return (
        <Link
            to={to}
            className={styles["header-logo"]}
            data-cy="header-logo"
            onClick={() => dispatch(authSlice.actions.setMenuNavigationState(false))}
        >
            <div className={styles["logo"]}>{logoSVG[logoIcon]}</div>
        </Link>
    )
}
