import {useCallback, useRef} from "react"
import {authSlice} from "../slices/authSlice"
import {useNotificationsTimeThreshold} from "./useNotificationsTimeThreshold"
import {cancelRequest, LastRequests} from "../lib/axios"
import {removeAuthStorage} from "./useAuthStorage"
import {DefaultPortalSection} from "../features/PortalSectionEffector/DefaultPortalSection"

interface LogoutFnParam {
    onSuccess: () => void
    onError: () => void
}

interface LogoutParams {
    useDispatch: () => any
    api: {
        logout: (param: LogoutFnParam) => Object
    }
}

export const useLogout = (params: LogoutParams) => {
    const {useDispatch, api} = params
    const dispatch = useDispatch()
    const lastRequests = useRef(LastRequests.items)
    lastRequests.current = LastRequests.items

    const clearStorage = removeAuthStorage()

    const {setValue} = useNotificationsTimeThreshold()
    return useCallback(() => {
        for (const request of lastRequests.current) cancelRequest(request)
        clearStorage()
        DefaultPortalSection.value = null
        dispatch(authSlice.actions.startLogout())
        dispatch(
            api.logout({
                onSuccess: () => {
                    setValue(undefined)
                    window.location.replace("/auth")
                },
                onError: () => window.location.replace("/auth"),
            })
        )
    }, [dispatch])
}
