import React from "react"
import {useSelector} from "react-redux"
import {authSelectors} from "scholastic-client-components/src/slices/authSlice"
import {Authorities} from "scholastic-client-components/src/types/securityTypes"
import {Box} from "scholastic-client-components/src/ui/Box/Box"
import {Text} from "scholastic-client-components/src/ui/Text/Text"
import {Button} from "scholastic-client-components/src/ui/Button/Button"
import {HomeResponse} from "../../../../api/homeAPI"
import {useHomePageAdaptive} from "../../adaptive/hooks/useHomePageAdaptive"
import {AdminGreeting, LocationInfo} from "./features/NetworkBlocks"
import styles from "./UserInfo.module.scss"
import {usePortalSectionContext} from "scholastic-client-components/src/context/portalSectionContext"
import {useAppSelector} from "../../../../hooks/useAppSelector"
import {homeSelectors} from "../../../../slices/homeSlice"
import {useTranslation} from "react-i18next"
import {PortalSection} from "scholastic-client-components/src/context/portalSectionTypes"

const buildingNameView = (portalSection: PortalSection) => {
    switch (portalSection) {
        case PortalSection.School:
            return "no_k12_buildings"
        case PortalSection.HigherEd:
            return "no_higher_ed_buildings"
        case PortalSection.Business:
            return "no_business_buildings"
    }
}

interface IUserInfo {
    onLogout(): void
    fullName: string
    data?: HomeResponse
    noDevicesTrial: boolean
}

export const UserInfo: React.FC<IUserInfo> = ({onLogout, data, fullName, noDevicesTrial}) => {
    const {t} = useTranslation(["home_portal"])
    const adminAuthority = useSelector(authSelectors.getAuthorities)?.find(
        (item) => item === Authorities.admins.master || item === Authorities.admins.support
    )

    const userNetworkTypes = useSelector(authSelectors.getNetworkTypes)
    const isProviderOnly =
        userNetworkTypes && userNetworkTypes.length === 1 && userNetworkTypes[0] === "PROVIDER"

    const {inRange} = useHomePageAdaptive()
    const {role, portalSection} = usePortalSectionContext()
    const isBusinessUser = useSelector(authSelectors.getIsBusinessUser) || role === "business"

    const locationInfo = useAppSelector((store) => homeSelectors.getLocationInfo(store, role))

    return (
        <Box
            flex={{columnDirection: true, justifyContent: "center", alignItems: "center"}}
            className={styles.userInfo}
        >
            <Box flex={{justifyContent: "center"}}>
                <img
                    src={isBusinessUser ? "graphics/BusinessLogo.svg" : "graphics/YellowLogo.svg"}
                    alt="logo"
                />
            </Box>
            <Box flex={{justifyContent: "center"}} mt={0.625} className={styles.userName}>
                <Text limitedWidth="500px" className={styles.welcome}>
                    {t("home_portal:home_page.welcome", {username: fullName})}
                </Text>
            </Box>

            {adminAuthority && <AdminGreeting adminAuthority={adminAuthority} />}
            {!data && !adminAuthority && <div style={{height: "1.9375rem"}} />}
            {data && !adminAuthority && (
                <>
                    {!isProviderOnly && !!userNetworkTypes?.length ? (
                        <>
                            {locationInfo.totalBuildings > 0 ? (
                                <LocationInfo
                                    locationsList={locationInfo?.locations}
                                    totalBuildings={locationInfo?.totalBuildings}
                                />
                            ) : (
                                <Text color="lighter-grey">
                                    {t(
                                        `home_portal:home_page.person_info.${buildingNameView(
                                            portalSection
                                        )}` as any
                                    )}
                                </Text>
                            )}
                        </>
                    ) : null}
                </>
            )}

            {noDevicesTrial && (
                <div className={styles.noDevicesTrialHint}>
                    <span className={styles.noDevicesTitle}>
                        {t("home_portal:home_page.trial_message.title")}
                    </span>
                    <span>{t("home_portal:home_page.trial_message.paragraph1")}</span>
                    <span>{t("home_portal:home_page.trial_message.paragraph2")}</span>
                    <span>{t("home_portal:home_page.trial_message.paragraph3")}</span>
                </div>
            )}

            <Box className={styles.logout}>
                <Button iconType="exit" onClick={onLogout}>
                    {!inRange && (
                        <Text color="lighter-grey">{t("home_portal:home_page.logout")}</Text>
                    )}
                </Button>
            </Box>
        </Box>
    )
}
