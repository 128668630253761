import React, {
    memo,
    ReactNode,
    Suspense,
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import classNames from "classnames"
import {PortalSectionIcon, PortalSectionIconProps} from "../PortalSectionIcon/PortalSectionIcon"
import styles from "./Header.module.scss"
import {Navigation} from "../Navigation/Navigation"
import {HeaderLogo, HeaderLogoProps} from "../HeaderLogo/HeaderLogo"
import {PortalsMenu, PortalsMenuProps, sectionToPortalPanelGroup} from "../PortalsMenu/PortalsMenu"
import {HeaderDropdown} from "../HeaderDropdown/HeaderDropdown"
import {SettingsIcon} from "../PortalsMenu/SettingsIcon"
import {UserIcon} from "../PortalsMenu/UserIcon"
import {NavigationLinkProps} from "../Navigation/NavigationLink/NavigationLink"
import {HeaderPopupItemProps} from "../HeaderDropdownItem"
import {getHeaderAlerts, headerAlertsSlice} from "../../../slices/headerAlertsSlice"
import {authSelectors, authSlice} from "../../../slices/authSlice"
import {SystemNotification} from "../../SocketWrapper/Notifications/useNotificationsListener"
import {NotificationsDropdown} from "../NotificationsDropdown/NotificationsDropdown"
import {getProfileLink} from "../redirectToProfile"
import {MobileContext} from "../../../context/mobileContext"
import {useNotificationViewMethodProvider} from "../../SocketWrapper/Notifications/hooks"
import {useHeaderHeight} from "./hooks/useHeaderHeight"
import {useNotificationsTimeThreshold} from "../../../hooks/useNotificationsTimeThreshold"
import {useFetchGreetings} from "./hooks/useFetchGreetings"
import {NameIcon} from "../../../ui/Icon/Icon"
import {intersection} from "lodash"
import {
    getCatchEyeNotification,
    getHeaderNotifications,
    headerNotificationsSlice,
} from "../../../slices/headerNotificationsSlice"
import {PortalsPanelItem} from "../../../types/securityTypes"
import {ExternalDependencies} from "../HelpCenter/interfaces"
import {AlertDropdown} from "../AlertDropdown/AlertDropdown"
import {AlertContentProps, AlertPreviewModalProps} from "../AlertDropdown/interfaces"
import {PortalSectionPopup} from "../PortalSectionPopup/PortalSectionPopup"
import {userGuideSelectors} from "../../../slices/userGuideSlice"
import {SectionLocationIcon} from "../../../ui/SectionLocationIcon/SectionLocationIcon"
import {PortalSection} from "../../../context/portalSectionTypes"
import {usePortalSectionContext} from "../../../context/portalSectionContext"
import {reactLazyRetry} from "../../../utilities/retryImport"
import {useTranslation} from "react-i18next"
import {i18n} from "../../../i18n/i18n"
import {DefaultPortalSection} from "../../PortalSectionEffector/DefaultPortalSection"

interface PortalSectionsItem {
    portalSection: PortalSection
    to: string
    active: boolean
}

export interface HeaderProps {
    useSelector: any
    useDispatch: any
    history: any

    logoLink?: HeaderLogoProps

    leftItems: Array<NavigationLinkProps>

    settingsItems?: Array<HeaderPopupItemProps>
    userItems?: Array<HeaderPopupItemProps>
    customUserDropdownOptions?: Array<{text: string; onClick: () => void; position?: number}>
    portalsMenu?: Omit<PortalsMenuProps, "open" | "setOpen" | "portals">
    logout: () => void
    portalSections?: PortalSectionsItem[]
    selectedPortalSection?: PortalSectionIconProps["selectedPortalSection"]

    christmas?: boolean
    forcedIconColor?: string
    forcedBackground?: string
    withAlerts?: boolean

    page: string
    title?: string
}

const HelpCenter = reactLazyRetry(() => import("../HelpCenter/HelpCenter"))
const AlertContent = reactLazyRetry(() => import("../AlertDropdown/AlertContent"))
const NotificationContent = reactLazyRetry(
    () => import("../NotificationsDropdown/NotificationContent")
)
const AlertPreviewModal = reactLazyRetry(() => import("../AlertDropdown/AlertPreviewModal"))

const LazyHelpCenter: React.FC<ExternalDependencies> = (props) => (
    <Suspense fallback={null}>
        <HelpCenter {...props} />
    </Suspense>
)

const LazyAlertContent: React.FC<AlertContentProps> = (props) => (
    <Suspense fallback={<div style={{width: "17.5rem"}} />}>
        <AlertContent {...props} />
    </Suspense>
)

const LazyAlertPreviewModal: React.FC<AlertPreviewModalProps> = (props) => (
    <Suspense fallback={null}>
        <AlertPreviewModal {...props} />
    </Suspense>
)

const LazyNotificationContent: React.FC<{
    notifications: Array<SystemNotification>
    useDispatch: any
}> = (props) => (
    <Suspense fallback={<div style={{width: "23.75rem"}} />}>
        <NotificationContent {...props} />
    </Suspense>
)

const getPortalSectionEnhancement = (): {
    [p in PortalSection]: {id: number; nodeIcon: ReactNode; text: string}
} => ({
    [PortalSection.School]: {
        id: 0,
        nodeIcon: <SectionLocationIcon type={"building"} portalSection={PortalSection.School} />,
        text: i18n.t("shared_components:portal_header.portal_section_popup.section_name.k12"),
    },
    [PortalSection.HigherEd]: {
        id: 3,
        nodeIcon: <SectionLocationIcon type={"location"} portalSection={PortalSection.HigherEd} />,
        text: i18n.t("shared_components:portal_header.portal_section_popup.section_name.higher_ed"),
    },
    [PortalSection.Business]: {
        id: 2,
        nodeIcon: <SectionLocationIcon type={"location"} portalSection={PortalSection.Business} />,
        text: i18n.t("shared_components:portal_header.portal_section_popup.section_name.business"),
    },
})

export const Header: React.FC<HeaderProps> = memo(
    ({
        useSelector,
        useDispatch,
        history,

        logoLink,

        leftItems,
        settingsItems,
        portalsMenu,
        logout,
        portalSections,
        selectedPortalSection,

        forcedIconColor,
        forcedBackground,
        christmas,
        withAlerts = true,
        customUserDropdownOptions,

        page,
        title,
    }) => {
        const {t} = useTranslation(["shared_components"])
        const {portalSection} = usePortalSectionContext()
        const containerRef = useRef<HTMLDivElement>(null)
        const currentPortals: PortalsPanelItem[] = portalsMenu?.currentPortal
            ? Array.isArray(portalsMenu.currentPortal)
                ? portalsMenu.currentPortal
                : [portalsMenu.currentPortal]
            : []

        const dispatch = useDispatch()
        useFetchGreetings(dispatch)

        const [navigationVisible, setNavigationVisible] = useState(false)
        const portalPanelItems = useSelector(authSelectors.getPortalsPanelItems)
        const portalVisible = useSelector(authSelectors.getGlobalUIState).menuNavigationOpen
        const profileMenuOpen = useSelector(authSelectors.getGlobalUIState).profileMenuOpen
        const notificationsMenuOpen = useSelector(
            authSelectors.getGlobalUIState
        ).notificationMenuOpen
        const portalSectionMenuOpen = useSelector(
            authSelectors.getGlobalUIState
        ).portalSectionMenuOpen

        // To make it work for portal without sections, get a last used section from the local storage.
        // If none, context value will fall back to the k-12
        const preservedPortalSection = DefaultPortalSection.value || portalSection
        const portalsMenuItems = portalPanelItems[sectionToPortalPanelGroup[preservedPortalSection]]

        const setPortalVisible = (value: boolean) => {
            dispatch(authSlice.actions.setMenuNavigationState(value))
        }
        const setProfileMenuOpen = (value: boolean) => {
            dispatch(authSlice.actions.setProfileMenuOpen(value))
        }
        const setPortalSectionMenuOpen = (value: boolean) => {
            dispatch(authSlice.actions.setPortalSectionMenuState(value))
        }
        const setNotificationsMenuOpen = (value: boolean) => {
            dispatch(authSlice.actions.setNotificationMenuState(value))
        }

        const [alertPreviewId, setAlertPreviewId] = useState<number | undefined>(undefined)

        const fullName = useSelector(authSelectors.getFullName)
        const isBusinessUser = useSelector(authSelectors.getIsBusinessUser)
        const visiblePortalSectionTooltip = useSelector(
            userGuideSelectors.getVisiblePortalSectionTooltip
        )

        const {collapseHeaderLeftPart: isMobile} = useContext(MobileContext)

        const closeRestBurgers = () => {
            setPortalVisible(false)
            setNavigationVisible(false)
        }

        const burgerHandler = (callback: (value: boolean) => void) => (value: boolean) => {
            closeRestBurgers()
            callback(value)
        }

        const isSomeBurgerOpen = isMobile && (portalVisible || navigationVisible)

        const enhancePortalSections = (item: PortalSectionsItem) => {
            return {
                ...item,
                ...getPortalSectionEnhancement()[item.portalSection],
            }
        }

        const enhanceOnClick = (item: HeaderPopupItemProps) => ({
            ...item,
            onClick: () => {
                closeRestBurgers()
                item.onClick && item.onClick()
            },
        })

        const onProfileItemClick = (item: HeaderPopupItemProps) => ({
            ...item,
            onClick: () => {
                closeRestBurgers()
                dispatch(authSlice.actions.setProfileMenuOpen(true))
                item.onClick && item.onClick()
            },
        })

        let baseUserItems: Array<HeaderPopupItemProps> = [
            {
                id: 0,
                text: fullName,
                to: getProfileLink(),
                external: true,
                icon: "user" as NameIcon,
                limitedWidth: "200px",
            },
        ]

        baseUserItems.push({
            id: baseUserItems.length,
            text: t("shared_components:portal_header.logout"),
            onClick: logout,
            external: true,
            icon: "logout" as NameIcon,
        })

        baseUserItems = baseUserItems.filter(
            (item) => !(customUserDropdownOptions || []).find(({text}) => text === item.text)
        )

        const userItems = [
            ...baseUserItems,
            ...(customUserDropdownOptions || []).map((item, index) => ({
                ...item,
                id: index + baseUserItems.length,
            })),
        ]

        for (let i = 0; i < (customUserDropdownOptions || []).length; i++) {
            const swap = (i: number, j: number) => {
                const t = userItems[i]
                userItems[i] = userItems[j]
                userItems[j] = t
            }
            const customOption = customUserDropdownOptions?.[i]
            if (customOption && customOption.position !== undefined) {
                swap(i + baseUserItems.length, customOption.position)
            }
        }

        const alerts = useSelector(getHeaderAlerts)
        const notifications: Array<SystemNotification> = useSelector(getHeaderNotifications) || []
        const catchEyeNotification: SystemNotification = useSelector(getCatchEyeNotification)
        const {setValue} = useNotificationsTimeThreshold()

        const userPopupOffset =
            containerRef.current &&
            parseFloat(
                window.getComputedStyle(containerRef.current).getPropertyValue("padding-right")
            ) - 5

        const {view} = useNotificationViewMethodProvider(useDispatch)
        const clearCatchEyeNotification = useCallback(
            (viewedId?: number) => {
                dispatch(headerNotificationsSlice.actions.clearCatchEye())
                if (viewedId) {
                    view([viewedId])
                    setValue(catchEyeNotification?.created)
                    dispatch(headerNotificationsSlice.actions.setViewedStatus([viewedId]))
                }
            },
            [dispatch, view, catchEyeNotification?.created]
        )

        const tallerHeader = useHeaderHeight(currentPortals?.[0])

        const userAuthorities = useSelector(authSelectors.getAuthorities) as Array<string>
        const accessibleLeftItems = useMemo(() => {
            return leftItems.map((item) =>
                item.dropdown
                    ? {
                          ...item,
                          dropdown: {
                              ...item.dropdown,
                              items: item.dropdown.items.filter((item) =>
                                  item.authorities
                                      ? !!intersection(userAuthorities, item.authorities).length
                                      : true
                              ),
                          },
                      }
                    : item
            )
        }, [userAuthorities, leftItems])

        useLayoutEffect(() => {
            dispatch(headerAlertsSlice.actions.setPortalSection(portalSection))
        }, [dispatch, portalSection])

        useLayoutEffect(() => {
            if (title) document.title = `TSN | ${title}`
        }, [title])

        return (
            <div
                className={classNames(styles.header, {
                    [styles["christmas-theme"]]: christmas,
                })}
                style={{background: isSomeBurgerOpen ? undefined : forcedBackground}}
                data-cy="portal-header"
                ref={containerRef}
            >
                {logoLink && !isMobile && <HeaderLogo {...logoLink} />}

                <Navigation
                    items={accessibleLeftItems}
                    visible={navigationVisible}
                    setVisible={burgerHandler(setNavigationVisible)}
                    logoLink={logoLink}
                />

                <div className={styles.icons} data-cy={"header-icons"}>
                    {withAlerts && !!alerts.length && (
                        <HeaderDropdown
                            isNavigationVisible={!alertPreviewId}
                            fullHeightDropdown
                            items={[]}
                            getIcon={() => (
                                <AlertDropdown tallerHeader={tallerHeader} alerts={alerts} />
                            )}
                            hideYellowBorder
                        >
                            <LazyAlertContent setOpenModal={setAlertPreviewId} alerts={alerts} />
                        </HeaderDropdown>
                    )}

                    {portalSections && portalSections.length > 1 && selectedPortalSection && (
                        <HeaderDropdown
                            dropdownListTitle={`${t(
                                "shared_components:portal_header.portal_view_label"
                            )}: `}
                            dataCy={"portal-sections"}
                            tooltipPopup={
                                <PortalSectionPopup
                                    visiblePortalSectionTooltip={visiblePortalSectionTooltip}
                                    selectedPortalSection={selectedPortalSection}
                                />
                            }
                            visiblePortalSectionTooltip={visiblePortalSectionTooltip}
                            items={portalSections.map(enhancePortalSections).map(enhanceOnClick)}
                            getIcon={(active, hover) => (
                                <PortalSectionIcon
                                    hover={hover}
                                    active={active}
                                    yellow={visiblePortalSectionTooltip}
                                    selectedPortalSection={selectedPortalSection}
                                />
                            )}
                            externalOpenStateDriver={{
                                setOpen: setPortalSectionMenuOpen,
                                open: portalSectionMenuOpen,
                            }}
                        />
                    )}

                    {!!notifications.length && !window.IS_PLAYWRIGHT && (
                        <HeaderDropdown
                            items={[]}
                            position={"bottom center"}
                            getIcon={(active, hover) => (
                                <NotificationsDropdown
                                    hover={hover}
                                    active={active}
                                    forcedIconColor={forcedIconColor}
                                    notifications={notifications}
                                    catchEyeNotification={catchEyeNotification}
                                    clearCatchEyeNotification={clearCatchEyeNotification}
                                    useDispatch={useDispatch}
                                />
                            )}
                            externalOpenStateDriver={{
                                setOpen: setNotificationsMenuOpen,
                                open: notificationsMenuOpen,
                            }}
                        >
                            <LazyNotificationContent
                                notifications={notifications}
                                useDispatch={useDispatch}
                            />
                        </HeaderDropdown>
                    )}

                    <LazyHelpCenter
                        page={page}
                        history={history}
                        useSelector={useSelector}
                        forcedColor={forcedIconColor}
                        dispatch={dispatch}
                        closeBurgers={isMobile ? closeRestBurgers : undefined}
                    />

                    {settingsItems && (
                        <HeaderDropdown
                            items={settingsItems.map(enhanceOnClick)}
                            getIcon={(active, hover) => (
                                <SettingsIcon hover={hover} active={active} />
                            )}
                        />
                    )}

                    {!!portalsMenuItems?.length && (
                        <PortalsMenu
                            {...portalsMenu}
                            portals={portalsMenuItems}
                            currentPortals={currentPortals}
                            open={portalVisible}
                            setOpen={burgerHandler(setPortalVisible)}
                            forcedColor={forcedIconColor}
                            isBusinessUser={isBusinessUser}
                        />
                    )}

                    <HeaderDropdown
                        position={"bottom right"}
                        items={userItems.map(onProfileItemClick)}
                        offsetX={userPopupOffset || undefined}
                        getIcon={(active, hover) => (
                            <UserIcon hover={hover} active={active} forcedColor={forcedIconColor} />
                        )}
                        externalOpenStateDriver={{
                            setOpen: setProfileMenuOpen,
                            open: profileMenuOpen,
                        }}
                    />

                    <LazyAlertPreviewModal
                        setOpenModal={setAlertPreviewId}
                        alertId={alertPreviewId}
                    />
                </div>
            </div>
        )
    }
)
