import {
    REM_MOBILE_BREAKPOINT,
    REM_MIN_MOBILE_SIZE,
} from "../../features/MobileContextComponent/constants"
import {IMobileContext} from "../../context/mobileContext"
import {useRem} from "./useRem"

// All provided sizes in rem
export interface AdaptiveValue {
    startSize?: number
    minSize?: number
}

interface AdaptiveHookParams extends Pick<IMobileContext, "isMobile">, AdaptiveValue {
    values?: Array<AdaptiveValue | undefined>
    remBreakPoint?: number
    floorResult?: boolean
}

type IUseAdaptiveValues = (params: AdaptiveHookParams) => Array<number | undefined>

// Return undefined if startSize or minSize is not provided
export const useAdaptiveValues: IUseAdaptiveValues = ({
    values,
    remBreakPoint = REM_MOBILE_BREAKPOINT,
    isMobile,
    startSize,
    minSize,
    floorResult,
}) => {
    const remValue = useRem()
    const innerWidthRem = window.innerWidth / remValue

    // return calculated value in pixels
    const calcAdaptiveValue = (params?: AdaptiveValue) => {
        if (!params) return
        const startValue = params.startSize
        const minValue = params.minSize

        if (!startValue || !minValue || !isMobile || !innerWidthRem) return
        if (minValue >= startValue || innerWidthRem <= REM_MIN_MOBILE_SIZE) return minValue

        const addSize = startValue - minValue
        const adaptiveWidth = remBreakPoint - REM_MIN_MOBILE_SIZE

        let adaptValue =
            minValue + addSize * ((innerWidthRem - REM_MIN_MOBILE_SIZE) / adaptiveWidth)

        if (floorResult) adaptValue = Math.floor(adaptValue)

        return adaptValue < minValue ? minValue : adaptValue
    }

    const res: Array<number | undefined> = []

    const pushResult = (params?: AdaptiveValue) => {
        res.push(calcAdaptiveValue(params))
    }

    if (startSize && minSize) pushResult({startSize, minSize})
    values?.forEach(pushResult)

    return res
}
