import React, {ReactNode} from "react"
import styles from "./heading.module.scss"
import classNames from "classnames"
import CSS from "csstype"

interface HeadingProps {
    className?: string
    style?: CSS.Properties
    white?: boolean
    disabled?: boolean
    semibold?: boolean
    children?: ReactNode
}

interface H1Props extends HeadingProps {
    red?: boolean
}

export const H1: React.FC<H1Props> = ({className, white, red, semibold, ...props}) => (
    <h1
        className={classNames(
            styles["h1-component"],
            {[styles.white]: white, [styles.red]: red, [styles.semibold]: semibold},
            className
        )}
        {...props}
    />
)

export const H2: React.FC<HeadingProps> = ({className, white, disabled, semibold, ...props}) => (
    <h2
        className={classNames(
            styles["h2-component"],
            {[styles.white]: white, [styles.disabled]: disabled, [styles.semibold]: semibold},
            className
        )}
        {...props}
    />
)

export const H3: React.FC<HeadingProps> = ({className, white, semibold, ...props}) => (
    <h3
        className={classNames(
            styles["h3-component"],
            {[styles.white]: white, [styles.semibold]: semibold},
            className
        )}
        {...props}
    />
)

export const H4: React.FC<HeadingProps> = ({className, white, semibold, ...props}) => (
    <h4
        className={classNames(
            styles["h4-component"],
            {[styles.white]: white, [styles.semibold]: semibold},
            className
        )}
        {...props}
    />
)
