import React from "react"
import {FormDistrictSelectProps} from "../features/ReduxForm/FormDistrictSelect"
import {InteractiveFilter} from "../slices/interactiveFilters"
import {AuthPerson} from "../api/authAPI"
import {AuthStore} from "../slices/authSlice"

interface DomainName {
    base: string
    capitalized: string
    plural: string
    pluralCapitalized: string
}
interface Domain {
    selectPlaceholder: string
    selectPlaceholderValue: {
        id: number
        name: string
    }
    SelectComponent: React.FC<FormDistrictSelectProps | any>
    interactiveFilterField: InteractiveFilter
}

export interface PortalSectionContextValue {
    portalSection: PortalSection
    portalRoute: PortalRoutes
    role: keyof AuthPerson["roleAuthorities"]
    networkType: "SCHOOL" | "BUSINESS" | "HIGHER_ED"
    locationName: DomainName
    location: Domain
    buildingName: DomainName
    building: Domain
    buildingTypeName: DomainName

    selectors: {
        getUserHasAuthorities: (state: AuthStore, authorities: Array<string>) => boolean
        getAuthorities: (state: AuthStore) => Array<string>
    }
}

export enum PortalSection {
    School = "SCHOOL",
    Business = "BUSINESS",
    HigherEd = "HIGHER_ED",
}

export enum PortalRoutes {
    School = "/school",
    Business = "/business",
    HigherEd = "/higherEd",
}

export const PortalSectionRoutes = {
    [PortalSection.School]: PortalRoutes.School,
    [PortalSection.Business]: PortalRoutes.Business,
    [PortalSection.HigherEd]: PortalRoutes.HigherEd,
}

export const PortalSectionRole: {[p in PortalSection]: keyof AuthPerson["roleAuthorities"]} = {
    [PortalSection.School]: "school",
    [PortalSection.Business]: "business",
    [PortalSection.HigherEd]: "higherEd",
}
