import React from "react"
import {Box} from "../../../../../ui/Box/Box"
import styles from "./BurgerTrigger.module.scss"
import {logoSVG} from "../../../HeaderLogo/HeaderLogo"

const OpenIcon = () => (
    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="18" height="3" rx="1.5" fill="#96B7CA" />
        <path
            d="M0 9.5C0 8.67157 0.671573 8 1.5 8H20.5C21.3284 8 22 8.67157 22 9.5C22 10.3284 21.3284 11 20.5 11H1.5C0.671573 11 0 10.3284 0 9.5Z"
            fill="#96B7CA"
        />
        <rect y="16" width="18" height="3" rx="1.5" fill="#96B7CA" />
    </svg>
)

const CloseIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5 1.5L1.5 18.5" stroke="white" strokeWidth="3" strokeLinecap="round" />
        <path d="M18.5 18.5L1.5 1.5" stroke="white" strokeWidth="3" strokeLinecap="round" />
    </svg>
)

interface BurgerTrigger {
    open: boolean
    logoIcon?: keyof typeof logoSVG
}

export const BurgerTrigger: React.FC<BurgerTrigger> = ({logoIcon, open}) => {
    const DefaultIcon = open ? CloseIcon : OpenIcon
    if (logoIcon)
        return (
            <div className={styles.trigger}>
                <Box flex={{alignItems: "center"}} mr={1.5} width={1.375}>
                    <DefaultIcon />
                </Box>
                <div className={styles.logo}>{logoSVG[logoIcon]}</div>
            </div>
        )
    return <DefaultIcon />
}
