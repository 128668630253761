import React, {useContext, useMemo} from "react"
import {
    getPortalsPanelData,
    PortalsPanelItem,
    getPortalsPanelItemLink,
    PortalsPanelItems,
} from "../../../types/securityTypes"
import {HeaderItem} from "../HeaderItem/HeaderItem"
import menuStyles from "./PortalsMenu.module.scss"
import ContentPortal from "../../../graphics/portals/PRELOAD_ContentPortal.svg"
import ScholasticPortal from "../../../graphics/portals/PRELOAD_ScholasticPortal.svg"
import ScholasticPortalForBusiness from "../../../graphics/portals/PRELOAD_ScholasticPortalForBusiness.svg"
import AdminPortal from "../../../graphics/portals/PRELOAD_AdminPortal.svg"
import ScreensPortal from "../../../graphics/portals/PRELOAD_ScreensPortal.svg"
import UsersPortal from "../../../graphics/portals/PRELOAD_UsersPortal.svg"
import RepositoryPortal from "../../../graphics/portals/PRELOAD_RepositoryPortal.svg"
import StudentPortal from "../../../graphics/portals/PRELOAD_StudentPortal.svg"
import MessagesPortal from "../../../graphics/portals/PRELOAD_MessagesPortal.svg"
import NewsPortal from "../../../graphics/portals/PRELOAD_NewsPortal.svg"
import MenuPortal from "../../../graphics/portals/PRELOAD_MenuPortal.svg"
import ProviderPortal from "../../../graphics/portals/PRELOAD_ProviderPortal.svg"
import ReportsPortal from "../../../graphics/portals/PRELOAD_ReportsPortal.svg"
import Alerts from "../../../graphics/portals/PRELOAD_Alerts.svg"
import Settings from "../../../graphics/portals/PRELOAD_Settings.svg"

import {chunk} from "lodash"
import {DropdownIconTrigger} from "./DropdownIconTrigger"
import {MenuIcon} from "./MenuIcon"
import classNames from "classnames"
import {HEADER_DROPDOWN_INDEX} from "../HeaderDropdown/HeaderDropdown"
import {Box} from "../../../ui/Box/Box"
import {MobileContext} from "../../../context/mobileContext"
import {useAdaptiveValues} from "../../../hooks/adaptive/useAdaptiveValues"
import {Dropdown} from "../../../ui/Dropdown/Dropdown"
import {Burger} from "../../../ui/Burger/LazyBurger"
import {useTranslation} from "react-i18next"
import {PortalRoutes, PortalSection} from "../../../context/portalSectionTypes"
import {usePortalSectionContext} from "../../../context/portalSectionContext"

interface IconLinkProps extends Pick<PortalLink, "currentPortal" | "mini"> {
    src: string
    name: PortalsPanelItem
    width: string
}

const IconLink: React.FC<IconLinkProps> = ({src, name, width, currentPortal, mini}) => {
    const {
        i18n: {language},
    } = useTranslation()
    const portalsPanelViewData = useMemo(getPortalsPanelData, [language])

    return (
        <div
            className={classNames(menuStyles.portalContainer, {
                [menuStyles.active]: currentPortal,
                [menuStyles.miniPortal]: mini,
            })}
        >
            <div
                className={classNames(menuStyles.iconWrapper, {[menuStyles.active]: currentPortal})}
            >
                <div
                    className={classNames(menuStyles.icon, {
                        [menuStyles.active]: currentPortal,
                    })}
                    style={{
                        WebkitMaskImage: `url(${src})`,
                        maskImage: `url(${src})`,
                        width,
                    }}
                />
            </div>
            <span className={menuStyles.name}>{portalsPanelViewData[name].name}</span>
        </div>
    )
}

interface PortalLink {
    portal: PortalsPanelItem
    currentPortal?: boolean
    mini?: boolean
    isBusinessUser?: boolean
}

const PortalLink: React.FC<PortalLink> = ({portal, isBusinessUser, ...iconProps}) => {
    switch (portal) {
        case PortalsPanelItem.Users:
            return <IconLink name={portal} src={UsersPortal} width={"2rem"} {...iconProps} />
        case PortalsPanelItem.Admin:
            return <IconLink name={portal} src={AdminPortal} width={"2rem"} {...iconProps} />
        case PortalsPanelItem.Student:
            return <IconLink name={portal} src={StudentPortal} width={"3rem"} {...iconProps} />
        case PortalsPanelItem.Content:
            return <IconLink name={portal} src={ContentPortal} width={"3rem"} {...iconProps} />
        case PortalsPanelItem.Home:
            return (
                <IconLink
                    name={portal}
                    src={isBusinessUser ? ScholasticPortalForBusiness : ScholasticPortal}
                    width={isBusinessUser ? "3.25rem" : "3rem"}
                    {...iconProps}
                />
            )
        case PortalsPanelItem.Screens:
            return <IconLink name={portal} src={ScreensPortal} width={"2.625rem"} {...iconProps} />
        case PortalsPanelItem.Repository:
            return <IconLink name={portal} src={RepositoryPortal} width={"3rem"} {...iconProps} />
        case PortalsPanelItem.Messages:
            return <IconLink name={portal} src={MessagesPortal} width={"3.25rem"} {...iconProps} />
        case PortalsPanelItem.Menu:
            return <IconLink name={portal} src={MenuPortal} width={"2.5rem"} {...iconProps} />
        case PortalsPanelItem.News:
            return <IconLink name={portal} src={NewsPortal} width={"3.25rem"} {...iconProps} />
        case PortalsPanelItem.Reports:
            return <IconLink name={portal} src={ReportsPortal} width={"3.25rem"} {...iconProps} />

        case PortalsPanelItem.Provider:
            return <IconLink name={portal} src={ProviderPortal} width={"3rem"} {...iconProps} />

        case PortalsPanelItem.Alerts:
            return <IconLink name={portal} src={Alerts} width={"3rem"} {...iconProps} />

        case PortalsPanelItem.Settings:
            return <IconLink name={portal} src={Settings} width={"3rem"} {...iconProps} />

        default:
            return null
    }
}

export const PortalsMenuitem: React.FC<{
    currentPortal?: boolean
    portal: PortalsPanelItem
    mini?: boolean
    isBusinessUser?: boolean
}> = ({portal, mini, currentPortal, isBusinessUser}) => (
    <a
        href={window.location.origin + getPortalsPanelItemLink()[portal]}
        className={menuStyles.link}
    >
        <PortalLink
            currentPortal={currentPortal}
            portal={portal}
            mini={mini}
            isBusinessUser={isBusinessUser}
        />
    </a>
)

export interface PortalsMenuProps {
    portals?: PortalsPanelItem[]
    currentPortal: PortalsPanelItem
    subRoutes?: boolean
    forcedColor?: string

    open: boolean
    setOpen: (value: boolean) => void
    isBusinessUser?: boolean
}

interface TriggerProps {
    open: boolean
    forcedColor?: string
    isMobile: boolean
}

const Trigger: React.FC<TriggerProps> = ({open, forcedColor, isMobile}) => (
    <DropdownIconTrigger
        getIcon={(active, hover) => (
            <MenuIcon hover={hover} active={active} forcedColor={forcedColor} />
        )}
        hideYellowBorder={isMobile}
        open={open}
    />
)

export const sectionToPortalPanelGroup: {[p in PortalSection]: keyof PortalsPanelItems} = {
    [PortalSection.School]: "school",
    [PortalSection.Business]: "business",
    [PortalSection.HigherEd]: "higherEd",
}

export const PortalsMenu: React.FC<
    Omit<PortalsMenuProps, "currentPortal"> & {currentPortals: Array<PortalsPanelItem>}
> = ({portals = [], forcedColor, currentPortals, open, setOpen, isBusinessUser}) => {
    const {isMobile} = useContext(MobileContext)
    const {portalRoute} = usePortalSectionContext()

    const [adaptivePortalNumber] = useAdaptiveValues({
        isMobile,
        startSize: 6,
        minSize: 3,
        floorResult: true,
    })
    const portalNumber = isMobile && adaptivePortalNumber ? adaptivePortalNumber : 4
    const groups = chunk(portals, portalNumber)

    return (
        <HeaderItem>
            {isMobile ? (
                <Burger
                    open={open}
                    setOpen={setOpen}
                    Trigger={({open}) => (
                        <Trigger isMobile={isMobile} open={open} forcedColor={forcedColor} />
                    )}
                    overlayColor={"#003A58"}
                >
                    {groups.map((portalsGroup, i) => (
                        <>
                            <Box key={i} mt={i === 0 ? undefined : 1.25}>
                                <div className={menuStyles.row} key={i}>
                                    {portalsGroup.map((portal, idx, groups) => (
                                        <>
                                            <PortalsMenuitem
                                                key={idx}
                                                currentPortal={currentPortals.includes(portal)}
                                                portal={portal}
                                                isBusinessUser={isBusinessUser}
                                            />
                                            {idx === groups.length - 1 &&
                                                groups.length < portalNumber &&
                                                new Array(portalNumber - groups.length)
                                                    .fill("")
                                                    .map((_, i) => (
                                                        <div
                                                            key={i}
                                                            className={menuStyles.mobileGap}
                                                        />
                                                    ))}
                                        </>
                                    ))}
                                </div>
                            </Box>
                        </>
                    ))}
                </Burger>
            ) : (
                <Dropdown
                    defaultPopupStyles={false}
                    dropdownClass={menuStyles.popup}
                    open={open}
                    setOpen={setOpen}
                    zIndexPopup={HEADER_DROPDOWN_INDEX}
                    trigger={
                        <DropdownIconTrigger
                            getIcon={(active, hover) => (
                                <MenuIcon hover={hover} active={active} forcedColor={forcedColor} />
                            )}
                            hideYellowBorder={isMobile}
                            open={open}
                        />
                    }
                    position={"bottom right"}
                    offsetX={70}
                >
                    <div className={menuStyles.menu}>
                        {groups.map((portalsGroup, idx) => (
                            <div className={menuStyles.row} key={idx}>
                                {portalsGroup.map((portal, idx) => (
                                    <PortalsMenuitem
                                        currentPortal={currentPortals.includes(portal)}
                                        portal={portal}
                                        key={idx}
                                        isBusinessUser={isBusinessUser}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </Dropdown>
            )}
        </HeaderItem>
    )
}
