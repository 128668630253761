import classNames from "classnames"
import React, {forwardRef, ReactNode, useImperativeHandle, useState} from "react"
import styles from "./Tooltip.module.scss"
import {InfoTrigger} from "./InfoTrigger/InfoTooltipTrigger"
import CSS from "csstype"
import {DropdownBase, DropdownBaseProps} from "../DropdownBase/DropdownBase"
import {Dropdown} from "../Dropdown/Dropdown"
import {DropdownProps} from "../Dropdown/types"

interface HoverBoxProps {
    children?: {
        Trigger?: React.ReactElement
        Popup: ReactNode
    }
    infoTrigger?:
        | true
        | {
              hintText: string
              dark?: boolean
          }
    wrapperStyle?: CSS.Properties
    popupClassName?: string
    popup?: ReactNode
    trigger?: ReactNode
}

export interface TooltipRef {
    open: boolean
}
export interface TooltipProps
    extends Omit<DropdownBaseProps, "children" | "trigger">,
        Omit<DropdownProps, "children" | "trigger" | "on" | "position">,
        HoverBoxProps {
    ref?: React.Ref<TooltipRef>
    dataTest?: string
    basedOnComponent?: "dropdown" | "dropdown_base"
    wrapperClassName?: string
    triggerClassName?: string
}

//@ts-ignore
export const Tooltip: React.FC<TooltipProps> = forwardRef<TooltipRef, TooltipProps>(
    (
        {
            infoTrigger,
            children,
            wrapperStyle,
            popupClassName,
            trigger: propsTrigger,
            popup: propsPopup,
            dataTest,
            basedOnComponent = "dropdown_base",
            wrapperClassName,
            triggerClassName,
            ...props
        },
        ref
    ) => {
        const [open, setOpen] = useState(false)

        useImperativeHandle(
            ref,
            () => ({
                open,
            }),
            [open]
        )

        const Trigger = children?.Trigger || propsTrigger
        const Popup = children?.Popup || propsPopup

        const DropdownComponent = basedOnComponent === "dropdown_base" ? DropdownBase : Dropdown

        return (
            <div className={classNames(styles.Wrapper, wrapperClassName)} style={wrapperStyle}>
                <DropdownComponent
                    //@ts-ignore
                    on={"hover"}
                    open={open}
                    setOpen={setOpen}
                    trigger={
                        <div className={triggerClassName} data-test={dataTest}>
                            {infoTrigger ? (
                                <InfoTrigger
                                    hintText={infoTrigger === true ? "" : infoTrigger.hintText}
                                    popupOpen={open}
                                    dark={infoTrigger === true ? undefined : infoTrigger.dark}
                                />
                            ) : (
                                Trigger
                            )}
                        </div>
                    }
                    contentStyle={{
                        boxShadow: "0 2px 12px var(--light-blue-shadow)",
                        borderRadius: "0.375rem",
                    }}
                    {...props}
                >
                    <div className={classNames(styles.Popup, popupClassName)}>{Popup}</div>
                </DropdownComponent>
            </div>
        )
    }
)

export const HoverBox: React.FC<TooltipProps> = ({children}) => {
    const [popupVisible, setPopupVisible] = useState(false)

    return (
        <div className={styles.Wrapper} onMouseLeave={() => setPopupVisible(false)}>
            <div className={styles.Trigger} onMouseEnter={() => setPopupVisible(true)}>
                {children?.Trigger}
            </div>
            {popupVisible && <div className={styles.Popup}>{children?.Popup}</div>}
        </div>
    )
}
