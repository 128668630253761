export const BUGSNAG_API_KEY = "50ff67ad7a42645173d71622991177bf"
export const BUGSNAG_APP_VERSION = "1.39"

export enum Environment {
    Stable = "stable",
    Stage = "stage",
    Prod = "prod",
}

export const environmentUrls: {[env in Environment]: string} = {
    [Environment.Stable]: "stable.thescholasticnetwork.com",
    [Environment.Stage]: "stage.thescholasticnetwork.com",
    [Environment.Prod]: "portal.thescholasticnetwork.com",
}

export const CONTENT_MAX_SIZE = {
    image: 30 * 1024 * 1024,
    media: 500 * 1024 * 1024,
    announcement: 2 * 1024 * 1024 * 1024,
    feed: 10 * 1024 * 1024,
}

export const CONTENT_MAX_FILES = {
    premiumLibrary: 25,
    common: 10,
}
