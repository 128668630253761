import React, {memo, ReactElement, ReactNode, useEffect, useState} from "react"
import LoaderImage from "../../graphics/Loader.svg"
import MultiColor from "./multi_color.png"
import styles from "./Loader.module.scss"
import classNames from "classnames"
import {APIStatus} from "../../types/APITypes"
import {ScholasticLoaderIcon} from "../ScholasticLoader/ScholasticLoader"

interface LoaderProps {
    text?: ReactNode | string
    small?: boolean
    suspended?: boolean
    multiColor?: boolean
    delay?: number
    inline?: boolean
    "data-cy"?: string
    size?: number
    className?: string
}

const defaultDelay = 500

export const Loader: React.FC<LoaderProps> = ({
    text,
    small,
    multiColor,
    delay = defaultDelay,
    suspended,
    inline,
    "data-cy": dataCy = "loader",
    size,
    className,
}) => {
    const [displayLoader, setDisplayLoader] = useState(false)
    const [id] = useState(() => Math.floor(Math.random() * 1_000_000_000))

    useEffect(() => {
        const timeoutId = setTimeout(
            () => {
                setDisplayLoader(true)
            },
            suspended ? delay : 0
        )
        return () => {
            clearTimeout(timeoutId)
        }
    }, [suspended])

    if (!displayLoader) {
        return null
    }

    return (
        <div
            className={classNames(styles["loader-component"], {
                [styles["-small"]]: small,
                [styles.inline]: inline,
            })}
            data-cy={dataCy}
        >
            {multiColor || small ? (
                <img
                    alt="Loading..."
                    src={multiColor ? MultiColor : LoaderImage}
                    className={classNames(className, {[styles["-animate"]]: multiColor})}
                    style={size ? {height: size + "rem", width: size + "rem"} : undefined}
                />
            ) : (
                <ScholasticLoaderIcon id={id} />
            )}
            {text && <span className={styles["text"]}>{text}</span>}
        </div>
    )
}

interface LoaderSuspendHideProps {
    status: APIStatus
    delayHide?: number
    children: ReactElement
}

export const LoaderSuspendHide: React.FC<LoaderSuspendHideProps> = memo(
    ({status, delayHide, children}) => {
        const [showLoader, setShowLoader] = useState(false)

        useEffect(() => {
            if (status === APIStatus.Loading) setShowLoader(true)
            if (status === APIStatus.Success)
                setTimeout(() => setShowLoader(false), delayHide || 500)
        }, [status, delayHide])

        return showLoader ? <Loader /> : children
    }
)
