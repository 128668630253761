import React, {ReactNode, useEffect, Suspense} from "react"
import {APIStatus} from "../../types/APITypes"
import {ContainerLayout} from "../../ui/ContainerLayout/ContainerLayout"
import ScholasticLoader from "../../ui/ScholasticLoader/ScholasticLoader"
import {reactLazyRetry} from "../../utilities/retryImport"

const ReloadMsg = reactLazyRetry(() => import("./ReloadMsg"))

interface AuthWrapProps {
    status: APIStatus
    invalid?: boolean
    ignoreAuthStatus?: boolean
}

//TODO add another wrapper which will be responsible for global event handlers. WindowEventsHelper?
export const AuthWrap: React.FC<{children?: ReactNode} & AuthWrapProps> = ({
    status,
    invalid,
    children,
    ignoreAuthStatus,
}) => {
    useEffect(() => {
        const handler = () => {
            document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`)
        }
        handler()
        window.addEventListener("resize", handler)
        document.documentElement.style.setProperty("--scroll_padding", "0px")

        window.addEventListener(
            "dragover",
            function (e) {
                e.preventDefault()
            },
            false
        )
        window.addEventListener(
            "drop",
            function (e) {
                e.preventDefault()
            },
            false
        )

        return () => {
            window.removeEventListener("resize", handler)
        }
    }, [])

    const bundleReady = window.scholasticNamespace?.bundleReady
    if (bundleReady === false) return null

    return !ignoreAuthStatus && (status == APIStatus.Initial || status == APIStatus.Loading) ? (
        <ContainerLayout>
            <div style={{marginTop: "8.875rem"}}>
                <ScholasticLoader />
            </div>
        </ContainerLayout>
    ) : (
        <>
            {children}
            {invalid && (
                <Suspense fallback={null}>
                    <ReloadMsg />
                </Suspense>
            )}
        </>
    )
}
