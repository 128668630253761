export enum ReferrerSearchParams {
    Title = "referrer_title",
    URL = "referrer_url",
}

export const getLoginRedirectPath = () => {
    if (window.location.pathname.length > 1) {
        return `/auth/login?${ReferrerSearchParams.URL}=${encodeURIComponent(
            window.location.pathname + window.location.search
        )}`
    }
    return `/auth`
}
