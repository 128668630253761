import {IMobileContext} from "../../../../../../../../scholastic-client-components/src/context/mobileContext"
import {useAdaptiveValues} from "../../../../../../../../scholastic-client-components/src/hooks/adaptive/useAdaptiveValues"
import {adaptiveHelper} from "../../../../../../../../scholastic-client-components/src/utilities/adaptiveHelper"

export const useAdaptiveLimitedWidth = (mobileContext: IMobileContext): string => {
    const MAX_LIMITED_WIDTH = 31.25
    const [adaptiveLimitedWidth] = useAdaptiveValues({
        startSize: MAX_LIMITED_WIDTH,
        minSize: 10.5,
        ...mobileContext,
    })
    return adaptiveHelper(mobileContext, MAX_LIMITED_WIDTH, undefined, adaptiveLimitedWidth) + "rem"
}
