import React, {useLayoutEffect} from "react"
import browserUpdate from "browser-update"
import {useSelector} from "react-redux"
import {authSelectors} from "../../slices/authSlice"
import {useTranslation} from "react-i18next"
import "./browser-update.scss"

export const BrowserUpdate: React.FC = () => {
    const {t} = useTranslation(["common"])
    const locale = useSelector(authSelectors.getLocale)
    const localeId = locale?.id

    useLayoutEffect(() => {
        // http://localhost:3000/screens/#test-bu
        browserUpdate({
            l: localeId || "en",
            text: {
                msg: t("common:ui.browser_update.msg"),
                msgmore: t("common:ui.browser_update.msgmore"),
                bupdate: t("common:ui.browser_update.bupdate"),
                bignore: t("common:ui.browser_update.bignore"),
            },
            required: {f: 90, c: 92, o: 78},
        })
    }, [localeId, t])

    return null
}
