import React, {useEffect, useState} from "react"
import {Notification, NotificationProps} from "./Notification/Notification"

interface RemovableNotificationProps extends NotificationProps {
    id: string
    onRemove: (payload: {id: string}) => void
}

export const RemovableNotification: React.FC<RemovableNotificationProps> = (props) => {
    const {id, onRemove} = props

    const [willRemove, setWillRemove] = useState(true)
    let removeTimeout: NodeJS.Timeout

    const cancelAutoRemove = () => {
        if (!willRemove) return

        setWillRemove(false)
        clearTimeout(removeTimeout)
    }

    useEffect(() => {
        if (willRemove) {
            removeTimeout = setTimeout(() => {
                onRemove({id})
            }, 5000)
        }

        return () => {
            clearTimeout(removeTimeout)
        }
    }, [id, willRemove])

    return <Notification onMouseEnter={cancelAutoRemove} {...props} />
}
