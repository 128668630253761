import {GenericAPIRequest, getCallAPI} from "../lib/axios"
import {GreetingsModalType, GuideLinks} from "../slices/userGuideSlice"

const fetchGuideLinks =
    <RootState>(): GenericAPIRequest<RootState, {page: string; articleId?: number}, GuideLinks> =>
    (args) => {
        return getCallAPI<RootState>()({
            url: `/api/guide`,
            ...args,
        })
    }

export interface GreetingsResponse {
    showGreetingsModal: boolean
    isFirstVisitThisPortal: boolean
    instructionGuideExists: boolean
    greetingModalType: GreetingsModalType
}

const fetchGreetings =
    <RootState>(): GenericAPIRequest<RootState, null, GreetingsResponse> =>
    (args) => {
        return getCallAPI<RootState>()({
            url: `/api/guide/greetings`,
            ...args,
        })
    }

//api/guide/viewGettingStarted
const registerGettingStartedCourseRedirect =
    <RootState>(): GenericAPIRequest<RootState, null, null> =>
    (args) => {
        return getCallAPI<RootState>()({
            url: `/api/guide/viewGettingStarted`,
            ...args,
        })
    }

const notConverted =
    <RootState>(): GenericAPIRequest<RootState, {items: Array<string>}, Array<string>> =>
    (args) => {
        return getCallAPI<RootState>()({
            url: `/api/wiki/notConverted`,
            ...args,
        })
    }

export const guideAPI = <RootState>() => ({
    fetchGuideLinks: fetchGuideLinks<RootState>(),
    fetchGreetings: fetchGreetings<RootState>(),
    notConverted: notConverted<RootState>(),
    registerGettingStartedCourseRedirect: registerGettingStartedCourseRedirect<RootState>(),
})
