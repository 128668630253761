import React, {ReactNode} from "react"
import {Box} from "scholastic-client-components/src/ui/Box/Box"
import {Text} from "scholastic-client-components/src/ui/Text/Text"
import styles from "./Boxes.module.scss"
import {SectionLocationIcon} from "scholastic-client-components/src/ui/SectionLocationIcon/SectionLocationIcon"
import classNames from "classnames"

interface IBlackBox {
    padSide: number
    ml?: number
    children?: ReactNode
    darkBackground?: boolean
}

export const BlackBox: React.FC<IBlackBox> = ({children, padSide, ml, darkBackground}) => {
    return (
        <Box
            flex={{alignItems: "center"}}
            className={classNames(styles.blackBox, {
                [styles["-dark"]]: darkBackground,
            })}
            padContainer={`0 ${padSide}rem`}
            ml={ml}
        >
            {children}
        </Box>
    )
}

interface IEducateBox {
    text: string
    limitedWidth?: string
    location?: boolean
    building?: boolean
    smallText?: boolean
    noWrap?: boolean
    limitLines?: number
    iconPosition?: "top" | "center"
}

export const EducateBox: React.FC<IEducateBox> = ({
    text,
    limitedWidth,
    location,
    smallText,
    noWrap = false,
    limitLines,
    iconPosition = "center",
}) => {
    return (
        <Box flex={{alignItems: iconPosition}} className={styles.educateBox}>
            <SectionLocationIcon type={location ? "location" : "building"} />
            <Text
                noWrap={noWrap}
                limitedWidth={limitedWidth}
                style={{paddingLeft: "0.625rem"}}
                small={smallText}
                limitLines={limitLines}
            >
                {text}
            </Text>
        </Box>
    )
}
