interface LocationData {
    id: number | string
    name: string
}

export const ALL_DISTRICTS_SELECT_ITEM: LocationData = {
    id: "ALL",
    name: "Global",
}

export const ALL_BUSINESSES_SELECT_ITEM: LocationData = {
    id: "ALL",
    name: "Global",
}

export const ALL_INSTITUTIONS_SELECT_ITEM: LocationData = {
    id: "ALL",
    name: "Global",
}
