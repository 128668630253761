import {combineReducers} from "redux"
import {reducer as formReducer} from "redux-form"
import {profileSlice} from "../slices/profileSlice"
import {authSlice} from "../../../scholastic-client-components/src/slices/authSlice"
import {guideTourSlice} from "../../../scholastic-client-components/src/slices/guideTourSlice"
import {headerAlertsSlice} from "../../../scholastic-client-components/src/slices/headerAlertsSlice"
import {headerNotificationsSlice} from "../../../scholastic-client-components/src/slices/headerNotificationsSlice"
import {notificationListSlice} from "../../../scholastic-client-components/src/slices/notificationListSlice"
import {uploadFilesSlice} from "../../../scholastic-client-components/src/slices/uploadFilesSlice"
import {userGuideSlice} from "../../../scholastic-client-components/src/slices/userGuideSlice"
import {userSupportRequestsSlice} from "../../../scholastic-client-components/src/slices/userSupportRequestsSlice"
import {homeSlice} from "../slices/homeSlice"

export const rootReducer = combineReducers({
    form: formReducer,

    auth: authSlice.reducer,
    notificationList: notificationListSlice.reducer,
    headerAlerts: headerAlertsSlice.reducer,
    headerNotifications: headerNotificationsSlice.reducer,
    uploadFiles: uploadFilesSlice.reducer,
    profile: profileSlice.reducer,
    userGuide: userGuideSlice.reducer,
    userSupportRequests: userSupportRequestsSlice.reducer,
    guideTour: guideTourSlice.reducer,
    home: homeSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>
