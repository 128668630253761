import {IMobileContext} from "../context/mobileContext"

export const adaptiveHelper = <T = undefined, S = undefined, G = undefined>(
    isMobile?: boolean | IMobileContext,
    defaultValue?: T,
    mobileValue?: S,
    adaptiveValue?: G
): T | S | G | undefined => {
    const mobile = typeof isMobile === "boolean" ? isMobile : isMobile?.isMobile
    if (mobile) {
        if (adaptiveValue !== undefined) return adaptiveValue
        if (mobileValue !== undefined) return mobileValue
    }
    return defaultValue
}
