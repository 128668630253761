import {
    createImmutableStateInvariantMiddleware,
    createSerializableStateInvariantMiddleware,
    isImmutableDefault,
    isPlain,
    getDefaultMiddleware,
} from "@reduxjs/toolkit"

const LIMIT_CHECK_LENGTH = 50
const inspectValue = (value: any, defaultFn: (value: any) => boolean) => {
    if (value?.length && value.length > LIMIT_CHECK_LENGTH) return true
    if (value && typeof value === "object") {
        const keys = Object.keys(value)
        if (keys.length > LIMIT_CHECK_LENGTH) {
            if (typeof keys[42] === "number") return true // for structures like {[entity_id]: boolean}
        }
    }
    return defaultFn(value)
}

const getReduxMiddlewares = () => {
    if (process.env.NODE_ENV === "production") return []
    const immutableStateInvariantMiddleware = createImmutableStateInvariantMiddleware({
        isImmutable: (value) => inspectValue(value, isImmutableDefault),
    })

    const serializableStateInvariantMiddleware = createSerializableStateInvariantMiddleware({
        isSerializable: (value) => inspectValue(value, isPlain),
    })
    return [immutableStateInvariantMiddleware, serializableStateInvariantMiddleware]
}

export const getStoreWatchers = () => [
    ...getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
    }),
    ...getReduxMiddlewares(),
]
