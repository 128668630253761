import {useLayoutEffect, useState} from "react"
import {PortalsPanelItem} from "../../../../types/securityTypes"

export const useHeaderHeight = (currentPortal?: PortalsPanelItem): boolean => {
    const [tallerHeader, setTallerHeader] = useState(false)

    useLayoutEffect(() => {
        if (
            currentPortal === PortalsPanelItem.Home &&
            !window.location.pathname.includes("profile")
        ) {
            document.documentElement.style.setProperty("--header-height", "5.625rem")
            setTallerHeader(true)
        } else {
            document.documentElement.style.setProperty("--header-height", "3.75rem")
            setTallerHeader(false)
        }
    }, [currentPortal, window.location.pathname])

    return tallerHeader
}
