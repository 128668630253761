import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {APIStatus} from "../types/APITypes"
import {GuideTourI} from "../types/guideTourTypes"

export enum TourStatus {
    OPEN,
    CLOSE,
    WAIT,
}

interface SliceState {
    status: APIStatus
    data?: GuideTourI
    tourStatus: TourStatus
}

const initialState: SliceState = {
    status: APIStatus.Initial,
    tourStatus: TourStatus.CLOSE,
}

export const guideTourSlice = createSlice({
    name: "guideTourSlice",
    initialState,
    reducers: {
        startFetch(state: SliceState) {
            state.status = APIStatus.Loading
        },
        failFetch(state: SliceState) {
            state.status = APIStatus.Failure
        },
        successFetch(state: SliceState, action: PayloadAction<GuideTourI>) {
            state.data = action.payload
            state.status = APIStatus.Success
        },
        setTourStatus(state: SliceState, action: PayloadAction<TourStatus>) {
            state.tourStatus = action.payload
        },
        reset() {
            return initialState
        },
    },
})

interface Store {
    guideTour: SliceState
}

export const guideTourSelector = createSelector(
    (store: Store) => store.guideTour.status,
    (store: Store) => store.guideTour.data,
    (store: Store) => store.guideTour.tourStatus,
    (status, data, tourStatus) => ({status, data, tourStatus})
)
