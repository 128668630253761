import React, {useContext} from "react"
import {PortalSectionContextValue} from "./portalSectionTypes"
import {
    getBusinessSectionContextValue,
    getHigherEdSectionContextValue,
    getSchoolSectionContextValue,
} from "./portalSectionContextValues"

export const getPortalSectionContextValue: () => {[p: string]: PortalSectionContextValue} = () => ({
    school: getSchoolSectionContextValue(),
    business: getBusinessSectionContextValue(),
    higherEd: getHigherEdSectionContextValue(),
})

export const PortalSectionContext = React.createContext<PortalSectionContextValue>(
    getPortalSectionContextValue().school
)

export const usePortalSectionContext = () => useContext(PortalSectionContext)
