import {TRIGGER_OFFSET} from "../../../constants"

export const dropdownPositions = [
    "bottom left",
    "bottom right",
    "bottom center",
    "top left",
    "top right",
    "top center",
    "center center",
    "detached",
]

export type Align = "top" | "bottom" | "center"
export type Justify = "left" | "right" | "center"
export type Position = typeof dropdownPositions[number]

export const DEFAULT_COORDS = {
    top: 0,
    left: 0,
}

export interface BlockSizes {
    triggerWidth: number
    triggerHeight: number

    dropdownWidth: number
    dropdownHeight: number
}

export const getDropdownCoords = (
    position: Position,
    blockSizes: BlockSizes,
    offsetX = 0,
    offsetY = 0,
    rootRect: DOMRect,
    disablePosCorrection = false
) => {
    const ww = document.documentElement.clientWidth
    const wh = document.documentElement.clientHeight

    if (position === "detached") {
        return {
            top: wh / 2 - blockSizes.dropdownHeight / 2,
            left: ww / 2 - blockSizes.dropdownWidth / 2,
        }
    }

    const [align, justify] = position.split(" ") as [Align, Justify]

    const getTop = () => {
        switch (align) {
            case "top":
                return (
                    -(TRIGGER_OFFSET + blockSizes.dropdownHeight + blockSizes.triggerHeight) -
                    offsetY
                )
            case "center":
                return blockSizes.triggerHeight / 2 - blockSizes.dropdownHeight / 2 + offsetY
            case "bottom":
                return TRIGGER_OFFSET + offsetY
            default:
                return DEFAULT_COORDS.top - offsetY
        }
    }

    const getLeft = () => {
        switch (justify) {
            case "left":
                return offsetX
            case "center":
                return blockSizes.triggerWidth / 2 - blockSizes.dropdownWidth / 2 + offsetX
            case "right": {
                return blockSizes.triggerWidth - blockSizes.dropdownWidth + offsetX
            }
            default:
                return DEFAULT_COORDS.left + offsetX
        }
    }

    // Absolute position
    let left = getLeft() + rootRect.left
    // https://scholasticnetwork.atlassian.net/browse/TSN-9858
    if (disablePosCorrection && left + blockSizes.dropdownWidth > ww) {
        left = ww - blockSizes.dropdownWidth - 5
    }
    return {
        top: getTop() + rootRect.top,
        left,
    }
}
