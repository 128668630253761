import {
    PortalRoutes,
    PortalSection,
    PortalSectionRole,
    PortalSectionRoutes,
} from "../../context/portalSectionTypes"
import {AuthPerson} from "../../api/authAPI"

export abstract class DefaultPortalSection {
    private static readonly key = "defaultPortalSection"

    public static get value(): PortalSection | null {
        const item = localStorage.getItem(this.key)
        if (item) {
            return JSON.parse(item) as PortalSection
        }
        return null
    }

    public static set value(portalSection: PortalSection | null) {
        localStorage.setItem(this.key, JSON.stringify(portalSection))
    }

    public static get route(): PortalRoutes {
        const defaultPortalSection = this.value
        return PortalSectionRoutes[defaultPortalSection || PortalSection.School]
    }

    public static get role(): keyof AuthPerson["roleAuthorities"] {
        const defaultPortalSection = this.value
        return PortalSectionRole[defaultPortalSection || PortalSection.School]
    }

    public static getRedirectTo = (defaultPortalPath: {[p in PortalSection]?: string}): string => {
        const defaultPortalSection = this.value
        // Default path for the default section
        const defaultSectionPath = defaultPortalSection && defaultPortalPath[defaultPortalSection]
        if (defaultSectionPath) {
            return defaultSectionPath
        }
        // First available default path
        return Object.values(defaultPortalPath).filter(Boolean)[0] || "/403"
    }
}
