const localStorageKey = "lang"

export class LocalStorageLocale {
    static getValue = (): string | undefined => {
        const localStorageValue = localStorage.getItem(localStorageKey)

        return localStorageValue !== null ? localStorageValue : undefined
    }

    static setValue = (value: string): void => {
        localStorage.setItem(localStorageKey, value)
    }
}
