import React from "react"
import {
    Page404,
    Page404Props,
} from "scholastic-client-components/src/pages/ErrorPage/page404/Page404"

type Home404Props = Page404Props

export const Home404: React.FC<Home404Props> = (props) => {
    return <Page404 variant={"full_height"} {...props} />
}
