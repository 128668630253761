import {Middleware} from "@reduxjs/toolkit"
import {stopSubmit, touch} from "redux-form"

const actionType = "API_VALIDATION_ERROR"

interface FormErrorMiddleware {
    action: (p: any) => {type: string; payload: any}
    middleware: Middleware<
        {}, // Most middleware do not modify the dispatch return value
        any
    >
}

export const formErrorMiddleware: FormErrorMiddleware = {
    action: (payload) => ({
        type: actionType,
        payload,
    }),
    middleware:
        ({getState, dispatch}) =>
        (next) =>
        (action) => {
            next(action)

            if (action.type.includes(actionType)) {
                const errors = action.payload.errors
                if (errors) {
                    const formState = getState().form
                    let form
                    if (formState) {
                        const forms = Object.keys(formState)

                        const premiumEditorForm = forms.find(
                            (item) => item === "premium_repository_editor"
                        )
                        if (premiumEditorForm) {
                            form = premiumEditorForm
                        } else {
                            if (forms.length > 0) form = forms[0]
                            if (forms.length > 1) {
                                console.warn("Form state contains more than 1 form!")
                            }
                        }
                    }
                    if (!form) return console.warn("Form state does not contain any forms")

                    dispatch(stopSubmit(form, errors))
                    dispatch(touch(form, Object.keys(errors).join("\n")))
                }
            }
        },
}
