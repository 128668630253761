import React, {FC, Suspense} from "react"
import {GuideTourProps} from "./interfaces"
import {reactLazyRetry} from "../../utilities/retryImport"

const LazyGuideTour = reactLazyRetry(() => import("./LazyGuideTour"))

export const GuideTour: FC<GuideTourProps> = (props) => {
    return (
        <Suspense fallback={null}>
            <LazyGuideTour {...props} />
        </Suspense>
    )
}
