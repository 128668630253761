import React, {FC} from "react"
import {HeaderPopupItemProps} from "../HeaderDropdownItem"
import styles from "./SupportDropdownItem.module.scss"
import classNames from "classnames"
import {Icon, NameIcon} from "../../../ui/Icon/Icon"

export interface SupportDropdownItemProps {
    id: number
    title?: React.ReactNode
    items: Array<HeaderPopupItemProps & {icon?: NameIcon}>
    mainLinks?: boolean
    seeAlsoLinks?: boolean
    handleClose?: () => void
    lastItem?: boolean
}

export const SupportDropdownItem: FC<SupportDropdownItemProps> = ({
    title,
    items,
    seeAlsoLinks,
    mainLinks,
    handleClose,
    lastItem,
}) => (
    <div
        className={classNames(styles.supportItem, {
            [styles.mainLinksContainer]: mainLinks,
            [styles.seeAlsoLinksContainer]: seeAlsoLinks,
            [styles.lastItem]: lastItem,
        })}
    >
        {(seeAlsoLinks || mainLinks) && (
            <div
                className={classNames(styles.supportItemTitle, {
                    [styles.mainLinks]: mainLinks,
                    [styles.seeAlsoLinks]: seeAlsoLinks,
                    [styles.withoutItems]: mainLinks && !items.length,
                })}
            >
                {seeAlsoLinks ? "See also:" : title}
            </div>
        )}
        <div className={styles.links}>
            {items.map(({to, text, icon, onClick}) => (
                <>
                    <div
                        key={to}
                        className={classNames(styles.link, {
                            [styles.defaultItems]: !seeAlsoLinks && !mainLinks,
                        })}
                    >
                        <a
                            rel={"noreferrer"}
                            target={"_blank"}
                            href={to}
                            onClick={() => {
                                if (onClick && handleClose) {
                                    onClick()
                                    handleClose()
                                }
                            }}
                        >
                            {text}
                        </a>
                        {icon && <Icon name={icon} />}
                    </div>
                </>
            ))}
        </div>
    </div>
)
