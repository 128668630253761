import {useAppSelector} from "./useAppSelector"
import {authSelectors} from "scholastic-client-components/src/slices/authSlice"
import {AppPaths} from "../lib/routing"

export const useAvailablePortalSectionsPaths = () => {
    const personRoleAuthorities = useAppSelector(authSelectors.getPersonRoleAuthorities)

    return {
        school: {
            home: personRoleAuthorities?.school?.length ? AppPaths.Home : undefined,
        },
        higherEd: {
            home: personRoleAuthorities?.higherEd?.length ? AppPaths.Home : undefined,
        },
        business: {
            home: personRoleAuthorities?.business?.length ? AppPaths.Home : undefined,
        },
    }
}
