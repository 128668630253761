import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {HeaderAlert} from "../features/SocketWrapper/Alerts/useAlertListener"
import {APIStatus} from "../types/APITypes"
import {PortalSection} from "../context/portalSectionTypes"

interface SliceState {
    alerts: Array<HeaderAlert>
    portalSection?: PortalSection
    initStatus: APIStatus
}

const initialState: SliceState = {
    alerts: [],
    initStatus: APIStatus.Initial,
}

export const headerAlertsSlice = createSlice({
    name: "headerAlerts",
    initialState,
    reducers: {
        setInitStatus(state: SliceState, action: PayloadAction<APIStatus>) {
            state.initStatus = action.payload
        },
        setInitialAlerts(state: SliceState, action: PayloadAction<Array<HeaderAlert>>) {
            state.alerts = action.payload
            state.initStatus = APIStatus.Success
        },
        setAlerts(state: SliceState, action: PayloadAction<Array<HeaderAlert>>) {
            state.alerts = action.payload
        },
        removeAlerts(state: SliceState, action: PayloadAction<Array<number>>) {
            state.alerts = state.alerts.filter((item) => !action.payload.includes(item.id))
        },
        setPortalSection(state: SliceState, action: PayloadAction<PortalSection>) {
            state.portalSection = action.payload
        },
        reset() {
            return initialState
        },
    },
})

interface Store {
    headerAlerts: SliceState
}

export const getHeaderAlerts = (store: Store) => store.headerAlerts.alerts
export const getHeaderAlertInitStatus = (store: Store) => store.headerAlerts.initStatus
export const getHeaderAlertPortalSection = (store: Store) => store.headerAlerts.portalSection
