import React, {forwardRef, ReactNode, useState} from "react"
import classNames from "classnames"
import styles from "../HeaderDropdown/HeaderDropdown.module.scss"

export interface DropdownIconTriggerProps {
    open: boolean
    className?: string
    onClick?: () => void
    getIcon: (active: boolean, hover: boolean) => ReactNode
    hideYellowBorder?: boolean
    fullHeightDropdown?: boolean
}

export const DropdownIconTrigger = forwardRef<HTMLButtonElement, DropdownIconTriggerProps>(
    ({className, getIcon, open, hideYellowBorder, fullHeightDropdown, ...triggerProps}, ref) => {
        const [hover, setHover] = useState(false)

        return (
            <button
                ref={ref}
                className={classNames(className, styles.dropdown, {
                    [styles["-open"]]: open,
                    [styles["-fullHeightDropdown"]]: fullHeightDropdown,
                    [styles.hideYellowBorder]: hideYellowBorder,
                })}
                {...triggerProps}
            >
                <div
                    className={classNames(styles.icon, styles.content)}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    {getIcon(open, hover)}
                </div>
            </button>
        )
    }
)
