import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {APIStatus} from "../types/APITypes"

export interface FileData {
    id: string
    file: string
    thumbnail: string
    src: string
    name: string
    type: string
    token?: string
}

interface SliceState {
    token?: string
    items: Array<FileData>
    tempItems: Array<FileData>

    uploadAPIStatus: APIStatus
}

const initialState = {
    items: [],
    tempItems: [],

    uploadAPIStatus: APIStatus.Initial,
} as SliceState

export const uploadFilesSlice = createSlice({
    name: "uploadFiles",
    initialState,
    reducers: {
        successUpload(state: SliceState, action: PayloadAction<{file: FileData; total: number}>) {
            const {file, total} = action.payload
            const last = state.tempItems.length + 1 === total

            state.token = file.token

            if (last) {
                state.uploadAPIStatus = APIStatus.Success
            }

            if (file) {
                state.items = last
                    ? [
                          ...state.items,
                          ...(state.tempItems.length ? [...state.tempItems, file] : [file]),
                      ]
                    : state.items
                state.tempItems = last ? [] : [...state.tempItems, file]
            }
        },

        errorUpload(state: SliceState) {
            state.uploadAPIStatus = APIStatus.Failure
        },

        startUpload(state: SliceState, action: PayloadAction<{first: boolean}>) {
            if (action.payload.first) {
                state.uploadAPIStatus = APIStatus.Loading
            }
        },

        reset() {
            return initialState
        },

        removeContentFile(state: SliceState, action: PayloadAction<{id: string}>) {
            state.items = state.items.filter((item) => item.id !== action.payload.id)
        },

        renameContentFile(state: SliceState, action: PayloadAction<{id: string; name: string}>) {
            const {id, name} = action.payload

            state.items = state.items.map((item) => (item.id === id ? {...item, name} : item))
        },
    },
})

export interface UploadFilesStore {
    uploadFiles: SliceState
}

export const uploadFilesSelectors = {
    getContentFiles: (store: UploadFilesStore) => store.uploadFiles.items,
    getUploadAPIStatus: (store: UploadFilesStore) => store.uploadFiles.uploadAPIStatus,
}
