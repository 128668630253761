import {ReactNode} from "react"
import * as React from "react"
import styles from "./ContainerLayout.module.scss"
import classNames from "classnames"
import {AdaptiveValue, useAdaptiveValues} from "../../hooks/adaptive/useAdaptiveValues"
import {REM_MOBILE_BREAKPOINT} from "../../features/MobileContextComponent/constants"
import {adaptiveHelper} from "../../utilities/adaptiveHelper"

interface AdaptiveProps {
    // adaptiveMode prop is required for rest adaptive props
    adaptiveMode?: boolean

    mobilePs?: number
    mobilePt?: number

    adaptivePs?: AdaptiveValue
    adaptivePt?: AdaptiveValue
    remBreakPoint?: number
}

interface ContainerLayoutProps extends AdaptiveProps {
    className?: string
    divRef?: React.Ref<HTMLDivElement>
    noPadding?: boolean
    noBottomPadding?: boolean
    withScroll?: boolean
    children?: ReactNode
}

export const ContainerLayout: React.FC<ContainerLayoutProps> = ({
    className,
    divRef,
    noPadding,
    noBottomPadding,
    withScroll = true,
    adaptiveMode,
    remBreakPoint,
    mobilePs,
    mobilePt,
    adaptivePs,
    adaptivePt,
    ...props
}) => {
    const [adaptPs, adaptPt] = useAdaptiveValues({
        values: [adaptivePs, adaptivePt],
        remBreakPoint: remBreakPoint || REM_MOBILE_BREAKPOINT,
        isMobile: !!adaptiveMode,
    })

    const paddings = {
        top: adaptiveHelper(adaptiveMode, undefined, mobilePt, adaptPt) + "rem",
        side: adaptiveHelper(adaptiveMode, undefined, mobilePs, adaptPs) + "rem",
    }

    return (
        <div
            ref={divRef}
            className={classNames(
                styles["container-layout"],
                {
                    [styles.noPadding]: noPadding,
                    [styles.withScroll]: withScroll,
                },
                className
            )}
            style={{
                paddingTop: paddings.top,
                paddingRight: paddings.side,
                paddingLeft: paddings.side,
            }}
            {...props}
        >
            {props.children}
            {!noBottomPadding && <div className={styles.bottomPaddingBlock} />}
        </div>
    )
}
