import React from "react"
import {WrappedFieldProps} from "redux-form"
import {WrappedFieldInputProps} from "redux-form/lib/Field"
import {FilterSchoolView} from "../../types/view/school/FilterSchoolView"
import {ALL_BUILDINGS_SELECT_ITEM, ALL_SCHOOLS_SELECT_ITEM} from "../../utilities/extraSelectValues"
import {SelectComponent} from "../../ui/SelectComponent/SelectComponentLazy"
import {useTranslation} from "react-i18next"

interface SchoolInputProps extends WrappedFieldInputProps {
    value: FilterSchoolView
}

export interface InputSchoolSelectProps extends WrappedFieldProps {
    withAllSchools?: boolean
    input: SchoolInputProps
    disabled?: boolean

    options: Array<FilterSchoolView>
    hideDescription?: boolean
    "data-cy"?: string
}

export const FormSchoolSelect: React.FC<InputSchoolSelectProps> = (props) => {
    const {
        input: {value, ...input},
        meta: {error, touched},
        withAllSchools,
        options,
        hideDescription,
        disabled,
        "data-cy": dataCy,
    } = props

    const {t} = useTranslation(["glossary"])
    const schools = withAllSchools ? [ALL_SCHOOLS_SELECT_ITEM, ...options] : options
    const school = withAllSchools && !value ? ALL_SCHOOLS_SELECT_ITEM : value

    const description = !hideDescription && school.fullAddress

    return (
        <SelectComponent
            {...input}
            value={school}
            title={t("glossary:building.k12.capitalized_singular")}
            description={description || ""}
            placeholder={t("glossary:building.k12.select_placeholder")}
            options={schools}
            error={touched ? error : undefined}
            onChange={(value) => input.onChange(value)}
            onBlur={() => input.onBlur(school)}
            compactSelector
            disabled={disabled}
            data-cy={dataCy}
        />
    )
}

export const FormBuildingSelect: React.FC<InputSchoolSelectProps> = (props) => {
    const {
        input: {value, ...input},
        meta: {error, touched},
        withAllSchools,
        options,
        hideDescription,
        disabled,
        "data-cy": dataCy,
    } = props

    const {t} = useTranslation(["glossary"])
    const buildings = withAllSchools ? [ALL_BUILDINGS_SELECT_ITEM, ...options] : options
    const building = withAllSchools && !value ? ALL_BUILDINGS_SELECT_ITEM : value

    const description = !hideDescription && building.fullAddress

    return (
        <SelectComponent
            {...input}
            value={building}
            title={t("glossary:building.higher_ed.capitalized_singular")}
            description={description || ""}
            placeholder={t("glossary:building.higher_ed.select_placeholder")}
            options={buildings}
            error={touched ? error : undefined}
            onChange={(value) => input.onChange(value)}
            onBlur={() => input.onBlur(building)}
            compactSelector
            disabled={disabled}
            data-cy={dataCy}
        />
    )
}
