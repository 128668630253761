import React, {FC, ReactNode} from "react"
import styles from "./BottomRightCornerBox.module.scss"
import {BodyPortal} from "../BodyPortal"

interface BaseNotificationsWrapperProps {
    notificationNode?: ReactNode
    guideNode?: ReactNode
    otherNodes?: Array<ReactNode>
}

export const BottomRightCornerBox: FC<BaseNotificationsWrapperProps> = ({
    guideNode,
    notificationNode,
    otherNodes,
}) => {
    return (
        <BodyPortal>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    {otherNodes?.length &&
                        otherNodes.map((node, index) => <div key={index}>{node}</div>)}
                    {notificationNode ? <div>{notificationNode}</div> : null}
                    {guideNode ? <div>{guideNode}</div> : null}
                </div>
            </div>
        </BodyPortal>
    )
}
