import React from "react"
import styles from "./Icon.module.scss"
import Sprite from "../../graphics/sprite/sprite.svg"
import classNames from "classnames"
import CSS from "csstype"

export const Icon: React.FC<{
    name: NameIcon
    className?: string
    style?: CSS.Properties
    "data-cy"?: string
    onClick?: () => void
}> = ({name, className, style, onClick, "data-cy": dataCy = name}) => (
    <svg
        className={classNames(styles.svg, styles[name], className)}
        style={style}
        viewBox={viewBox[name]}
        onClick={onClick}
        data-cy={dataCy}
    >
        <use xlinkHref={Sprite + "#" + name} />
    </svg>
)

export type NameIcon =
    | "arrow"
    | "notify"
    | "doubleArrow"
    | "search"
    | "questionMark"
    | "droplist2"
    | "logout"
    | "certificate"
    | "copyEntity"
    | "activeBuild"
    | "details"
    | "notification"
    | "disabledGroup"
    | "history"
    | "invites"
    | "entityIndicator"
    | "inactiveBuild"
    | "activate"
    | "themeRepository"
    | "contentRepository"
    | "share"
    | "intervalChanger"
    | "calendar"
    | "openEye"
    | "viewsCount"
    | "hiddenEye"
    | "documentation"
    | "closedEye"
    | "noProducts"
    | "mainGroup"
    | "sort"
    | "sortAsc"
    | "unlimited"
    | "sortDesc"
    | "tagFolder"
    | "district"
    | "school"
    | "list"
    | "user"
    | "question"
    | "userHeader"
    | "statistics"
    | "alert"
    | "schools"
    | "screens"
    | "screensNotAssign"
    | "offShedule"
    | "devices"
    | "delete"
    | "dragndrop"
    | "dnd"
    | "reset"
    | "droplist"
    | "select-droplist"
    | "SCHOLASTIC"
    | "MENU"
    | "USERS"
    | "GROUPS"
    | "ADMIN"
    | "SCREENS"
    | "LMS"
    | "ADVERTISEMENT"
    | "CONTENT"
    | "REPOSITORY"
    | "REPOSITORIES"
    | "THEMES"
    | "STUDENT"
    | "STUDENTS"
    | "MESSAGES"
    | "NEWS"
    | "ATHLETIC"
    | "COMMUNITY"
    | "districtAccess"
    | "loading-area"
    | "xls-loading-area"
    | "text-loading-area"
    | "vert-more-photos"
    | "hor-more-photos"
    | "more-photos"
    | "file"
    | "edit"
    | "update"
    | "preview"
    | "add"
    | "view"
    | "selectAll"
    | "detach"
    | "revoke"
    | "twitter"
    | "rss"
    | "portrait"
    | "textFeed"
    | "imageType"
    | "videoType"
    | "videoPlay"
    | "notConvertedVideo"
    | "imagePreview"
    | "clock"
    | "offline"
    | "offlineScreen"
    | "schedule"
    | "queue"
    | "tag"
    | "threeCircles"
    | "painting"
    | "viewCount"
    | "image"
    | "copy"
    | "upload"
    | "contentCopy"
    | "folder"
    | "star"
    | "projectLogo"
    | "message"
    | "contentCount"
    | "pendingCount"
    | "twitch"
    | "facebook"
    | "youtube"
    | "onOffIndicator"
    | "menuTemplate"
    | "crossLines"
    | "plus"
    | "calendarCopy"
    | "calendarPaste"
    | "calendarDelete"
    | "clockPeriod"
    | "export"
    | "alertContent"
    | "CREATIVE"
    | "screenWithPlus"
    | "setting"
    | "map"
    | "filterSetting"
    | "exit"
    | "news"
    | "group"
    | "rename"
    | "alertus"
    | "alertus_text"
    | "cap"
    | "copySign"
    | "searchDetailed"
    | "clockArrow"
    | "DEFAULT"
    | "filterSearchSettings"
    | "fileFeed"
    | "fileFeedCustom"
    | "warning"
    | "yellow-warning"
    | "user-warning"
    | "thin-close"
    | "sample"
    | "contentUsage"
    | "screenPlay"
    | "locked"
    | "unlocked"
    | "info"
    | "chat"
    | "bulk"
    | "screen_not_lms"
    | "repository_content"
    | "repository_theme"
    | "repository_sample"
    | "repository_brand"
    | "folder_plus_sign"
    | "content_plus_sign"
    | "mail_with_lines"
    | "TRIAL"
    | "help_button"
    | "hashtag"
    | "key"
    | "CLASSROOM"
    | "test_device"
    | "REPORTS"
    | "file-log"
    | "business"
    | "SETTINGS"
    | "transparentCopy"
    | "add_network"
    | "institution"
    | "WEB_RTC"
    | "content_sign"
    | "search_with_border"
    | "bonusly"
    | "disabledDetach"

const viewBox: {[key in NameIcon]?: string} = {
    openEye: "0 0 18 10",
    closedEye: "0 0 18 12",
    screens: "0 0 20 14",
    detach: "0 0 18 13",
    twitter: "0 0 48 43",
    rss: "0 0 45 45",
    textFeed: "0 0 57 42",
    videoType: "0 0 16 16",
    imageType: "0 0 16 16",
    videoPlay: "0 0 12 14",
    notConvertedVideo: "0 0 14 17",
    imagePreview: "0 0 16 16",
    view: "0 0 18 16",
    clock: "0 0 17 14",
    devices: "0 0 18 12",
    offline: "0 0 23 13",
    schedule: "0 0 14 14",
    queue: "0 0 12 13",
    dnd: "0 0 13 13",
    tag: "0 0 19 12",
    schools: "0 0 18 12",
    threeCircles: "0 0 12 10",
    painting: "0 0 17 12",
    viewCount: "0 0 18 13",
    folder: "0 0 17 12",
    star: "0 0 11 10",
    projectLogo: "0 0 57 50",
    contentCount: "0 0 17 12",
    pendingCount: "0 0 17 12",
    onOffIndicator: "0 0 13 14",
    crossLines: "0 0 13 13",
    MESSAGES: "0 0 50 32",
    alertContent: "0 0 10 16",
    CREATIVE: "0 0 15 14",
    map: "0 0 13 13",
    exit: "0 0 15 15",
    news: "0 0 23 23",
    ATHLETIC: "0 0 26 14",
    COMMUNITY: "0 0 40 30",
    group: "0 0 18 18",
    list: "0 0 18 18",
    THEMES: "0 0 26 16",
    MENU: "0 0 26 16",
    upload: "0 0 18 18",
    edit: "0 0 18 18",
    SCHOLASTIC: "0 0 26 16",
    alertus: "0 0 18 16",
    copySign: "0 0 15 15",
    searchDetailed: "0 0 19 17",
    clockArrow: "0 0 18 18",
    DEFAULT: "0 0 26 16",
    warning: "0 0 20 18",
    sample: "0 0 18 18",
    contentUsage: "0 0 9 8",
    screenPlay: "0 0 18 18",
    locked: "0 0 7 9",
    unlocked: "0 0 10 10",
    info: "0 0 18 18",
    chat: "0 0 24 16",
    bulk: "0 0 18 18",
    screen_not_lms: "0 0 18 18",
    REPOSITORIES: "0 0 26 16",
    folder_plus_sign: "0 0 19 12",
    ["thin-close"]: "0 0 16 16",
    mail_with_lines: "0 0 50 32",
    help_button: "0 0 16 14",
    unlimited: "0 0 16 14",
    hashtag: "0 0 32 32",
    key: "0 0 52 52",
    test_device: "0 0 18 18",
    REPORTS: "0 0 50 32",
    ["file-log"]: "0 0 18 18",
    business: "0 0 18 18",
    question: "0 0 16 16",
    SETTINGS: "0 0 42 26",
    transparentCopy: "0 0 15 15",
    ["add_network"]: "0 0 15 19",
    institution: "0 0 18 18",
    ["WEB_RTC"]: "0 0 16 16",
    disabledGroup: "0 0 18 18",
    ["content_sign"]: "0 0 18 12",
    ["search_with_border"]: "0 0 16 16",
    bonusly: "0 0 18 16",
    disabledDetach: "0 0 18 13",
}
