import React, {memo, useLayoutEffect} from "react"
import {usePortalSectionContext} from "../../context/portalSectionContext"
import {setAxiosPortalSectionHeader} from "../../lib/axios"
import {DefaultPortalSection} from "./DefaultPortalSection"

interface PortalSectionEffectorProps {}

export const PortalSectionEffector: React.FC<PortalSectionEffectorProps> = memo(() => {
    const {role, portalSection} = usePortalSectionContext()

    useLayoutEffect(() => {
        setAxiosPortalSectionHeader(role)
        DefaultPortalSection.value = portalSection
    }, [portalSection, role])

    return null
})
