import React, {
    forwardRef,
    memo,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from "react"
import classNames from "classnames"
import {ModalScrollViewContext} from "../../context/modalContexts"
import styles from "./Dropdown.module.scss"
import {
    DropdownPosition,
    DropdownPositionInterface,
} from "./components/DropdownPosition/DropdownPosition"
import {DEFAULT_Z_INDEX} from "./constants"
import {DropdownContent} from "./components/DropdownContent/DropdownContent"
import {v4 as uuid} from "uuid"
import {DropdownInterface, DropdownProps} from "./types"
import {useTriggerLayoutStyles} from "./useTriggerLayoutStyles"

export const Dropdown = memo(
    forwardRef<DropdownInterface, DropdownProps>(
        (
            {
                on,
                open,
                setOpen,
                trigger,
                zIndexPopup = DEFAULT_Z_INDEX,
                disabled,
                triggerClassName,
                containerClassName,
                ["data-test"]: dataTest,
                ...dropdownProps
            },
            ref
        ) => {
            const modalScrollView = useContext(ModalScrollViewContext)

            const [dropdownId] = useState(uuid().substring(8))
            const [localOpen, setLocalOpen] = useState(false)
            const [hovered, setHovered] = useState(false)

            const dropdownPositionRef = useRef<DropdownPositionInterface | null>(null)
            const triggerRef = useRef<HTMLDivElement | null>(null)
            const rootRef = useRef<HTMLDivElement | null>(null)
            const dropdownRef = useRef<HTMLDivElement | null>(null)

            const {triggerLayoutStyles} = useTriggerLayoutStyles(triggerRef.current, zIndexPopup)

            const openState = open !== undefined ? open : localOpen
            const setOpenState = setOpen || setLocalOpen
            const dropdownOpen = openState || (on === "hover" && hovered)

            const toggleTrigger = useCallback(
                (value?: boolean) => {
                    if (disabled) return

                    let defaultOnClick: (() => void) | undefined
                    React.Children.forEach(trigger, (element) => {
                        defaultOnClick = element.props?.onClick
                    })
                    if (defaultOnClick) defaultOnClick()
                    setOpenState(value ?? !openState)
                },
                [disabled, openState, setOpenState, trigger]
            )

            const handleMouseEnter = useCallback(() => {
                if (!disabled && on === "hover") {
                    setOpenState(true)
                }
            }, [disabled, on, setOpenState])

            const handleMouseLeave = useCallback(() => {
                if (!disabled && on === "hover") {
                    setOpenState(false)
                }
            }, [disabled, on, setOpenState])

            useImperativeHandle(ref, () => ({place: dropdownPositionRef.current?.place}), [
                // update after Position render
                dropdownOpen,
            ])

            useLayoutEffect(() => {
                // Close on modal scroll
                if (modalScrollView) {
                    modalScrollView.onscroll = () => {
                        toggleTrigger(false)
                    }
                }
            }, [modalScrollView, setOpenState, toggleTrigger])

            useEffect(() => {
                return () => {
                    if (modalScrollView) {
                        modalScrollView.onscroll = null
                    }
                }
            }, [])

            useEffect(() => {
                if (disabled) {
                    setOpenState(false)
                }
            }, [disabled, setOpenState])

            return (
                <div className={classNames(styles.container, containerClassName)}>
                    <div
                        ref={triggerRef}
                        data-dropdown-trigger={dropdownId}
                        data-test={dataTest}
                        className={classNames(styles.triggerWrapper, triggerClassName)}
                    >
                        {React.cloneElement(trigger, {
                            onClick: () => toggleTrigger(),
                            onMouseEnter: handleMouseEnter,
                            onMouseLeave: handleMouseLeave,
                            disabled,
                        })}
                    </div>
                    <div
                        className={classNames(styles.dropdownRoot)}
                        ref={rootRef}
                        style={{zIndex: zIndexPopup}}
                    >
                        <div className={styles.triggerLayout} style={triggerLayoutStyles}>
                            {dropdownOpen && (
                                <DropdownPosition
                                    open={dropdownOpen}
                                    setOpen={setOpenState}
                                    zIndexPopup={zIndexPopup}
                                    dropdownId={dropdownId}
                                    ref={dropdownPositionRef}
                                    dropdownRef={dropdownRef}
                                    triggerRef={triggerRef}
                                    rootRef={rootRef}
                                    {...dropdownProps}
                                >
                                    <div
                                        onMouseEnter={() => setHovered(true)}
                                        onMouseLeave={() => setHovered(false)}
                                    >
                                        <DropdownContent {...dropdownProps} />
                                    </div>
                                </DropdownPosition>
                            )}
                        </div>
                    </div>
                </div>
            )
        }
    )
)
