import React from "react"

export const UserIcon: React.FC<{active: boolean; hover: boolean; forcedColor?: string}> = ({
    active,
    hover,
    forcedColor,
}) => (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            stroke={forcedColor || (active ? "#FFFFFF" : hover ? "#CAE4F2" : "#96B7CA")}
            strokeWidth="2"
            d="M1.67373 19.8596L1.66663 19.8524V19.0333C1.66663 15.7825 4.32203 13.1 7.59996 13.1H14.4C17.6779 13.1 20.3333 15.7825 20.3333 19.0333V19.8667H1.68114C1.67897 19.8647 1.6765 19.8623 1.67373 19.8596ZM15 5C15 7.20914 13.2091 9 11 9C8.79086 9 7 7.20914 7 5C7 2.79086 8.79086 1 11 1C13.2091 1 15 2.79086 15 5Z"
        />
    </svg>
)
