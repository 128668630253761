import {PaginationData} from "../slices/types"
import {BaseLocationParams} from "./searchTypes"

export enum APIStatus {
    Initial = "Initial",
    Loading = "Loading",
    Success = "Success",
    Failure = "Failure",
    NotFound = "NotFound",
}

export interface RequestWithPagination {
    pageParams: PaginationData
}

export interface RequestWithSearchParams<T = Record<string, unknown>> {
    locationParams?: BaseLocationParams & T
    query?: string
}

export interface DeleteCommonRequest {
    items: Array<number>
}

export interface CommonPaginationResponse<T> {
    list: {
        items: Array<T>
        total: number
    }
    notConverted?: Array<number>
}
