//some users encounter bug with preloading chunks for unknown reason
export const HIGH_PRIORITY_CHUNKS = [] /*[
    //@ts-ignore
    () => import("moment/locale/en-gb"),
    () => import("../ui/SelectComponent/SelectComponent"),
]*/

export const OTHER_CHUNKS = [] /*[
    () => import("../features/ReduxForm/FormTimePicker/FormTimePicker"),
    () => import("../features/ReduxForm/FormAntDatePicker/FormAntDatePicker"),
]*/
