import React, {memo, ReactNode} from "react"
import styles from "./DropdownMenu.module.scss"
import classNames from "classnames"

interface DropdownTitleProps {
    children: ReactNode
}

export const DropdownTitle: React.FC<DropdownTitleProps> = memo(({children}) => {
    return (
        <span className={classNames(styles.dropdownTitle, styles.itemContentOffset)}>
            {children}
        </span>
    )
})
