import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource} from "axios"
import {GenericAppThunk} from "../store/store"
import {Portal} from "../types/securityTypes"
import {authSlice} from "../slices/authSlice"
import {notificationListSlice} from "../slices/notificationListSlice"
import {formErrorMiddleware} from "../middlewares/formErrorMiddleware"
import {History} from "history"
import {AuthPerson, LogoutRequest} from "../api/authAPI"
import {getLoginRedirectPath} from "./routing"
import {pick} from "lodash"
import {HIGH_PRIORITY_CHUNKS, OTHER_CHUNKS} from "./chunsToPreload"
import Bugsnag from "@bugsnag/js"
import {removeAuthStorage} from "../hooks/useAuthStorage"
import {unstable_batchedUpdates} from "react-dom"
import {i18n} from "../i18n/i18n"

interface ConfigureAxiosProps {
    store: {dispatch: any; getState: any}
    history: History
    portal: Portal

    logout?: (APIArgs?: {payload: LogoutRequest}) => void
    getPortalToggleData?: () => {selectedPortalType?: Portal}
}

let leftHighPriorityChunks = HIGH_PRIORITY_CHUNKS
let leftOtherChunks = OTHER_CHUNKS

let requestsInWorkCount = 0
const CANCELED_MSG = "CANCELED"
let OngoingRequestTokens: {[key: string]: CancelTokenSource[]} = {}
export const LastRequests: {items: Array<string>} = {items: []}

export const LastRequestResponse = {
    api: "/api",
    response: {} as any,
}

export const cancelRequest = (key: string) => {
    const tokens = OngoingRequestTokens[key]
    if (tokens?.length) {
        for (const token of tokens) token.cancel(CANCELED_MSG)
        delete OngoingRequestTokens[key]
    }
}

const ERROR_AS_EXPECTED_STATUS = 418

export const configureAxios = (props: ConfigureAxiosProps) => {
    OngoingRequestTokens = {}
    LastRequests.items = []
    requestsInWorkCount = 0
    leftHighPriorityChunks = HIGH_PRIORITY_CHUNKS
    leftOtherChunks = OTHER_CHUNKS

    const {store, history, portal, logout} = props
    const clearStorage = removeAuthStorage()

    axios.defaults.headers.common["Portal-Type"] = portal
    axios.defaults.headers.common["Portal-Origin"] = portal
    axios.defaults.baseURL = window.location.origin

    axios.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            if (error.message === CANCELED_MSG) return
            const authRequest = error.response.config.url.includes("api/auth")

            switch (error.response.status) {
                case 401:
                    if (authRequest) {
                        if (history.location.pathname.includes("resetPassword")) {
                            break
                        }

                        const loginRedirectPath = getLoginRedirectPath()
                        clearStorage()
                        window.location.replace(loginRedirectPath)
                    } else {
                        logout && logout()
                    }
                    break
                case 403:
                    if (authRequest) {
                        //TODO FIX
                        //history.push(`/no_access`)
                        return Promise.resolve(error.response)
                    } else {
                        history.push("/403")
                    }
                    break
                case 404:
                    if (authRequest) {
                        logout && logout()
                    }
                    break
                case 422:
                    if (error.response.data && Bugsnag.isStarted()) {
                        const {id, email, fullName} = store.getState().auth?.person || {}
                        Bugsnag.setUser(id?.toString(), email, fullName)
                        Bugsnag.notify(JSON.stringify(error.response.data))
                    }
                    store.dispatch(formErrorMiddleware.action(error.response.data))
                    break
                case 412:
                    clearStorage()
                    store.dispatch(authSlice.actions.invalidateAuth())
                    break
                case ERROR_AS_EXPECTED_STATUS:
                    break
                default:
                    store.dispatch(
                        notificationListSlice.actions.setNotification({
                            title: i18n.t`shared_components:notifications.server_error.title`,
                            message: i18n.t`shared_components:notifications.server_error.message`,
                            details: error.response.data && JSON.stringify(error.response.data),
                        })
                    )
                    break
            }

            return Promise.reject(error)
        }
    )
}

export const setAxiosTokenHeader = (authToken?: string, authETag?: string) => {
    if (authToken) {
        axios.defaults.headers.common["X-Auth-Token"] = authToken
    } else {
        delete axios.defaults.headers.common["X-Auth-Token"]
    }
    if (authETag) {
        axios.defaults.headers.common["If"] = `</api/auth> ([${authETag}])`
    } else {
        delete axios.defaults.headers.common["If"]
    }
}

export const axiosPortalSectionHeaderName = "Portal-Section"

export const setAxiosPortalSectionHeader = (portalSection: keyof AuthPerson["roleAuthorities"]) => {
    if (portalSection) {
        axios.defaults.headers.common[axiosPortalSectionHeaderName] = portalSection
    } else {
        delete axios.defaults.headers.common[axiosPortalSectionHeaderName]
    }
}

export interface CallAPIParams {
    url: string
    resourceId?: string
    canParallel?: boolean
    payload?: any
    onSuccess?: (response: any, reducerData?: any, headers?: any) => void
    includeHeaders?: string[]
    onError?: (errorResponse: AxiosError, reducerData?: any) => void
    onNotFound?: () => void
    reducerData?: any
    config?: AxiosRequestConfig
}

export type CallAPI<AppThunk> = (params: CallAPIParams) => AppThunk

let preloaderId = -1
const ChunkPreloader = () => {
    clearTimeout(preloaderId)
    if (leftHighPriorityChunks.length) {
        const importFirstChunk = leftHighPriorityChunks[0]
        // importFirstChunk()
        leftHighPriorityChunks = leftHighPriorityChunks.slice(1)
    }
    if (requestsInWorkCount === 0) {
        preloaderId = Number(
            setTimeout(() => {
                if (leftOtherChunks.length) {
                    const importFirstChunk = leftOtherChunks[0]
                    // importFirstChunk()
                    leftOtherChunks = leftOtherChunks.slice(1)
                    if (leftOtherChunks.length) ChunkPreloader()
                }
            }, 2500)
        )
    }
}

export const getCallAPI =
    <RootState>(): CallAPI<GenericAppThunk<RootState>> =>
    (props) =>
    async () => {
        const {
            url,
            payload,
            onSuccess,
            onError,
            onNotFound,
            reducerData,
            config: _config = {},
            includeHeaders,
            resourceId,
            canParallel,
        } = props
        clearTimeout(preloaderId)
        requestsInWorkCount++

        let src = _config.method === "GET" ? undefined : resourceId || url
        if (src?.startsWith("/")) src = src?.substr(1)

        if (src && LastRequests.items[LastRequests.items.length - 1] !== src)
            LastRequests.items.push(src)
        if (LastRequests.items.length > 30)
            LastRequests.items = LastRequests.items.slice(LastRequests.items.length - 30)

        if (src && !canParallel && OngoingRequestTokens[src]) {
            requestsInWorkCount--
            cancelRequest(src)
            //let previous request recognize it was canceled
            await new Promise((resolve) => setTimeout(resolve, 0))
        }

        const cancelTokenSource = axios.CancelToken.source()
        if (src) {
            if (!canParallel) OngoingRequestTokens[src] = [cancelTokenSource]
            else
                OngoingRequestTokens[src] = [
                    ...(OngoingRequestTokens[src] || []),
                    cancelTokenSource,
                ]
        }
        const config: AxiosRequestConfig = {..._config, cancelToken: cancelTokenSource.token}
        config.headers = {
            ...(config.headers || {}),
            common: {
                ...(config.headers?.common || {}),
                "Client-Window": `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`,
            },
        }

        try {
            const method = config?.method
            let response: AxiosResponse
            if (method && method.toLowerCase() === "get") {
                response = await axios.get(url, config)
            } else {
                response = await axios.post(url, payload, config)
            }

            //no token means request was canceled
            if (src && !OngoingRequestTokens[src]) return

            const headers = includeHeaders ? pick(response.headers, includeHeaders) : undefined
            if (onSuccess && response) {
                LastRequestResponse.api = url
                LastRequestResponse.response = response.data

                const handler = () => onSuccess(response.data, reducerData, headers)
                if (unstable_batchedUpdates) unstable_batchedUpdates(handler)
                else handler()
            }
            requestsInWorkCount--
            ChunkPreloader()
        } catch (err: any) {
            console.warn(err)
            if (err?.response?.status === 404 && onNotFound) {
                onNotFound()
            } else {
                onError && onError(err, reducerData)
            }
        } finally {
            if (src) {
                if (OngoingRequestTokens[src]?.length && OngoingRequestTokens[src].length < 2)
                    delete OngoingRequestTokens[src]
                else if (OngoingRequestTokens[src])
                    OngoingRequestTokens[src] = OngoingRequestTokens[src].filter(
                        (it) => it !== cancelTokenSource
                    )
            }
        }
    }

export interface APIRequestParams<Req, Res, ReducerData> {
    payload?: Req
    onSuccess?: (response: Res, reducerData: ReducerData) => void
    onError?: (errorResponse: any, reducerData: ReducerData) => void
    onNotFound?: () => void
    reducerData?: ReducerData
    config?: AxiosRequestConfig
}

export type GenericAPIRequest<RootState, Req = null, Res = null, ReducerData = null> = (
    params: APIRequestParams<Req, Res, ReducerData>
) => GenericAppThunk<RootState>
