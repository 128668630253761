import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {NEW_COMMENT_ID} from "../features/Chat/types"
import {
    AllActiveTicketsResponse,
    EditTicketResponse,
    ITicketEntity,
    MyRequestsResponse,
    TicketChatMessage,
    TicketData,
    TicketFile,
    TicketInfo,
    TicketViewResponse,
} from "../api/supportRequestAPI"
import {APIStatus} from "../types/APITypes"

interface SliceState {
    fetchStatus: APIStatus
    deleteStatus: APIStatus
    fetchInfoStatus: APIStatus
    fetchAllTicketsStatus: APIStatus

    count: {
        active: number
        resolved: number
    }
    allActiveTickets: Array<ITicketEntity>
    allResolvedTickets: Array<ITicketEntity>

    editCommentsStatuses: {
        [id: number]: APIStatus
    }

    commonData: TicketInfo
    comments: Array<TicketChatMessage>
    activeTicket?: TicketData
    resolvedTicket?: TicketViewResponse
}

const initialCommonData: TicketInfo = {
    hasActiveTicket: false,
    hasChangesInActiveTicket: false,
    hasChangesInResolvedTicket: false,
    hasResolvedRequest: false,
    hidePhone: false,
}

const initialState: SliceState = {
    commonData: initialCommonData,
    deleteStatus: APIStatus.Initial,
    fetchInfoStatus: APIStatus.Initial,
    fetchStatus: APIStatus.Initial,
    fetchAllTicketsStatus: APIStatus.Initial,

    comments: [],
    allActiveTickets: [],
    allResolvedTickets: [],
    editCommentsStatuses: {},
    count: {
        active: 0,
        resolved: 0,
    },
}

export const userSupportRequestsSlice = createSlice({
    name: "userSupportRequests",
    initialState,
    reducers: {
        setDeleteStatus(state: SliceState, action: PayloadAction<APIStatus>) {
            state.deleteStatus = action.payload
        },
        setInfoStatus(state: SliceState, action: PayloadAction<APIStatus>) {
            state.fetchInfoStatus = action.payload
        },
        setCommentStatus(
            state: SliceState,
            action: PayloadAction<{status: APIStatus; id: number}>
        ) {
            state.editCommentsStatuses = {
                ...state.editCommentsStatuses,
                [action.payload.id]: action.payload.status,
            }
        },
        setCommonData(state: SliceState, action: PayloadAction<TicketInfo>) {
            state.commonData = action.payload
        },
        setFetchStatus(state: SliceState, action: PayloadAction<APIStatus>) {
            state.fetchStatus = action.payload
        },
        setAllTicketsStatus(state: SliceState, action: PayloadAction<APIStatus>) {
            state.fetchAllTicketsStatus = action.payload
        },
        setActiveTicketData(state: SliceState, action: PayloadAction<EditTicketResponse>) {
            const messages = action.payload.chatMessages || []
            state.comments = messages
            state.editCommentsStatuses = [...messages.map(({id}) => id), NEW_COMMENT_ID].reduce(
                (obj, id) => (id ? {...obj, [id]: APIStatus.Initial} : obj),
                {}
            )
            state.activeTicket = {
                id: action.payload.id,
                subject: action.payload.subject,
                description: action.payload.description,
                phone: action.payload.phone,
                files: action.payload.files || [],
                reporter: action.payload.reporter || action.payload.creator,
                isEditable: action.payload.isEditable,
                created: action.payload.created,
                tags: action.payload.tags || [],
            }
        },
        setResolvedTicketData(state: SliceState, action: PayloadAction<TicketViewResponse>) {
            state.resolvedTicket = action.payload
        },
        setTicketFeedback(state: SliceState, action: PayloadAction<{id: number; rating: number}>) {
            const {id, rating} = action.payload

            if (state.resolvedTicket) {
                state.resolvedTicket.feedbackRating = rating
                state.allResolvedTickets = state.allResolvedTickets.map((item) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            feedbackRating: rating,
                        }
                    }
                    return item
                })
            }
        },
        setAllActiveTicketsData(
            state: SliceState,
            action: PayloadAction<AllActiveTicketsResponse>
        ) {
            state.allActiveTickets = action.payload.items
            state.count = action.payload.count
            state.fetchAllTicketsStatus = APIStatus.Success
        },
        setAllResolvedTicketsData(state: SliceState, action: PayloadAction<MyRequestsResponse>) {
            state.allResolvedTickets = action.payload.items
            state.count = action.payload.count
            state.fetchAllTicketsStatus = APIStatus.Success
        },
        openActiveTicket(state: SliceState, action: PayloadAction<{id: number}>) {
            const updatedTickets = state.allActiveTickets.map((ticket) => {
                if (ticket.id === action.payload.id) {
                    return {
                        ...ticket,
                        lastMessage: ticket.lastMessage
                            ? {...ticket.lastMessage, isNewMessage: false}
                            : undefined,
                    }
                }
                return ticket
            })
            state.allActiveTickets = updatedTickets
        },
        openResolvedTicket(state: SliceState, action: PayloadAction<{id: number}>) {
            state.allResolvedTickets = state.allResolvedTickets.map((ticket) => {
                if (ticket.id === action.payload.id) {
                    return {
                        ...ticket,
                        lastMessage: ticket.lastMessage
                            ? {...ticket.lastMessage, isNewMessage: false}
                            : undefined,
                    }
                }
                return ticket
            })
        },
        addComment(state: SliceState, action: PayloadAction<TicketChatMessage>) {
            state.comments = [
                ...state.comments.map((item) => ({...item, isEditable: false})),
                action.payload,
            ]
        },
        editComment(state: SliceState, action: PayloadAction<TicketChatMessage>) {
            state.comments = state.comments.map((com) => {
                if (com.id === action.payload.id) {
                    return action.payload
                }
                return com
            })
        },
        deleteComment(
            state: SliceState,
            action: PayloadAction<{currentPersonId?: number; id: number}>
        ) {
            const {id, currentPersonId} = action.payload
            state.comments = state.comments.filter((comment) => comment.id !== id)

            if (state.comments.length) {
                const lastMessage =
                    state.comments.length > 1
                        ? state.comments[state.comments.length - 1]
                        : state.comments[0]
                state.comments = state.comments.map((item) =>
                    item.id === lastMessage.id
                        ? {
                              ...lastMessage,
                              isEditable: lastMessage.creator.id === currentPersonId,
                          }
                        : item
                )
            }
        },
        updateTicketData(
            state: SliceState,
            action: PayloadAction<{description: string; files: TicketFile[]}>
        ) {
            if (state.activeTicket)
                state.activeTicket = {
                    ...state.activeTicket,
                    description: action.payload.description,
                    files: action.payload.files || [],
                }
        },
        resetActiveTicketData(state: SliceState) {
            state.activeTicket = undefined
        },
        resetCommonData(state: SliceState) {
            state.commonData = initialCommonData
        },
    },
})

interface Store {
    userSupportRequests: SliceState
}

export const userSupportRequestsSelectors = {
    getActiveTicketData: createSelector(
        (store: Store) => store.userSupportRequests.activeTicket?.id,
        (store: Store) => store.userSupportRequests.activeTicket?.subject,
        (store: Store) => store.userSupportRequests.activeTicket?.description,
        (store: Store) => store.userSupportRequests.activeTicket?.files,
        (store: Store) => store.userSupportRequests.activeTicket?.reporter,
        (store: Store) => store.userSupportRequests.activeTicket?.isEditable,
        (store: Store) => store.userSupportRequests.activeTicket?.created,
        (store: Store) => store.userSupportRequests.activeTicket?.phone,

        (id, subject, description, files = [], creator, isEditable, created, phone) => ({
            id,
            subject,
            description,
            files,
            creator,
            isEditable,
            created,
            phone,
        })
    ),
    getActiveTicketById: createSelector(
        (store: Store) => store.userSupportRequests.allActiveTickets,
        (store: Store, id: number | undefined) => id,
        (allActiveTickets, id) => {
            const ticket = allActiveTickets.find((item) => item.id === id)
            return ticket
        }
    ),

    getResolvedTicketData: (store: Store) => store.userSupportRequests.resolvedTicket,
    getAllActiveTickets: (store: Store) => store.userSupportRequests.allActiveTickets,
    getAllResolvedTickets: (store: Store) => store.userSupportRequests.allResolvedTickets,
    getCommonData: (store: Store) => store.userSupportRequests.commonData,
    getChatMessages: (store: Store) => store.userSupportRequests.comments,
    getFetchStatus: (store: Store) => store.userSupportRequests.fetchStatus,
    getCommentsStatuses: (store: Store) => store.userSupportRequests.editCommentsStatuses,
    getDeleteStatus: (store: Store) => store.userSupportRequests.deleteStatus,
    getInfoStatus: (store: Store) => store.userSupportRequests.fetchInfoStatus,
    getAllTicketsStatus: (store: Store) => store.userSupportRequests.fetchAllTicketsStatus,
    getTicketsCount: (store: Store) => store.userSupportRequests.count,
}
