import {APIRequest, callAPI} from "../lib/axios"
import {PortalsPanelItem} from "scholastic-client-components/src/types/securityTypes"

interface INews {
    name: string
    text: string
    startDate: number
}

interface Building {
    id: number
    name: string
}
export interface Location {
    id: number
    name: string
    locationLevelUser: boolean
    buildings: Building[]
}

export interface LocationInfo {
    locations: Location[]
    totalBuildings: number
}

export interface HomeResponse {
    news: INews[]
    noDevicesTrial: boolean

    userLocations: {
        school: Location[]
        business: Location[]
        higherEd: Location[]
    }

    panelItems: {
        school?: Array<PortalsPanelItem>
        business?: Array<PortalsPanelItem>
        higherEd?: Array<PortalsPanelItem>
    }
}

const fetchData: APIRequest<null, HomeResponse> = (args) => {
    return callAPI({url: "api/auth/home", ...args})
}

export const homeAPI = {
    fetchData,
}
