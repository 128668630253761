import {
    createSlice as createSlicePackage,
    createSelector as createSelectorPackage,
    configureStore as configureStorePackage,
} from "@reduxjs/toolkit"

export const createSlice = createSlicePackage
export const createSelector = createSelectorPackage
export const configureStore = configureStorePackage
export type PayloadAction<T> = {payload: T}
