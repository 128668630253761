import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {SystemNotification} from "../features/SocketWrapper/Notifications/useNotificationsListener"
import {APIStatus} from "../types/APITypes"

interface SliceState {
    notifications?: Array<SystemNotification>
    catchEyeNotification?: SystemNotification
    initStatus: APIStatus
}

const initialState: SliceState = {
    initStatus: APIStatus.Initial,
}

export const headerNotificationsSlice = createSlice({
    name: "headerNotifications",
    initialState,
    reducers: {
        setNotifications(
            state: SliceState,
            action: PayloadAction<{timeThreshold: number; items: Array<SystemNotification>}>
        ) {
            const {items, timeThreshold} = action.payload
            if (!items) {
                state.catchEyeNotification = undefined
            } else {
                if (
                    !state.catchEyeNotification ||
                    !items.find(({id}) => id === state.catchEyeNotification!.id)
                ) {
                    const newNotifications = items
                        .filter(
                            ({id: payloadId}) =>
                                !state.notifications?.find(({id}) => id === payloadId)
                        )
                        .filter(({viewed, created}) => !viewed && created > timeThreshold)
                    state.catchEyeNotification = newNotifications?.[0]
                }
            }
            state.notifications = items
        },
        setInitStatus(state: SliceState, action: PayloadAction<APIStatus>) {
            state.initStatus = action.payload
        },
        setInitialNotifications(
            state: SliceState,
            action: PayloadAction<{timeThreshold: number; items: Array<SystemNotification>}>
        ) {
            const {items, timeThreshold} = action.payload
            state.notifications = items
            const notViewedNotifications = items.filter(
                ({viewed, created}) => !viewed && created > timeThreshold
            )
            if (notViewedNotifications.length)
                state.catchEyeNotification = notViewedNotifications[0]
            state.initStatus = APIStatus.Success
        },
        clearCatchEye(state: SliceState) {
            state.catchEyeNotification = undefined
        },
        setViewedStatus(state: SliceState, action: PayloadAction<Array<number>>) {
            const items = action.payload
            state.notifications = state.notifications?.map((item) => {
                if (items.includes(item.id))
                    return {
                        ...item,
                        viewed: true,
                    }
                return item
            })
        },
        reset() {
            return initialState
        },
    },
})

interface Store {
    headerNotifications: SliceState
}

export const headerNotificationsSelectors = {
    getInitStatus: (state: Store) => state.headerNotifications.initStatus,
}

export const getHeaderNotifications = (store: Store) => store.headerNotifications.notifications

export const getCatchEyeNotification = (store: Store) =>
    store.headerNotifications.catchEyeNotification

export const getHeaderNotificationsInitStatus = (store: Store) =>
    store.headerNotifications.initStatus
