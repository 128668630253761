import React from "react"
import {ContainerLayout} from "../ui/ContainerLayout/ContainerLayout"
import {Suspense} from "react"
import {PageHeader} from "../ui/PageHeader/PageHeader"
import ScholasticLoader from "../ui/ScholasticLoader/ScholasticLoader"

export const LazyWrapper: React.FC<{title: string; children: React.ReactNode; mt?: number}> = ({
    title,
    children,
    mt,
}) => {
    return (
        <Suspense
            fallback={
                <ContainerLayout>
                    <PageHeader>
                        {{
                            title,
                        }}
                    </PageHeader>
                    <div style={{marginTop: `${mt || 10}rem`}}>
                        <ScholasticLoader />
                    </div>
                </ContainerLayout>
            }
        >
            {children}
        </Suspense>
    )
}
