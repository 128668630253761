import React, {Suspense} from "react"
import {SelectComponentProps} from "./interfaces"
import {reactLazyRetry} from "../../utilities/retryImport"

const Select = reactLazyRetry(() => import("./SelectComponent"))

export const SelectComponent: React.FC<SelectComponentProps> = (props) => (
    <Suspense fallback={null}>
        <Select {...props} />
    </Suspense>
)
