import React from "react"
import {EducateBox} from "./Boxes"
import {Location} from "../../../../../api/homeAPI"
import styles from "./NetworkBlocks.module.scss"

interface LocationItemProps {
    location: Location
    limitedWidth: string
}

export const LocationItem: React.FC<LocationItemProps> = ({location, limitedWidth}) => {
    return (
        <div className={styles.location} key={location.id}>
            <EducateBox
                limitedWidth={limitedWidth}
                limitLines={2}
                text={location.name.toUpperCase()}
                iconPosition={"top"}
                location
                smallText
            />
            {location.buildings.length > 0 && (
                <ul className={styles.list}>
                    {location.buildings.map((building) => (
                        <li className={styles.item} key={building.id}>
                            <EducateBox
                                limitedWidth={limitedWidth}
                                limitLines={2}
                                text={building.name}
                                iconPosition={"top"}
                                building
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
