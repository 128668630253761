import React, {Suspense, useEffect, useLayoutEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useLogout} from "scholastic-client-components/src/hooks/useLogout"
import {authSelectors} from "scholastic-client-components/src/slices/authSlice"
import {Loader} from "scholastic-client-components/src/ui/Loader/Loader"
import {authAPI} from "../../api/connectedAPI"
import {BlurPageBackground} from "../../features/BlurPageBackground/BlurPageBackground"
import {PortalList} from "./components/PortalList/PortalList"
import {UserInfo} from "./components/UserInfo/UserInfo"
import {useFetch} from "./hooks/useFetch"
import styles from "./styles.module.scss"

const NewsList = React.lazy(() => import("./components/NewsList/NewsList"))

export const HomePage: React.FC = () => {
    const logout = useLogout({useDispatch, api: authAPI})
    const {fetch, response, fetchNews} = useFetch()

    const fullName = useSelector(authSelectors.getFullName) || ""
    const noDevicesTrial = useSelector(authSelectors.getNoDevicesTrial)

    useEffect(() => {
        fetch()
    }, [fetch])

    useEffect(() => {
        fetchNews()
    }, [fetchNews])

    useLayoutEffect(() => {
        const backgroundCreatedByAuthScript = document.getElementById("home_portal_background")
        if (!backgroundCreatedByAuthScript) {
            const background = document.createElement("div")
            background.style.height = "100vh"
            background.style.left = "0"
            background.style.overflow = "hidden"
            background.style.position = "fixed"
            background.style.top = "0"
            background.style.width = "100vw"
            background.style.zIndex = "1"

            const backgroundHelper = document.createElement("div")
            backgroundHelper.style.backgroundPosition = "50% 50%"
            backgroundHelper.style.backgroundSize = "cover"
            backgroundHelper.style.filter = "brightness(53%) blur(13px)"
            backgroundHelper.style.height = "calc(100% + 50px)"
            backgroundHelper.style.width = "calc(100% + 50px)"
            backgroundHelper.style.position = "relative"
            backgroundHelper.style.left = "-25px"
            backgroundHelper.style.top = "-25px"
            backgroundHelper.id = "home_portal_background"
            backgroundHelper.style.backgroundImage =
                "url(background/normalBg.jpg), url(background/smallBg.jpg)"

            background.appendChild(backgroundHelper)
            document.body.appendChild(background)
        }
    }, [])

    return (
        <>
            <BlurPageBackground>
                <div className={styles.content}>
                    <div className={styles.mainBlock}>
                        {
                            <>
                                <div className={styles.navigation}>
                                    <UserInfo
                                        data={response}
                                        onLogout={logout}
                                        fullName={fullName}
                                        noDevicesTrial={!!noDevicesTrial}
                                    />
                                    {response && !noDevicesTrial && <PortalList />}
                                    {!response && (
                                        <div style={{marginTop: "2rem"}}>
                                            <Loader />
                                        </div>
                                    )}
                                </div>
                                <Suspense fallback={null}>
                                    <NewsList data={response} />
                                </Suspense>
                            </>
                        }
                    </div>
                </div>
            </BlurPageBackground>
        </>
    )
}
