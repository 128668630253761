import {useCallback, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {homeAPI} from "../../../api/homeAPI"
import {homeSelectors, homeSlice} from "../../../slices/homeSlice"
import {usePortalSectionContext} from "scholastic-client-components/src/context/portalSectionContext"

export const useFetch = () => {
    const response = useSelector(homeSelectors.getData)
    const status = useSelector(homeSelectors.getStatus)
    const dispatch = useDispatch()
    const {portalSection} = usePortalSectionContext()

    const fetch = useCallback(
        (redirectOnSuccess?: boolean) => {
            dispatch(homeSlice.actions.startFetch())
            dispatch(
                homeAPI.fetchData({
                    onSuccess: (response) => {
                        dispatch(
                            homeSlice.actions.successFetch({
                                ...response,
                                news: redirectOnSuccess ? [] : response.news,
                            })
                        )
                    },
                    onError: () => {
                        dispatch(homeSlice.actions.failFetch())
                    },
                })
            )
        },
        [dispatch, portalSection]
    )

    const ignoreFirstFetch = useRef(true)
    const fetchNews = useCallback(() => {
        if (!ignoreFirstFetch.current)
            dispatch(
                homeAPI.fetchData({
                    onSuccess: (response) => {
                        dispatch(homeSlice.actions.successFetch(response))
                    },
                })
            )
        ignoreFirstFetch.current = false
    }, [dispatch, portalSection])

    return useMemo(
        () => ({
            status,
            response,
            fetch,
            fetchNews,
        }),
        [fetch, response, status, fetchNews]
    )
}
