import React from "react"
import {Position} from "../../../../ui/Dropdown/components/DropdownPosition/hooks/getDropdownCoords"
import {HeaderItem} from "../../HeaderItem/HeaderItem"
import {Link} from "react-router-dom"
import styles from "./NavigationLink.module.scss"
import classNames from "classnames"
import {HeaderDropdown} from "../../HeaderDropdown/HeaderDropdown"

type INavigationLink<T = {}> = T & {
    authorities?: Array<string>
    id: number
    isActive: boolean
    text: string
    to: string
    onClick?: () => void
    orange?: boolean
}

export interface NavigationLinkProps<T = {}> extends Partial<INavigationLink> {
    dropdown?: {
        items: Array<INavigationLink<T>>
        dropdownWidth?: string
        position?: Position
    }
    customDropdownNode?: React.FC<{closeDropdown: () => void}>
}

export const NavigationLink: React.FC<NavigationLinkProps> = ({
    dropdown,
    customDropdownNode,
    ...linkProps
}) => {
    if (dropdown || customDropdownNode) {
        if (customDropdownNode) {
            return (
                <HeaderDropdown
                    customDropdownNode={customDropdownNode}
                    items={[]}
                    position={dropdown?.position}
                    title={linkProps.text}
                    isActive={linkProps.isActive}
                />
            )
        }
        if (!dropdown?.items.length) return null
        const {items, dropdownWidth, position} = dropdown
        if (items.length > 1)
            return (
                <HeaderDropdown
                    customDropdownNode={customDropdownNode}
                    items={items}
                    title={items.find(({isActive}) => isActive)?.text || linkProps.text}
                    isActive={linkProps.isActive}
                    dropdownWidth={dropdownWidth}
                    position={position}
                />
            )
        return <NavLink {...items[0]} text={linkProps.text} />
    }

    return <NavLink {...linkProps} />
}

const NavLink: React.FC<Partial<INavigationLink>> = ({isActive, to = "", onClick, text}) => (
    <HeaderItem isActive={isActive}>
        <Link
            to={to}
            className={classNames(styles["navigation-link"], {[styles["-active"]]: isActive})}
            onClick={onClick}
            data-cy={text ? `headerLink${text}` : undefined}
        >
            <span>{text}</span>
        </Link>
    </HeaderItem>
)
