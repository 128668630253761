import React, {useEffect, useState} from "react"
import {classNames} from "../../../../scholastic-client-components/src/SharedPackages/classnames"
import {useSearchParams} from "../../hooks/search/useSearchParams"
import styles from "./BlurPageBackground.module.scss"

interface BlurPageBackground {
    children?: React.ReactNode
}

export const BlurPageBackground: React.FC<BlurPageBackground> = ({children}) => {
    const {
        ["help_center"]: {value},
    } = useSearchParams("help_center")
    const [fixMarginIfNoScroll, setFixMarginIfNoScroll] = useState(false)

    useEffect(() => {
        setFixMarginIfNoScroll(!!value)
    }, [value])

    const scrollIsVisible = value ? true : document.body.clientHeight === document.body.scrollHeight

    return (
        <div
            className={classNames(styles.content, {
                [styles.fixMargin]: fixMarginIfNoScroll && scrollIsVisible,
            })}
        >
            {children}
        </div>
    )
}
