import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {GreetingsResponse} from "../api/guideAPI"
import {APIStatus} from "../types/APITypes"

export const FULL_SCHOLASTIC_DOC = "https://docs.thescholasticnetwork.com/"

export interface GuideItem {
    id: number
    article: string
    name: string
}

export type GreetingsModalType = "SCHOOL" | "BUSINESS"

export interface UserGuideArticle {
    id: number
    name: string
    article: string
}

export interface GuideLinks {
    title?: string
    links?: Array<UserGuideArticle>
    gettingStartedCount: number
    gettingStartedAvailable: boolean
}

interface SliceState {
    status: APIStatus
    data: Array<UserGuideArticle>
    greetingsModalType: GreetingsModalType
    instructionGuideExists: boolean
    isFirstVisitThisPortal: boolean
    greetingsModalOpen: boolean
    hideGreetingsModalArticlesBtn: boolean
    gettingStartedCount: number
    gettingStartedAvailable: boolean
}

const initialState: SliceState = {
    status: APIStatus.Initial,
    data: [],
    greetingsModalType: "SCHOOL",
    greetingsModalOpen: false,
    instructionGuideExists: false,
    isFirstVisitThisPortal: false,
    hideGreetingsModalArticlesBtn: false,
    gettingStartedCount: 0,
    gettingStartedAvailable: false,
}

export const userGuideSlice = createSlice({
    name: "userGuideSlice",
    initialState,
    reducers: {
        startFetch(state: SliceState) {
            state.status = APIStatus.Loading
        },
        failFetch(state: SliceState) {
            state.status = APIStatus.Failure
        },
        successFetch(state: SliceState, action: PayloadAction<GuideLinks>) {
            state.data = action.payload.links || []
            state.gettingStartedCount = action.payload.gettingStartedCount
            state.gettingStartedAvailable = action.payload.gettingStartedAvailable
            state.status = APIStatus.Success
        },
        reset(state: SliceState) {
            state.data = []
            state.status = APIStatus.Initial
        },
        resetFirstVisitThisPortal(state: SliceState) {
            state.isFirstVisitThisPortal = false
        },
        successGreetingsFetch(state: SliceState, action: PayloadAction<GreetingsResponse>) {
            const {
                showGreetingsModal,
                greetingModalType,
                instructionGuideExists,
                isFirstVisitThisPortal,
            } = action.payload

            state.greetingsModalType = greetingModalType
            state.instructionGuideExists = instructionGuideExists
            state.isFirstVisitThisPortal = isFirstVisitThisPortal
            if (showGreetingsModal) {
                state.greetingsModalOpen = true
            }
        },
        openGreetingsModal(state: SliceState) {
            state.hideGreetingsModalArticlesBtn = true
            state.greetingsModalOpen = true
        },
        closeGreetingModal(state: SliceState) {
            state.greetingsModalOpen = false
        },
    },
})

interface Store {
    userGuide: SliceState
}

export const userGuideSelectors = {
    getStatus: (state: Store) => state.userGuide.status,
    getData: (state: Store) => state.userGuide.data,

    getGreetingsModalType: (state: Store) => state.userGuide.greetingsModalType,
    getVisibleTourButton: (state: Store) => state.userGuide.instructionGuideExists,
    getVisiblePortalSectionTooltip: (state: Store) => state.userGuide.isFirstVisitThisPortal,
    getGreetingsModalOpen: (state: Store) => state.userGuide.greetingsModalOpen,
    getHideGreetingsModalArticlesBtn: (state: Store) =>
        state.userGuide.hideGreetingsModalArticlesBtn,
    gettingStartedCount: (state: Store) => state.userGuide.gettingStartedCount,
    gettingStartedAvailable: (state: Store) => state.userGuide.gettingStartedAvailable,
}
