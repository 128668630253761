import {useEffect} from "react"
import {notificationListSlice} from "../slices/notificationListSlice"
import {DispatchAction} from "../lib/reactRedux"

const openDate = new Date()

const requestLastModDate = (onDateReceived: (date: Date) => void, indexRoute: string) => {
    const xhr = new XMLHttpRequest()

    xhr.open("GET", indexRoute, true)

    xhr.setRequestHeader("cache-control", "no-cache, must-revalidate, post-check=0, pre-check=0")

    xhr.onerror = function () {
        console.error("Could not send app index request")
    }
    xhr.onreadystatechange = function () {
        // readystate 2, headers received
        if (this.readyState == 2) {
            if (this.getResponseHeader("Last-Modified")) {
                const lastUpdate = new Date(this.getResponseHeader("Last-Modified") as string)
                if (openDate < lastUpdate) {
                    onDateReceived(lastUpdate)
                }
            } else {
                console.error("Could not read Last-Modified header from app index request")
            }
            this.abort()
        }
    }
    xhr.send()
}

export const useUpdateMessage = (dispatch: DispatchAction, indexRoute: string): void => {
    useEffect(() => {
        const interval = setInterval(
            () =>
                requestLastModDate(
                    () => dispatch(notificationListSlice.actions.showUpdateMessage()),
                    indexRoute
                ),
            5 * 60000
        )
        return () => {
            clearInterval(interval)
        }
    }, [dispatch, indexRoute])
}
