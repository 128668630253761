import React from "react"
import {Icon} from "../../Icon/Icon"
import styles from "./InfoTrigger.module.scss"
import classNames from "classnames"

interface InfoTooltipTriggerProps {
    hintText: string
    popupOpen: boolean
    dark?: boolean
}

export const InfoTrigger: React.FC<InfoTooltipTriggerProps> = ({hintText, popupOpen, dark}) => {
    return (
        <div
            className={classNames(styles.triggerBox, {
                [styles.popupOpen]: popupOpen,
                [styles.dark]: dark,
            })}
        >
            <Icon className={styles.icon} name={"question"} />
            {hintText && <span className={styles.hint}>{hintText}</span>}
        </div>
    )
}
