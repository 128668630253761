import React, {ReactNode, Suspense, useEffect, useState} from "react"
import {antdLocale, initI18n} from "./i18n"
import {APIStatus} from "../types/APITypes"
import {authSelectors} from "../slices/authSlice"
import {useSelector} from "react-redux"
import ScholasticLoader from "../ui/ScholasticLoader/ScholasticLoader"
import {ContainerLayout} from "../ui/ContainerLayout/ContainerLayout"
import {ConfigProvider} from "antd"
import {LocalStorageLocale} from "./localStorage"

type I18nWrapperProps = {
    ns: string[]
    children: ReactNode
}

export const I18nWrapper: React.FC<I18nWrapperProps> = ({ns, children}) => {
    const locale = useSelector(authSelectors.getLocale)
    const [i18nStatus, setI18nStatus] = useState(APIStatus.Loading)
    const unauthorizedUserLocale = LocalStorageLocale.getValue()

    useEffect(() => {
        initI18n({ns, lng: locale?.id || unauthorizedUserLocale})
            .then(() => {
                setI18nStatus(APIStatus.Success)
            })
            .catch(() => {
                setI18nStatus(APIStatus.Failure)
            })
    }, [locale?.id, ns, unauthorizedUserLocale])

    return (
        <Suspense fallback={<ScholasticLoader />}>
            <ConfigProvider locale={antdLocale[locale?.id || "en"]}>
                <>
                    {i18nStatus == APIStatus.Initial || i18nStatus == APIStatus.Loading ? (
                        <ContainerLayout>
                            <div style={{marginTop: "8.875rem"}}>
                                <ScholasticLoader />
                            </div>
                        </ContainerLayout>
                    ) : (
                        children
                    )}
                </>
            </ConfigProvider>
        </Suspense>
    )
}
