import classNames from "classnames"
import React, {
    forwardRef,
    memo,
    ReactNode,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
} from "react"
import {BodyPortal} from "../../../../features/BodyPortal"
import {DEFAULT_Z_INDEX} from "../../constants"
import {DropdownPositionProps} from "../../types"
import styles from "./DropdownPosition.module.scss"
import {BlockSizes, getDropdownCoords} from "./hooks/getDropdownCoords"
import {findDropdownPosition} from "./hooks/useCorrectPosition"

export interface _DropdownPositionProps {
    open: boolean
    setOpen: (open: boolean) => void

    dropdownId: string
    triggerRef: React.MutableRefObject<HTMLDivElement | null>
    dropdownRef: React.MutableRefObject<HTMLDivElement | null>
    rootRef: React.MutableRefObject<HTMLDivElement | null>
}

type Props = DropdownPositionProps & _DropdownPositionProps & {children?: ReactNode}
export type DropdownPositionInterface = {
    place: () => void
} | null

export const DropdownPosition = memo(
    forwardRef<DropdownPositionInterface, Props>(
        (
            {
                position = "bottom left",
                zIndexPopup = DEFAULT_Z_INDEX,
                offsetX,
                offsetY,
                open,
                setOpen,
                children,
                closeOnDocument = true,
                dropdownId,
                dropdownRef,
                triggerRef,
                rootRef,
                disablePosCorrection,
            },
            ref
        ) => {
            const place = useCallback(() => {
                const rootNode = rootRef.current
                const triggerNode = triggerRef.current
                const dropdownNode = dropdownRef.current
                if (!dropdownNode || !rootNode || !triggerNode) {
                    return
                }

                const blockSizes: BlockSizes = {
                    triggerWidth: 0,
                    triggerHeight: 0,
                    dropdownWidth: 0,
                    dropdownHeight: 0,
                }
                const triggerRect = triggerNode.getBoundingClientRect()
                blockSizes.triggerHeight = triggerRect.height
                blockSizes.triggerWidth = triggerRect.width

                const dropdownRect = dropdownNode.getBoundingClientRect()
                blockSizes.dropdownHeight = dropdownRect.height
                blockSizes.dropdownWidth = dropdownRect.width

                const rootRect = rootNode.getBoundingClientRect()

                const initialCoords = getDropdownCoords(
                    position,
                    blockSizes,
                    offsetX,
                    offsetY,
                    rootRect
                )
                const correctedPosition = disablePosCorrection
                    ? position
                    : findDropdownPosition({
                          position,
                          dropdownCoords: initialCoords,
                          blockSizes,
                          offsetX,
                          offsetY,
                          rootRect,
                      })
                if (correctedPosition !== position || disablePosCorrection) {
                    const coords = getDropdownCoords(
                        correctedPosition,
                        blockSizes,
                        offsetX,
                        offsetY,
                        rootRect,
                        disablePosCorrection
                    )
                    dropdownNode.style.top = coords.top + "px"
                    dropdownNode.style.left = coords.left + "px"
                } else {
                    dropdownNode.style.top = initialCoords.top + "px"
                    dropdownNode.style.left = initialCoords.left + "px"
                }
            }, [disablePosCorrection, dropdownRef, offsetX, offsetY, position, rootRef, triggerRef])

            useImperativeHandle(ref, () => ({place}), [place])

            useLayoutEffect(() => {
                place()
            }, [place])

            useEffect(() => {
                const handleClose = (event: any) => {
                    const clickOnDropdown = ((): boolean => {
                        const selector = `[data-dropdown='positioned-dropdown-${dropdownId}']`
                        const elem = document.querySelector(selector)

                        const path = event.path || (event.composedPath && event.composedPath())

                        if (path) {
                            return path.includes(elem)
                        }

                        return !!event.target.closest(selector)
                    })()

                    if (clickOnDropdown) return
                    setOpen(false)
                }
                if (closeOnDocument) {
                    setTimeout(() => {
                        setTimeout(() => {
                            window.addEventListener("click", handleClose)
                        }, 0)
                    }, 0)
                }
                return () => {
                    window.removeEventListener("click", handleClose)
                }
            }, [])

            return (
                <BodyPortal>
                    <div
                        ref={dropdownRef}
                        data-dropdown={`positioned-dropdown-${dropdownId}`}
                        className={classNames(styles.dropdownPosition, {
                            [styles.dropdownPosition_visible]: open,
                        })}
                        style={{zIndex: zIndexPopup}}
                    >
                        {children}
                    </div>
                </BodyPortal>
            )
        }
    )
)
