import React, {useCallback, useEffect, useState} from "react"
import {useRem} from "../../hooks/adaptive/useRem"
import {
    IMobileContext,
    MobileContext,
    mobileContextInitialValues,
} from "../../context/mobileContext"
import {REM_MOBILE_BREAKPOINT} from "./constants"

interface MobileProviderProps {
    children?: React.ReactNode
    collapseHeaderLeftPartMinimumWidthRem?: number
}

export const MobileProvider: React.FC<MobileProviderProps> = ({
    children,
    collapseHeaderLeftPartMinimumWidthRem,
}) => {
    const [context, setContext] = useState(mobileContextInitialValues)

    const remValue = useRem()

    const getContext = useCallback((): IMobileContext => {
        const innerWidth = document.documentElement.clientWidth

        if (window.IS_PLAYWRIGHT) {
            return mobileContextInitialValues
        }

        return {
            isMobile: innerWidth <= REM_MOBILE_BREAKPOINT * remValue,
            collapseHeaderLeftPart:
                innerWidth <=
                (collapseHeaderLeftPartMinimumWidthRem || REM_MOBILE_BREAKPOINT) * remValue,
            innerWidth: innerWidth,
        }
    }, [remValue])

    useEffect(() => {
        const handleResize = () => setContext(getContext())

        handleResize()
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [getContext])

    return <MobileContext.Provider value={context}>{children}</MobileContext.Provider>
}
