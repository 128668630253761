import {useCallback, useState} from "react"
import {APIStatus} from "../../../types/APITypes"
import {getNotificationsAPI} from "../../../api/notificationsAPI"

export const useNotificationViewMethodProvider = (useDispatch: any) => {
    const dispatch = useDispatch()
    const [status, setStatus] = useState(APIStatus.Initial)

    const view = useCallback(
        (items: Array<number>) => {
            setStatus(APIStatus.Loading)
            dispatch(
                getNotificationsAPI().viewNotifications({
                    payload: {
                        items,
                    },
                })
            )
        },
        [dispatch]
    )

    return {
        view,
        status,
    }
}

export const useNotificationDeleteMethodProvider = (useDispatch: any) => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState<Array<number>>([])

    const deleteNotification = useCallback(
        (id: number) => {
            setInProgress((current) => [...current, id])
            dispatch(
                getNotificationsAPI().deleteNotification({
                    onSuccess: () => {
                        setInProgress((current) => current.filter((item) => item !== id))
                    },
                    onError: () => {
                        setInProgress((current) => current.filter((item) => item !== id))
                    },
                    payload: {
                        id,
                    },
                })
            )
        },
        [dispatch]
    )

    return {
        inProgress,
        deleteNotification,
    }
}
