import React from "react"
import classNames from "classnames"
import styles from "./Box.module.scss"
import CSS from "csstype"
import {AdaptiveValue, useAdaptiveValues} from "../../hooks/adaptive/useAdaptiveValues"
import {adaptiveHelper} from "../../utilities/adaptiveHelper"

export type FlexProp =
    | true
    | {
          alignItems?: "center" | "bottom" | "top" | "baseline" | "stretch" | "end"
          justifyContent?: "space-between" | "right" | "center" | "left"
          columnDirection?: boolean
      }

interface AdaptiveBoxProps {
    // adaptiveMode is required for prop for rest adaptive props
    adaptiveMode?: boolean

    adaptiveMb?: AdaptiveValue
    adaptiveMr?: AdaptiveValue
    adaptiveMt?: AdaptiveValue
    adaptiveMl?: AdaptiveValue

    mobileMb?: number
    mobileMr?: number
    mobileMt?: number
    mobileMl?: number

    mobileFlex?: FlexProp
}

export interface BoxProps extends AdaptiveBoxProps {
    className?: string

    mt?: number
    mr?: number
    mb?: number
    pt?: number
    ml?: number

    padContainer?: string
    minWidth?: number
    maxHeight?: number
    width?: number
    height?: number
    flex?: FlexProp
    inline?: boolean
    propsStyles?: CSS.Properties
    children?: React.ReactNode
    boxRef?: (node: HTMLElement | null) => void
}

export const Box: React.FC<BoxProps> = ({
    className,
    mt,
    mb,
    mr,
    pt,
    ml,
    adaptiveMode,
    mobileMb,
    mobileMr,
    mobileMt,
    mobileMl,
    adaptiveMb,
    adaptiveMt,
    adaptiveMr,
    adaptiveMl,
    padContainer,
    flex,
    mobileFlex,
    width,
    minWidth,
    maxHeight,
    height,
    inline,
    propsStyles,
    boxRef,
    ...props
}) => {
    const flexProp = adaptiveMode && mobileFlex ? mobileFlex : flex
    const {
        alignItems = undefined,
        justifyContent = undefined,
        columnDirection = false,
    } = !flexProp || typeof flexProp === "boolean" ? {} : flexProp

    const [adaptMb, adaptMl, adaptMt, adaptMr] = useAdaptiveValues({
        values: [adaptiveMb, adaptiveMl, adaptiveMt, adaptiveMr],
        isMobile: !!adaptiveMode,
    })

    const margins = {
        top: adaptiveHelper(adaptiveMode, mt, mobileMt, adaptMt),
        bottom: adaptiveHelper(adaptiveMode, mb, mobileMb, adaptMb),
        right: adaptiveHelper(adaptiveMode, mr, mobileMr, adaptMr),
        left: adaptiveHelper(adaptiveMode, ml, mobileMl, adaptMl),
    }

    const style: CSS.Properties = {
        marginTop: `${margins.top}rem`,
        marginRight: `${margins.right}rem`,
        marginBottom: `${margins.bottom}rem`,
        marginLeft: `${margins.left}rem`,
        paddingTop: `${pt}rem`,
        padding: padContainer,
        width: `${width}rem`,
        minWidth: `${minWidth}rem`,
        maxHeight: `${maxHeight}rem`,
        height: `${height}rem`,
        display: inline ? "inline-block" : undefined,
        ...propsStyles,
    }
    return (
        <div
            style={style}
            className={classNames(styles["box-component"], className, {
                [styles["-flex"]]: flex,
                [styles[`-ai_${alignItems}`]]: alignItems,
                [styles[`-jc_${justifyContent}`]]: justifyContent,
                [styles.column]: columnDirection,
            })}
            ref={boxRef}
            {...props}
        />
    )
}
