import {Location} from "../api/homeAPI"

export const shownLocationsCount = 20
export const trimList = (locations: Location[]) => {
    const newList: Location[] = []
    let totalElements = 0

    for (let i = 0; i < locations.length; i++) {
        const buildingCount = locations[i].buildings.length

        if (totalElements + buildingCount < shownLocationsCount) {
            newList.push(locations[i])
            totalElements += buildingCount + 1
        } else {
            const remainingElements = shownLocationsCount - totalElements
            if (remainingElements > 0 && remainingElements <= buildingCount) {
                newList.push({
                    ...locations[i],
                    buildings: locations[i].buildings.slice(0, remainingElements),
                })
                totalElements += remainingElements
            }
            break
        }
    }

    return newList
}
