import React, {useContext, useMemo} from "react"
import styles from "./Navigation.module.scss"
import classNames from "classnames"
import {NavigationLink, NavigationLinkProps} from "./NavigationLink/NavigationLink"
import {MobileContext} from "../../../context/mobileContext"
import {BurgerTrigger} from "./BurgerComponents/BurgerTrigger/BurgerTrigger"
import {BurgerNavLink} from "./BurgerComponents/BurgerNavLink/BurgerNavLink"
import {HeaderLogo, HeaderLogoProps} from "../HeaderLogo/HeaderLogo"
import {Burger} from "../../../ui/Burger/LazyBurger"

interface AdaptiveProps {
    logoLink?: HeaderLogoProps
}

export interface NavigationProps extends AdaptiveProps {
    items: Array<NavigationLinkProps>
    visible: boolean
    setVisible: (navigationVisible: boolean) => void
}

export const Navigation: React.FC<NavigationProps> = ({items, visible, setVisible, logoLink}) => {
    const {collapseHeaderLeftPart: isMobile} = useContext(MobileContext)
    const flattenItems = useMemo(() => {
        return items.map((item) => (item.dropdown ? item.dropdown.items : item)).flat()
    }, [items])

    if (items.length <= 0 && logoLink?.logoIcon && isMobile) return <HeaderLogo {...logoLink} />

    return (
        <>
            {isMobile ? (
                <Burger
                    open={visible}
                    setOpen={setVisible}
                    fullHeight={false}
                    Trigger={({open}) => (
                        <BurgerTrigger open={open} logoIcon={logoLink?.logoIcon} />
                    )}
                >
                    {flattenItems.map((item) => (
                        <BurgerNavLink
                            key={item.id}
                            onClick={() => {
                                item.onClick && item.onClick()
                                setVisible(false)
                            }}
                            {...item}
                        />
                    ))}
                </Burger>
            ) : (
                <div className={classNames(styles.navigation)} data-cy="header-navigation">
                    <ul className={styles.links}>
                        {items.map((item) => (
                            <NavigationLink
                                key={item.id}
                                onClick={() => {
                                    item.onClick && item.onClick()
                                    setVisible(false)
                                }}
                                {...item}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}
