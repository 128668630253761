import classNames from "classnames"
import React, { ReactNode } from "react";
import styles from "./HeaderItem.module.scss"

export interface HeaderItemProps {
    isActive?: boolean
    className?: string
    "data-cy"?: string
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    onClick?: () => void
    disabled?: boolean
}

export const HeaderItem: React.FC<{children?: ReactNode} & HeaderItemProps> = ({
    isActive,
    children,
    className,
    onMouseEnter,
    onMouseLeave,
    onClick,
    "data-cy": dataCy,
    disabled,
}) => (
    <li
        data-cy={dataCy}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        className={classNames(className, styles["header-item"], {
            [styles["-active"]]: isActive,
            [styles.disabled]: disabled,
        })}
    >
        {children}
    </li>
)
