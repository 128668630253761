import classNames from "classnames"
import React, {ReactElement, useLayoutEffect} from "react"
import styles from "./DropdownList.module.scss"
import {DropdownTitle} from "../DropdownMenu/DropdownTitle"
import {Scrollbars} from "../../Scrollbars"

interface DropdownListProps {
    items: Array<ReactElement>
    width?: number
    maxWidth?: number
    matchHeaderColors?: boolean
    title?: string
    withScrollbars?: boolean
}

const WithScrollBars = ({children}) => (
    <Scrollbars style={{height: "100%"}} autoHeightMin={"100%"} autoHeight>
        {children}
    </Scrollbars>
)

const WithoutScrollBars = ({children}) => <>{children}</>

export const DropdownList: React.FC<DropdownListProps> = ({
    items,
    width,
    maxWidth,
    matchHeaderColors,
    title,
    withScrollbars,
}) => {
    useLayoutEffect(() => {
        const {overflowY} = document.body.style
        if (document.body.clientHeight === document.body.scrollHeight)
            document.body.style.overflowY = "hidden"
        setTimeout(() => {
            document.body.style.overflowY = overflowY
        }, 0)
    }, [])

    const Wrapper = withScrollbars ? WithScrollBars : WithoutScrollBars

    return (
        <ul
            style={{width: `${width}px`, maxWidth: `${maxWidth}px`}}
            className={classNames(styles.dropdownList, {
                [styles.matchHeaderColors]: matchHeaderColors,
            })}
        >
            <Wrapper>
                {title && <DropdownTitle>{title}</DropdownTitle>}
                {items.map((item) => (
                    <li key={item.key as string} data-cy={"dropdown-item"}>
                        {item}
                    </li>
                ))}
            </Wrapper>
        </ul>
    )
}
